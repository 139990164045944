const addTypeCheck = (data) => {
  const type = localStorage.getItem('type');
  const newData = JSON.parse(JSON.stringify(data)); // Create a deep copy of the data

  if (type === 'default') {
    newData.forEach((row) => (row.type = 'default'));
  } else {
    newData.forEach((row) => (row.type = 'test'));
  }
  return newData;
};

export { addTypeCheck };

