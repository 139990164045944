/* eslint-disable no-unused-expressions */
import {React, Fragment, useEffect, useState, useContext} from 'react';
import {Search} from './search/search';
import {Header} from './header';
import {Body} from './body';

export const Table = (props) => {
  const [header, setHeader] = useState(null);
  const [body, setBody] = useState(null);
  const [result, setResult] = useState(false);
  const searchResult = props.search;

  const search = (data, res) => {
    const rowsToShow = [];
    const response = searchResult.length ? searchResult : res;
    response.forEach((row) => {
      for (const cell in row) {
        if (typeof row[cell] === 'string') {
          if (row[cell].toString().toLowerCase().includes(data.toLowerCase())) {
            if (!rowsToShow.includes(row)) {
              rowsToShow.push(row);
            }
          }
        }
      }
    });
    if (data !== '') {
      setResult(rowsToShow);
    } else if (data === '') {
      setResult(null);
    } else {
      setResult(null);
    }
  };

  const sort = ({name, clicked}) => {
    const data = [...props.data]?.sort((a, b) =>
      (typeof a[name] === 'string' && typeof b[name] === 'string' && !isNaN(new Date(a[name])) && !isNaN(new Date(b[name]))) ?
      (clicked ? new Date(b[name]) - new Date(a[name]) : new Date(a[name]) - new Date(b[name])) :
      (clicked ? b[name]?.localeCompare(a[name]) : a[name]?.localeCompare(b[name])),
    );
    setBody(data);
  };

  const formatTitle = (input) => {
    const words = input?.split(/(?=[A-Z])|\s|_/);
    const formattedWords = words?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase());
    return formattedWords?.join(' ');
  };

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      const header = props.data.header || [];

      if (!header.length) {
        for (const key in props.data[0]) {
          header.push({

            name: key,
            title: formatTitle(key),
            sort: key === 'actions' ? false : true,

          });
        }
      }

      setBody(props.data);
      setHeader(header);
    }
  }, [props.data]);

  useEffect(() => {
    props.checkbox !== undefined ? props.checkbox.setItem([]) : null;
  }, [result]);

  // no data
  if (!header && !body) return false;

  return (
    <Fragment>
      {/* SEARCH */}
      {props.search &&
        <Search callback={search} data={props.data} />
      }
      {/* TABLE */}
      <div className="flow-root">
        <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="relative pt-10">
              <table className="w-full border-separate border rounded-xl px-5">
                {/* HEAD */}
                <Header
                  data={header}
                  show={props.show}
                  search={result}
                  actions={props.actions}
                  checkbox={props.checkbox}
                  checkboxData={props.data}
                  callback={sort}
                  selectedItem={props.checkbox !== undefined ? props.checkbox.getItem : null}
                  setSelectedItem={props.checkbox !== undefined ? props.checkbox.setItem : null}
                />
                {/* BODY */}
                <Body
                  data={body}
                  show={props.show}
                  search={result}
                  actions={props.actions}
                  checkbox={props.checkbox}
                  badge={props.badge}
                  selectedItem={props.checkbox !== undefined ? props.checkbox.getItem : null}
                  setSelectedItem={props.checkbox !== undefined ? props.checkbox.setItem : null}
                />
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
