import React, {useContext, useState, useEffect} from 'react';
import {Animate, AuthContext, ViewContext, Form, Card, Row, useLocation, useNavigate} from 'components/lib';

export function Signin(props) {
  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const location = useLocation();
  const navigate = useNavigate();

  // state
  const [form, setForm] = useState({

    email: {
      label: 'Email',
      type: 'sign',
      required: true,
    },
    password: {
      label: 'Password',
      type: 'password',
      required: true,
    },
    forgotpassword: {
      type: 'link',
      url: '/forgotpassword',
      text: 'Forgot?',
    },
  });

  useEffect(() => {
    // was an error message passed from the server router?
    const qs = location.search;

    if (qs.includes('error')) {
      const msg = decodeURIComponent(qs.substring(qs.indexOf('?error=')+7));
      viewContext.notification.show(msg, 'error');
    }
  }, [location.search, viewContext.notification]);

  return (
    <Animate type='pop'>
      <Row
        title='Login to account'
        desc='Enter your credentials to access your account.'
      >
        <Card restrictWidth center>
          <Form
            data={ form }
            method='POST'
            url={ '/api/auth' }
            buttonText={ 'Login' }
            callback={ (res) => {
              (res.data['2fa_required'] ? navigate(`/signin/otp?token=${res.data.token}`) : navigate(authContext.signin(res)));
            }}
          />
        </Card>
      </Row>
    </Animate>
  );
}
