/** *
*
*   AUTH LAYOUT
*   Layout for the signup/signin pages
*
**********/

import {Footer, Logo} from 'components/lib';
import flossImage from './floss.png';

export function AuthLayout(props) {
  const {title} = props;

  const passwordLayout = {
    layout: title === 'Forgot Your Password?' || title === 'Reset Your Password' ? true : false,
    css: title === 'Forgot Your Password?' || title === 'Reset Your Password' ? 'grid-cols-1' : 'grid-cols-2',
  }

  const backgroundImageStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${flossImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
    width: '100%',
    borderRadius: '0.75rem',
  };

  return (
    <main className={`grid ${passwordLayout.css} gap-4 h-screen`}>
      <div className='col-span-1 w-full p-6 flex flex-col justify-between'>
        <div></div>
        {/* <Logo
          big
          color
          logo
        /> */}
        { <props.children {...props.data }/> }
        <Footer />
      </div>
      { !passwordLayout.layout &&
        <div className='col-start-2 w-full p-6'>
          <div className='rounded-xl w-full h-full' style={backgroundImageStyle}>
            <Logo
              big
              white
              logo
            />
          </div>
        </div>
      }
    </main>
  );
}
