import {useState} from 'react';
import Axios from 'axios';
import {Animate, CheckList, Button, Logo, useNavigate} from 'components/lib';
import Style from './onboarding.tailwind.js';
import {checkLocation} from 'helpers/checklocation';

export function PixelOnboarding(props) {
  const baseUrl = checkLocation();
  const navigate = useNavigate();
  const hash = window.location.hash;
  const [activeView, setActiveView] = useState(hash ? hash.substring(1)-1 : 0);

  if (!props?.views?.length) {
    return false;
  }

  const view = props.views[activeView];

  async function goTo(view) {
    window.location.hash = view + 1;
    setActiveView(view);
  }

  async function finish() {
    if (props.save) {
      await Axios.patch(`${baseUrl}/api/user`, {onboarded: true});
    }

    navigate(props.onFinish || '/dashboard');
  }

  return (
    <Animate type='pop-center'>
      <div className={ Style.onboarding }>

        <section className={ Style.sidebar }>

          <Logo className={ Style.logo }/>

          <CheckList color='white'
            className={ Style.checklist }
            hideCross interactive
            items={ props.views.map((view, i) => {
              return {name: view.name, checked: i <= activeView ? true : false, onClick: () => goTo(i)};
            })
            }/>

        </section>

        <section className={ Style.main }>

          <header className={ Style.header }>
            <h2 className={ Style.name }>
              { view.name }
            </h2>

            { view.description &&
            <span className={ Style.description }>
              { view.description }
            </span>
            }
          </header>

          <article className={ Style.article }>
            { view.component }
          </article>

        </section>

        <nav className={ Style.nav }>
          <div className="flex ml-auto">
            { activeView > 0 &&
              <Button
                small
                color='white'
                size={ 16 }
                text='Prev'
                className={ Style.prev }
                action={ () => goTo(activeView-1) }
                fullWidth
              />
            }

            { activeView < props.views.length-1 &&
              <Button
                small
                color='blue'
                size={ 14 }
                text='Next'
                className={ Style.next }
                action={ () => {
                  goTo(activeView+1);
                }}
                fullWidth
              />
            }
          </div>
        </nav>

      </div>
    </Animate>
  );
}
