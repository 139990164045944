import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import uuid4 from 'uuid4';
import {updateForm} from 'redux/features/businessBuilder/businessBuilderSlice';

export function BusinessText(props) {
  const [inputs, setInputs] = useState(props.inputs || []);
  const dispatch = useDispatch();

  const waitEnter = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    let words = e.target.value.trim();
    const wordsArray = words.split(',').map(item => {
      if (props.label ==='Job Title') {
        const cleanedItem = item.trim().replace(/[^a-zA-Z0-9 _-]/g, '');
        return cleanedItem;
      } else {
        const cleanedItem = item.trim().replace(/[^a-zA-Z0-9 _-]/g, '');
        return cleanedItem.replace(/ /g, '_').replace(/-(?=[^\s])/g, '_')
      }
    });
    setInputs((prevInputs) => [...prevInputs, ...wordsArray]);
    e.target.value = '';
  }
};


  const deleteBadge = (index) => {
    setInputs((prevInputs) => {
      const newInputs = [...prevInputs.slice(0, index), ...prevInputs.slice(index + 1)];
      return newInputs;
    });
  };

  useEffect(() => {
    const label = props?.label
        .toLowerCase()
        .replace(/[^a-zA-Z0-9\s]/g, '')
        .trim()
        .replace(/\s+/g, '_');
    const form = props.form?.toLowerCase();
    const formattedInputs = inputs?.map((item) => ({value: item}));
    dispatch(updateForm({label, value: formattedInputs, form}));
  }, [inputs, dispatch, props?.form, props?.label]);

  return (
    <div className='flex flex-wrap mt-2 mb-4 min-h-[42px] relative w-full rounded-lg border border-gray-300'>
      {inputs?.map((input, index) => (
        <div
          key={uuid4()}
          onClick={() => deleteBadge(index)}
          className="flex items-center h-8 px-2 m-1 text-zinc-900 text-sm transition-colors duration-150 bg-gray-300 rounded-md focus:shadow-outline"
        >
          <span className="mr-2">{input}</span>
          <span className="inline-flex justify-center items-left cursor-pointer px-1 text-xs font-bold leading-none text-zinc-600">x</span>
        </div>
      ))}
      <input
        className='resize-none overflow-hidden h-8 border-none border-transparent focus:border-transparent focus:ring-0 m-1'
        id={'Search'}
        type='text'
        placeholder={inputs?.length ? '' : 'Type here'}
        onKeyDown={(e) => waitEnter(e)}
      />
    </div>
  );
}
