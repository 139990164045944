import { Icon } from '../icon/icon';

export const Search = (props) => {
  return (
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <form className='relative'>
          <input
            type='text'
            onChange={(e) => props.callback(e.target.value, props.data)}
            className='block w-full pl-10 p-3 rounded-xl bg-white border border-solid border-slate-200 focus:bg-slate-50 appearance-none disabled:opacity-50'
            placeholder='Search'
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            {/* Assume your Icon component renders the correct SVG */}
            <Icon image="search" size={20} />
          </div>
        </form>
      </div>
    </div>
  );
};
