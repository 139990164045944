import React, {Fragment, useContext, useState} from 'react';
import {Animate, ViewContext} from 'components/lib';
import {useParams, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {removeForm} from 'redux/features/audienceBuilder/audienceBuilderSlice';
import {checkLocation} from 'helpers/checklocation';
import {checkType} from 'helpers/checkDataType';

export const AudienceGenerator = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const baseUrl = checkLocation();
  const context = useContext(ViewContext);
  const [clicked, setClicked] = useState(false);

  const reduxData = useSelector((state) => state.audienceBuilder.audienceBuilderForm);

  const postAudience = async (data) => {
    try {
      await axios.post(`${baseUrl}/api/audience/${params.id}/generate`, data, {
        headers: {
          'content-type': 'application/json',
          'Accept': 'application/json,text/*;q=0.99',
        }},
      );
      setClicked(true);
      dispatch(removeForm());
      navigate('/audience');
      context.notification.show('Audience generated successfully', 'success', true);
    } catch (err) {
      console.error(err);
      context.notification.show(err.response?.data.message || 'An unexpected error occurred', 'error', true);
    }
  };

  const sendStorage = async () => {
    const zipcodeList = reduxData?.zipcodeList?.concat(reduxData?.zipcodeListText);
    const data = {
      zipcodeList: (zipcodeList || [])
        .filter((item, index, self) =>
          self.findIndex((other) => other.value === item.value) === index)
        .map((item) => item.value),
      cityList: reduxData?.cityList?.map((item) => item.value) || [],
      notNull: reduxData?.notNull || [],
      nullOnly: reduxData?.nullOnly || [],
      stateList: reduxData?.stateList?.map((item) => item.value) || [],
      ageList: reduxData?.ageList !== undefined && reduxData.ageList[0] !== 0 ? reduxData.ageList : [],
      genderList: reduxData?.genderList || ['U'],
      iabList: reduxData?.iabList || [],
      optionType: reduxData?.optionType || 'B2C',
      keywordList: reduxData?.keywordList || [],
      businessList: {
        job_title: reduxData?.job_title || [],
        seniority: reduxData?.seniority || [],
        department: reduxData?.department || [],
        company_name: reduxData?.company_name || [],
        company_domain: reduxData?.company_domain || [],
        industry: reduxData?.industry || [],
        sic: reduxData?.sic || [],
        naics: reduxData?.naics || [],
        employee_count: reduxData?.employee_count || [],
        est_company_revenue: reduxData?.est_company_revenue || [],
      },
      profile: {
        personal: reduxData?.personal?.map((item) => [
          item.field,
          item.operator,
          checkType(item.field, item.value_back),
        ]) || [],
        family: reduxData?.family?.map((item) => [
          item.field,
          item.operator,
          checkType(item.field, item.value_back),
        ]) || [],
        financial: reduxData?.financial?.map((item) => [
          item.field,
          item.operator,
          checkType(item.field, item.value_back),
        ]) || [],
        house: reduxData?.house?.map((item) => [
          item.field,
          item.operator,
          checkType(item.field, item.value_back),
        ]) || [],
        auto: reduxData?.auto?.map((item) => [
          item.field,
          item.operator,
          checkType(item.field, item.value_back),
        ]) || [],
      },
      dateRange: reduxData?.dateRange || {},
    };

    if (data.iabList.length === 0 && data.keywordList.length === 0) { 
      context.notification.show('Please select or create at least one type of Audience', 'error', true);
      return;
    } 
    else if (
      data.ageList.length === 0 &&
      data.businessList.length === 0 &&
      data.genderList.length === 0 &&
      data.iabList.length === 0 &&
      data.keywordList.length === 0 &&
      data.zipcodeList.length === 0 &&
      data.cityList.length === 0 &&
      data.notNull.length === 0 &&
      data.nullOnly.length === 0 &&
      data.stateList.length === 0 &&
      data.profile.personal.length === 0 &&
      data.profile.family.length === 0 &&
      data.profile.financial.length === 0 &&
      data.profile.house.length === 0 &&
      data.profile.auto.length === 0 &&
     !data.dateRange.startDate &&
     !data.dateRange.endDate
    ) {
      context.notification.show('Please select at least one field', 'error', true);
      return;
    }
    localStorage.removeItem('field');
    postAudience(data);
  };

  return (
    <Fragment>
      <Animate>
      <button
            type="button"
            className={` ${clicked ? 'bg-gray-400 pointers-event-none focus-visible:outline-gray-400' : 'bg-blue-600 hover:bg-blue-700 focus-visible:outline-blue-600'} rounded-md  px-7 py-2.5 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 `}
            onClick={sendStorage}
          >
            Generate Audience
          </button>
      </Animate>
    </Fragment>
  );
};
