import {useContext} from 'react';
import {
  Animate,
  AuthContext,
  Row,
  Card,
  useNavigate,
  Form,
} from 'components/lib';

export function SignupPassword(props) {
  const context = useContext(AuthContext);
  const navigate = useNavigate();


  return (
    <Animate type="pop">
      <Row title="Set your password">
        <Card loading={false} restrictWidth center>
          <Form
            url="/api/user/firstPassword"
            method="PUT"
            buttonText="Save Password"
            data={{
              newpassword: {
                label: 'Create New Password ',
                type: 'password',
                required: true,
                complexPassword: true,
              },
              confirm_newpassword: {
                label: 'Confirm New Password ',
                type: 'password',
                required: true,
                complexPassword: true,
              },
            }}
            callback={(res) => {
              context.update({has_password: true});

              navigate('/signin');
            }}
          />
        </Card>
      </Row>
    </Animate>
  );
}
