import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {addPixel, setCurrentPage} from 'redux/features/pixelProcessor/pixelProcessorSlice';
import {Card, Animate, Feedback, Button, Icon} from 'components/lib';
import {Table} from 'gsd-components/table/table';
import {useNavigate, useParams} from 'react-router-dom';
import {checkLocation} from 'helpers/checklocation';
import Formatation from 'helpers/format';
import axios from 'axios';

export function DownloadStatus() {
  const dispatch = useDispatch();
  const params = useParams();
  const baseUrlPixel = checkLocation(1);
  const format = new Formatation();
  const pixelList = useSelector((state) => state.pixelProcessor.pixelProcessorList);
  const currentPage = useSelector((state) => state.pixelProcessor.currentPage);
  const pagValues = useSelector((state) => state.pixelProcessor.pagValues);
  const pixel = pixelList.length > 0 ? pixelList.find((item) => item?.id === params.id) : [];
  const navigate = useNavigate();

  const downloadCSV = (pixel) => {
    if (pixel.status === 'COMPLETE') {
      const CSV = 'https://audiencelab-builder.s3.amazonaws.com/' + pixel.name;
      window.open(CSV, '_blank').focus();
    }
    return null;
  };

  const getPixel = async () => {
    const res = await axios.get(`${baseUrlPixel}/api/pixel/${params.id}?page=${currentPage}&limit=100`);
    dispatch(addPixel(res.data.data.docs[0]));
  };

  useEffect(() => {
    getPixel();
  }, [currentPage]);

  return (
    <Animate type='pop'>
      {/* Title */}
      <div className='flex justify-between items-center'>
        <button
          className='m-6'
          type="button"
          onClick={() => navigate(`/pixel/${params.id}`)}
        >
          <Icon image="arrow-left" size={18} />
          Back
        </button>
      </div>

      <Card>
        {/* <NewPaginate
          offset={currentPage - 1}
          limit={pagValues.limit}
          total={pagValues.total}
          next={() => dispatch(setCurrentPage(currentPage + 1))}
          prev={() => dispatch(setCurrentPage(currentPage - 1))}
          onChange={() => dispatch(setCurrentPage())}
        /> */}
        {!pixel?.file_list ?
          <h2 className=" font-semibold text-lg">Your pixel is being processed</h2> :
          !pixel?.file_list[0] ?
            <h2 className=" font-semibold text-lg">Your pixel is being processed</h2> :
          <Table
            data={ pixel?.file_list?.map((item) => ({...item, createdAt: `${format.timestampModal(item?.createdAt)}`})) }
            show={['createdAt', 'status']}
            badge={{col: 'status', color: 'blue', condition: [
              {value: 'COMPLETE', color: 'green'},
              {value: 'FAILED', color: 'red'},
            ]}}
            // actions={[{icon: 'download', onClick: [downloadCSV], tooltip: ['Download CSV']}]}
          />
        }
      </Card>
      <Feedback/>
    </Animate>
  );
}
