/** *
*
*   SIGN UP STEP 1
*   Signup form for account owners
*   Step 1: create account
*   Step 2: select plan and make payment
*
**********/

import React, { useContext, useRef, useState, useEffect } from 'react';
import { AuthContext, Animate, Row, Card, Form, Link } from 'components/lib';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import {checkLocation} from 'helpers/checklocation';


export function SignupLink(props) {
  const context = useContext(AuthContext);
  const captchaRef = useRef(null);
  const [valid, setValid] = useState(false);
  const [plan, setPlan] = useState({});
  const [planLabel, setPlanLabel] = useState("");
  const [loading, setLoading] = useState(true);

  const baseUrl = checkLocation();
  const planLocation = window.location.pathname.split('/').pop();

  useEffect(() => {
    const getPlan = async () => {
      try {
        const res = await axios.get(`${baseUrl}/api/plans/signup/${planLocation}`);
        setPlan(res.data.data);
        setPlanLabel(res.data.data.label);
      } catch (error) {
        console.error("Error fetching plan:", error);
        // You may want to set a default plan or handle this error gracefully
      } finally {
        setLoading(false);
      }
    };
    getPlan();
  }, []);

  // Recaptcha

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    if (valid === false) {
      context.notification('Please verify you are not a robot', 'error', true);
    }

    const res = await axios.post(
      `https://www.google.com/recaptcha/api/siteverify?secret=${process.env.REACT_APP_RECAPTCHA_SECRET_KEY}&response=${token}`, {
      headers: {
        'Content-Security-Policy': '*', 'Content-type': 'application/json; charset=UTF-8',
      },
    },
    );
    if (res.status(200)) {
      res.send('Human 👨 👩');
      setValid(true);
    } else {
      res.send('Robot 🤖');
      context.notification('Please verify you are not a robot', 'error', true);
      setValid(false);
    }
  };
  const handleOnChange = () => {
    const token = captchaRef.current.getValue();
    token ? setValid(true) : setValid(false);
  };
  return (

    <>
      {!loading && (
        <Animate type='pop'>
          <Row title={`Create your ${planLabel} Account`}>
            <Card loading={false} restrictWidth center>
              <Form
                data={{
                  name: {
                    label: 'First Name',
                    type: 'text',
                    required: true,
                    errorMessage: 'Please enter your first name',
                  },
                  email: {
                    label: 'Email',
                    type: 'email',
                    required: true,
                  },
                  password: {
                    label: 'Password',
                    type: 'password',
                    required: true,
                    errorMessage:"Password must be a minimum of 8 characters, and one upper case letter",
                    complexPassword: true,
                  },
                  confirm_password: {
                    type: 'hidden',
                    value: null,
                  }
                }}
                url={valid && !loading && `/api/account/${planLocation}`}
                method='POST'
                buttonText={loading ? 'Loading Plan...' : (valid ? 'Create Account' : 'Verify you are not a robot')}
                onSubmit={handleOnSubmit}
                callback={context.signin}
              />
              <br />
              <div className="flex mx-auto">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  ref={captchaRef}
                  onChange={handleOnChange}
                />
              </div>
              <div className='mt-4'>
                Already registered? <Link url='/signin' text='Sign In' />
              </div>
            </Card>
          </Row>
        </Animate>
      )}


    </>

  );
}
