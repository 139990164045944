import {useState, useEffect, useRef} from 'react';
import uuid4 from 'uuid4';
import {Checkbox} from './checkbox/checkbox';

export const Header = (props) => {
  // initialize
  const originalData = useRef([...props.data]);
  const data = [...props.data];
  const [column, setColumn] = useState(false);
  const [clicked, setClicked] = useState(false);

  // inject actions
  if (props.actions && !props.show.includes('actions') && data.length > 1) {
    props.show.push('actions');
    originalData.current.push({name: 'actions', title: 'Actions', sort: false});
  }

  // inject checkbox
  let checkboxComponent = null;
  if (props.checkboxData && data.length > 1) {
    checkboxComponent = (
      <th scope="col" className="relative w-16 px-8 sm:w-12 sm:px-6">
        <Checkbox
          search={props.search}
          selectedItem={props.selectedItem}
          setSelectedItem={props.setSelectedItem}
          data={props.checkboxData}
          header
        />
      </th>
    );
  }

  return (
    <thead className='hidden font-semibold md:table-header-group'>
      <tr key={uuid4()}>
        {props.checkbox && checkboxComponent}
        {Array.from(
            new Set(
                props.show
                    .map((item) => originalData.current.find((cell) => cell.name === item))
                    .filter((cell) => cell)
                    .map((cell) => cell.name),
            ),
        ).map((name) => {
          const cell = originalData.current.find((cell) => cell.name === name);
          return (
            <th
              key={uuid4()}
              className={`outline-0 capitalize p-5 border-b border-dotted border-slate-100 first:pl-0 last:pr-0 ${
                cell.sort ?
                (column === cell.name && clicked) ?
                'text-left relative cursor-pointer after:absolute after:right-0 after:top-1/2 after:mt-1 after:w-3 after:h-3 after:opacity-50 after:-translate-y-1/2 after:bg-contain after:bg-[url("components/table/icons/ico-sort-dsc.svg")]':
                'text-left relative cursor-pointer after:absolute after:right-0 after:top-1/2 after:mt-1 after:w-3 after:h-3 after:opacity-50 after:-translate-y-1/2 after:bg-contain after:bg-[url("components/table/icons/ico-sort-asc.svg")]':
                'text-right'
              }`}
              onClick={() => {
                props.callback({name: cell.name, clicked: clicked});
                setClicked(!clicked);
                setColumn(cell.name);
              }}
            >
              {cell.title}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
