import {ClassHelper, Error, Label} from 'components/lib.js';
import React, {useState} from 'react';
import Style from './input.tailwind.js';
import WindowedSelect from 'react-windowed-select';
import Tippy from '@tippyjs/react';
import './tippy.css';
import 'tippy.js/animations/scale.css';

export const BusinessSelect = (props) => {
  const error = props.errorMessage || 'Please enter a value';
  const minChars = props.minChars || 3;
  const messages = {
    typingRequired: `Type at least ${minChars} characters`,
    noDataFound: `No data found`,
  };

  const [options, setOptions] = useState([]);
  const [emptyMessage, setEmptyMessage] = useState(messages.typingRequired);

  const onInputChange = (inputText) => {
    if (inputText.length >= minChars) {
      const optionsFiltered = props.options?.filter((option) =>
        String(option.label)?.toLowerCase()?.includes(String(inputText)?.toLowerCase()),
      );
      setOptions(optionsFiltered);
      if (optionsFiltered.length === 0);
      setEmptyMessage(messages.noDataFound);
    } else {
      if (props.lazyLoad === true) {
        setEmptyMessage(messages.typingRequired);
      } else {
        setEmptyMessage(messages.noDataFound);
      }
    }
  };

  function change(e) {
    const value = e ? e.value : 'unselected';
    let valid = undefined;

    // validate
    valid = props.required && value === 'unselected' ? false : true;
    if (typeof props.onChange === 'function') {
      props?.onChange(props.name, e, valid, value);
    }
    props.callback && props.callback(value);
  }
  // style
  const textStyle = ClassHelper(Style, {
    textbox: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
  });

  // tooltip
  const toolTipContent = (innerProps, label) => {
    // const selectedOne = props.taxonomy === 'B2C' ? b2c.filter((option) => option['field'].includes(label)) : b2b.filter((option) => option['field'].includes(label));
    // if (selectedOne.length > 0 && selectedOne[0].des !== '') {
    //   return (
    //     <Tippy content={selectedOne[0].des} animation='scale'>
    //       <div {...innerProps} className='cursor-pointer my-2 px-2 hover:bg-blue-200'>
    //         {label}
    //       </div>
    //     </Tippy>
    //   );
    // }

    return (
      <div {...innerProps} className='cursor-pointer my-1 px-2 hover:bg-blue-200'>
        {label}
      </div>
    );
  };

  return (
    <div className={Style.input + ' ' + props.containerClass}>
      {props.label && (
        <Label text={props.label} required={props.required} for={props.name} />
      )}
      <WindowedSelect
        className='mb-4'
        options={props.lazyLoad === true ? options : props.options}
        onChange={(e) => change(e)}
        onInputChange={onInputChange}
        isMulti={props.isMulti}
        isSearchable={props.isSearchable}
        defaultValue={props.defaultValue}
        theme={props.theme}
        styles={props.styles}
        closeMenuOnSelect={props.closeMenuOnSelect}
        noOptionsMessage={() => emptyMessage}
        isOptionDisabled={(option) => option.value === undefined}
        id={props.name}
        maxMenuHeight={props.maxMenuHeight || 200}
        components={{
          Option: ({innerProps, label, data}) => toolTipContent(innerProps, label, data)}}
      />

      {props.valid === false && <Error message={error} />}
    </div>
  );
};
