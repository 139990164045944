// From https://themestr.app/builder
export const THEME_DEFAULT = {
  colors: {
    primary: '#3B82F6',
    secondary: '#64748b',
    success: '#27ae60',
    danger: '#e74c3c',
    info: '#0F81C7',
    warning: '#e67e22',
    light: '#ececec',
    dark: '#222222',
  },
};

export const THEME_SIGNAL = {
  colors: {
    primary: '#FF304F',
    secondary: '#CACACA',
    success: '#015668',
    danger: '#06648C',
    info: '#0F81C7',
    warning: '#0DE2EA',
    light: '#ececec',
    dark: '#222222',
  },
};

export const THEME_FRESCA = {
  colors: {
    primary: '#07689F',
    secondary: '#C9D6DF',
    success: '#11D3BC',
    danger: '#F67280',
    info: '#A2D5F2',
    warning: '#FF7E67',
    light: '#FAFAFA',
    dark: '#4e4e4e',
  },
};

