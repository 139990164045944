/** *
*
*   QUOTA
*   import Quota from 'helpers/quota'
*   const [quota, setQuota] = useState(null) ----->  access the quota.permit in the component where you used it
*   
*   PROPS
*   type: pixel2, b2b, b2c, etc
*   quota: quota state from the other component
*   setQuota: setQuota state from the other component
*   limitLabel: label for the limit stat
*   usageLabel: label for the usage stat
*   remainingLabel: label for the remaining stat
*
**********/
import React, { useEffect, useState, Fragment } from 'react';
import { Grid, Stat, Loader, Animate } from 'components/lib';
import { checkLocation } from 'helpers/checklocation';
import axios from 'axios';


const Quota = (props) => {
  const [loading, setLoading] = useState(true); 
  const baseUrl = checkLocation();

  const getQuota = async () => {
    try {
      const quotaResponse = await axios.get(`${baseUrl}/api/access/quota/${props.type}`);
      props.setQuota(quotaResponse.data);
    } catch (error) {
      console.error('Failed to fetch quota:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getQuota();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!props.quota || !props.quota.limit && props.isDetailedQuota ) {
    return null;
  }

  const showResumedQuota = () => {

    let resumeQuota = !props.isDetailedQuota;

    let condition1 = props.quota.limit !== 0 && props.quota.limit !== null;
    let condition2 = props.quota.resultLimit !== 0 && props.quota.resultLimit !== null;

    let value = condition1 ? Math.max(0, props.quota.limit - props.quota.usage) : Math.max(0, props.quota.resultLimit - props.quota.resultUsage);

    return {
      resumeQuota: resumeQuota && condition1 && condition2,
      condition1,
      condition2,
      value
    }

  }


  if (!props.isDetailedQuota && !showResumedQuota().condition1 && !showResumedQuota().condition2) {
    return null;
  }


  return (

    showResumedQuota().resumeQuota ? 
    
    (
      <Fragment>
        <Animate>
          <div className='m-6'>
            <Grid cols="2">
              {props.stats}
              <div className='border rounded-xl p-1 shadow-md'>
                <Stat
                  value={props.quota.limit === (0 || null) ? props.quota.usage : `${props.quota.usage}/${props.quota.limit}`}
                  label={props.unitsLabel}
                  tooltip={props.quota.renew.create ? "refresh-cw" : null}
                />
              </div>
              <div className='border rounded-xl p-1 shadow-md'>
                <Stat
                  value={props.quota.resultLimit === (0 || null) ? props.quota.resultUsage : `${props.quota.resultUsage}/${props.quota.resultLimit}`}
                  label={props.resolutionsLabel}
                  tooltip={props.quota.renew.result ? "refresh-cw" : null}
                />
              </div>
            </Grid>
          </div>
        </Animate>
      </Fragment>
    ) : (
      <Fragment>
        <Animate>
          <div className='m-6'>
            <Grid cols="3">
              {props.stats} 
              <div className='border rounded-xl p-1 shadow-md'>
                <Stat
                  value={props.quota.limit || showResumedQuota().condition1 ? props.quota.limit : props.quota.resultLimit}
                  label={props.limitLabel || (showResumedQuota().condition2 ? "Pixel Resolution Limit" : "Current Pixel Limit")}
                />
              </div>
              <div className='border rounded-xl p-1 shadow-md'>
                <Stat
                  value={props.isDetailedQuota ? props.quota.usage : (showResumedQuota().condition1 ? props.quota.usage : props.quota.resultUsage)}
                  label={props.usageLabel || (showResumedQuota().condition2 ? "Pixel Resolutions" : "Pixels Posted")}
                />
              </div>
              <div className='border rounded-xl p-1 shadow-md'>
                <Stat
                  value={  props.isDetailedQuota ? Math.max(0, props.quota.limit - props.quota.usage) : showResumedQuota().value}
                  label={props.remainingLabel || (showResumedQuota().condition2 ? "Pixel Resolutions Left" : "Pixels Left")}
                  tooltip={props.isDetailedQuota ? (props.quota.renew.create ? "refresh-cw" : null) : showResumedQuota().condition1 ? (props.quota.renew.create ? "refresh-cw" : null) : (props.quota.renew.result ? "refresh-cw" : null)}
                />
            </div>
            </Grid>
        </div>
      </Animate>
    </Fragment>
    )
  );
};

export default Quota;
