/* eslint-disable */
import Axios from "axios";

// components
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PrivateRoute, AuthProvider } from "./auth";
import { View } from "components/lib";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Client } from "@stomp/stompjs";
import { planRoutes } from "routes/signup";
import { SignupLink } from 'views/auth/signup/link';

// 404
import { NotFound } from "views/error/404";

// tailwind css
import "../css/output.css";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateProgress } from "redux/features/audienceBuilder/audienceBuilderSlice";
import { updateKeywordList } from "redux/features/audienceBuilder/keywordPlannerSlice";
import { updatePixelFileProgress } from "redux/features/pixelProcessor/pixelProcessorSlice";
import { updateEnrichment } from "redux/features/enrichment/enrichmentSlice";
import { fromHex } from "helpers/decode";
import ZendeskHelper from "./zendesk";

// settings
const Settings = require("settings.json");
const StripePromise = loadStripe(
  Settings[process.env.NODE_ENV].stripe.publishableAPIKey
);

const routes = [
  ...require("routes/account").default,
  ...require("routes/app").default,
  ...require("routes/auth").default,
  ...require("routes/website").default,
];

const RABBITMQ_MSG_TYPES = {
  keywordPlanner: "keywordPlanner",
  audienceBuilder: "audience",
  pixelDownloader: "pixelDownloader",
  enrichment: "enrichment",
};

const intializeSocketConnection = (dispatch, accountId) => {
  const brokerURL =
    process.env.NODE_ENV === "production"
      ? "wss://builder.audiencelab.io/ws"
      : "ws://builder.audiencelab.io/ws";

  const client = new Client({
    brokerURL: brokerURL,
    connectHeaders: {
      login: process.env.REACT_APP_SOCKET_LOGIN || "audLabFront",
      passcode: process.env.REACT_APP_SOCKET_PASSCODE || "BJWKIN8ro8",
    },
  });

  client.onConnect = function (frame) {
    client.subscribe(
      `/queue/progress_updates_${accountId}`,
      function (message) {
        const parsedMessage = JSON.parse(message.body);
        if (parsedMessage.type === RABBITMQ_MSG_TYPES.audienceBuilder) {
          dispatch(updateProgress(parsedMessage));
        } else if (parsedMessage.type === RABBITMQ_MSG_TYPES.keywordPlanner) {
          dispatch(updateKeywordList(parsedMessage));
        } else if (parsedMessage.type === RABBITMQ_MSG_TYPES.pixelDownloader) {
          dispatch(updatePixelFileProgress(parsedMessage));
        } else if (parsedMessage.type === RABBITMQ_MSG_TYPES.enrichment) {
          dispatch(updateEnrichment(parsedMessage));
        }
      },
      {
        "x-max-length": 1,
      }
    );
  };

  client.onStompError = function (frame) {
    console.log("Broker reported error: " + frame.headers["message"]);
    console.log("Additional details: " + frame.body);
  };

  client.activate();

  return () => {
    client.deactivate();
  };
};

async function signout() {
  Axios({ method: 'delete', url: '/api/auth' });
  localStorage.clear();
  return window.location = '/signin';
}

Axios.interceptors.request.use((config) => {
  return config;
}, (error) => {
  return Promise.reject(error);
});

Axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response) {
    switch (error.response.status) {
      // case 403:
      // case 401:
      //   signout();
        // break;
      case 429:
        console.log('Account limit reached');
        break;
    }
  }
  return Promise.reject(error);
});

export default function App(props) {
  const [signupData, setSignupData] = useState(null);

  useEffect(() => {
    planRoutes()
      .then(data => {

        data.forEach(obj => {
          obj.view = SignupLink;
        });

        setSignupData(data);
      })
      .catch(error => {
        console.error("Error:", error);
      });
  }, []);

  const user = JSON.parse(localStorage.getItem("user"));
  Axios.defaults.baseURL = Settings[process.env.NODE_ENV].server_url;
  const dispatch = useDispatch();

  if (user?.token) {
    // add auth token to api header calls
    Axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
  }

  useEffect(() => {
    if (user?.token) {
      return intializeSocketConnection(dispatch, user?.account_id);
    }
  }, []);

  let filteredRoutes = routes;

  const filterRoutesByPermission = (routeKeywords, permission) => {
    return routes
      .filter((route) => {
        return routeKeywords.some((keyword) => {
          const regex = new RegExp(`\\b${keyword}\\b`);
          return regex.test(route.path);
        });
      })
      .map((route) => ({ ...route, allow: permission }));
  };

  if (user?.access) {

  const accountAccess = fromHex(user.access)

    const routeConfigs = [
      {
        keywords: ["audience", "keyword"],
        permission: accountAccess.audiences || accountAccess.custom_audiences ,
      },
      { keywords: ["/account/users"], permission: accountAccess.users },
      { keywords: ["enrichment"], permission: accountAccess.enrichments },
      { keywords: ["business"], permission: accountAccess.b2b },
      { keywords: ["pixel"], permission: accountAccess.pixels },
    ];

    const selectedRoutes = routeConfigs.reduce(
      (acc, { keywords, permission }) => {
        return [...acc, ...filterRoutesByPermission(keywords, permission)];
      },
      []
    );

    filteredRoutes = routes.map((route) => {
      const selectedRoute = selectedRoutes.find(
        (selectedRoute) => selectedRoute.path === route.path
      );
      return selectedRoute ? selectedRoute : route;
    });
  } else {

    if (user?.account_access) {

    const accountAccess = fromHex(user?.account_access)
    const routeConfigs = [
      {
        keywords: ["audience", "keyword"],
        permission: accountAccess.audiences.allow || accountAccess.custom_audiences.allow,
      },
      { keywords: ["/account/users"], permission: accountAccess.users.allow },
      { keywords: ["enrichment"], permission: accountAccess.enrichments?.allow },
      { keywords: ["business"], permission: accountAccess.b2b?.allow },
      { keywords: ["pixel"], permission: accountAccess.pixels?.allow },
    ];

    const selectedRoutes = routeConfigs.reduce(
      (acc, { keywords, permission }) => {
        return [...acc, ...filterRoutesByPermission(keywords, permission)];
      },
      []
    );

    filteredRoutes = routes.map((route) => {
      const selectedRoute = selectedRoutes.find(
        (selectedRoute) => selectedRoute.path === route.path
      );
      return selectedRoute ? selectedRoute : route;
    });
  }
  }

  const routeSelection = user?.access || user?.account_access ? filteredRoutes.filter((route) => route.allow !== false)
    : routes;


  let combinedRoutes;

  if (signupData) {

    combinedRoutes = routeSelection.concat(signupData)
  } else {
    combinedRoutes = routeSelection

  }


  // render the routes
  return (
    <Elements stripe={StripePromise}>
      <AuthProvider>
        <BrowserRouter>
          <ZendeskHelper />
          <Routes>
            {combinedRoutes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    route.permission ? (
                      <PrivateRoute permission={route.permission}>
                        <View
                          display={route.view}
                          layout={route.layout}
                          title={route.title}
                        />
                      </PrivateRoute>
                    ) : (
                      <View
                        display={route.view}
                        layout={route.layout}
                        title={route.title}
                      />
                    )
                  }
                />
              );
            })}
            {/* 404 */}
            <Route
              path="*"
              element={
                <View layout="home" title="" />
              }
            />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </Elements>
  );
}
