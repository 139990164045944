import {Fragment, useLayoutEffect, useRef, useState} from 'react';

export const Checkbox = (props) => {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);

  const selectAll = () => {
    props.setSelectedItem(checked ? [] : (props.search ? props.search : props.data));
    setChecked(!checked);
  };

  useLayoutEffect(() => {
    setChecked(props.selectedItem?.length === props.data?.length);
  }, [props.selectedItem]);

  return (
    <Fragment>
      {props.header && (
        <input
          type="checkbox"
          className="absolute left-6 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-500  sm:left-4"
          ref={checkbox}
          checked={checked}
          onChange={selectAll}
        />
      )}
      {props.body && (
        <input
          type="checkbox"
          className="absolute left-6 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-400 sm:left-4"
          value={props.item?.email}
          checked={props.selectedItem?.includes(props.item)}
          onChange={(e) =>
            props.setSelectedItem(
              e.target.checked ?
              [...props.selectedItem, props.item] :
              props.selectedItem.filter((p) => p !== props.item),
            )}
        />
      )}
    </Fragment>
  );
};
