/** *
*
*   APP NAV
*   Primary navigation used inside the main app component
*
*   PROPS
*   type: fixed or popup (style of nav on mobile)
*   items: array of objects containing label, link and icon (optional)
*
**********/

import {useState, useContext} from 'react';
import {NavLink} from 'react-router-dom';
import {Logo, AuthContext, Icon} from 'components/lib';
import './app.scss';

export function AppNav(props) {
  const authContext = useContext(AuthContext);
  // state
  const [open, setOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  // navbar splice
  const regularNavOptions = props.items.filter(obj => obj.label !== "Account" && obj.label !== "Sign Out");
  const settingsNavoptions = props.items.filter(obj => obj.label === "Account" || obj.label === "Sign Out");

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  return (
    <nav className={`sidebar popup ${open && 'open expanded'}`} style={{ zIndex: 9999 }}
      onMouseEnter={ (e) => setOpen(true)}
      onMouseLeave={ (e) => {
        setOpen(false)
        setSettingsOpen(false)
      }}
    >

    <Logo color={'color'} mark />

      <section className='nav-links'>
        { regularNavOptions?.map((item) => {
          const isActive = window.location.pathname === item.link;
          if (item.link) {
            return (
              <NavLink
                key={ item.label }
                to={ item.link }
                style={{width: (100/regularNavOptions.length) + '%'}}
                activeclassname={'active'}
                >

                { item.icon &&
                  <Icon
                  className={`icon ${isActive ? 'active' : ''}`}
                    image={ item.icon }
                    size={ 15 }
                    color={ 'dark' }
                  />
                }
                { item.custom &&
                  <Icon
                    className='icon'
                    costum={ item.custom }
                    size={ 15 }
                    color={ 'dark' }
                  />
                }
                { item.label &&
                  <span className='label'>
                    { item.label }
                  </span>
                }

              </NavLink>
            );
          }

          return (
            <div key={ item.label } onClick={ item.action }>

              { item.icon &&
                <Icon
                  className='icon'
                  image={ item.icon }
                  size={ 15 }
                  color={ 'dark' }
                />
              }
              { item.label &&
                <span className='label'>
                  { item.label }
                </span>
              }
            </div>
          );
        })}
      </section>

      {/* Settings Toggle */}
      <section className='settings-nav-links'>
        <div onClick={toggleSettings}>
          <Icon
            className='icon'
            image={ 'settings' }
            size={ 15 }
            color={ 'dark' }
          />
          <span className='label'>
            { authContext.user?.name?.match(/^\w+/)[0] }
          </span>
        </div>

        {/* Settings Dropdown */}
        {settingsOpen && settingsNavoptions?.map((item) => {
          const isActive = window.location.pathname === item.link;
          if (item.link) {
            return (
              <NavLink
                key={ item.label }
                to={ item.link }
                style={{width: (100/settingsNavoptions.length) + '%'}}
                activeclassname={'active'}
                >

                { item.icon &&
                  <Icon
                  className={`icon ${isActive ? 'active' : ''}`}
                    image={ item.icon }
                    size={ 15 }
                    color={ 'dark' }
                  />
                }
                { item.custom &&
                  <Icon
                    className='icon'
                    costum={ item.custom }
                    size={ 15 }
                    color={ 'dark' }
                  />
                }
                { item.label &&
                  <span className='label'>
                    { item.label }
                  </span>
                }

              </NavLink>
            );
          }

          return (
            <div key={ item.label } onClick={ item.action }>

              { item.icon &&
                <Icon
                  className='icon'
                  image={ item.icon }
                  size={ 15 }
                  color={ 'dark' }
                />
              }
              { item.label &&
                <span className='label'>
                  { item.label }
                </span>
              }
            </div>
          );
        })}
      </section>
    </nav>
  );
}
