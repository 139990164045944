import React, {useState} from 'react';
import axios from 'axios';

const ModalConnect  = ({platform, baseUrl, closeModal, clearModal, edit, isOpen, errorNotification, successNotification, getIntegrations}) => {
  const [nameInput, setNameInput] = useState('');
  const [apiInput, setApiInput] = useState('');

  const handleNameInput = (e) => {
    setNameInput(e.target.value);
  };

  const handleApiInput = (e) => {
    e.preventDefault();
    setApiInput(e.target.value);
  };

  const sendForm = async (e) => {
    e.preventDefault();

    const formOptions = {
      name: nameInput,
      type: platform.platform
    };
    
    if (platform.platform === 'klaviyo') {
      formOptions.api_key = `Klaviyo-API-Key ${apiInput}`;
    }

    if (platform.platform === 'goHighLevel') {
      formOptions.api_key = apiInput;
    }

    try {
      await axios.post(`${baseUrl}/api/integrationAccount`, formOptions);
      successNotification('Connect');
      closeModal();
      getIntegrations();
    } catch (error) {
      errorNotification('Connect', error.response.data.message);
      closeModal();
    }
  };

  if (platform.type !== 'Connect') return null;
  return (
    isOpen &&
    platform.type === 'Connect' && (
      <div className="modal modal-appear-done modal-enter-done">
        <div className="modal-content">
          <section className="relative p-6 x-1 mb-6 bg-white rounded">
            <header className="relative pb-5 mb-5 border-dotted border-slate-100 border-b block text-sm uppercase">
              <h1 className="float:left m-0 text-lg font-semibold">
                {`Connect with ${platform.platform}`}
              </h1>
            </header>
            <form className="">
              <input type="hidden" id="name" name="name" value="4d024eab-7d7c-45e5-8f8c-9d444c4b42ab"/>
              <div className="relative mb-10 last:mb-0">
                <label className="relative block text-sm mb-2 uppercase after:text-red-500 after:ml-1 after:font-semibold after:content-['*']">Integration Name</label>
                <input type="text" name="name" onChange={(e) => handleNameInput(e)} className="relative block w-full p-3 rounded bg-white border border-solid border-slate-100 focus:bg-slate-50 appearance-none disabled:opacity-50"/>
              </div>
              <div className="relative mb-10 last:mb-0">
                <label className="relative block text-sm mb-2 uppercase after:text-red-500 after:ml-1 after:font-semibold after:content-['*']">API Key</label>
                <input type="text" name="name" onChange={(e) => handleApiInput(e)} className="relative block w-full p-3 rounded bg-white border border-solid border-slate-100 focus:bg-slate-50 appearance-none disabled:opacity-50"/>
              </div>
              <button onClick={sendForm} className="cursor-pointer mt-5 font-sans w-[49%] mr-[2%] last:mr-0 bg-blue-500 text-white hover:bg-blue-600 hover:border-blue-600 relative block text-center font-semibold rounded px-12 py-3 ease-in-out duration-500">{edit ? 'Update' : 'Connect'}</button>
              <button onClick={clearModal} className="cursor-pointer mt-5 font-sans text-slate-500 border-solid border border-slate-200 hover:text-white hover:border-slate-500 bg-transparent w-[49%] mr-[2%] last:mr-0 bg-zinc-900 hover:bg-black relative block text-center font-semibold rounded px-12 py-3 ease-in-out duration-500">Cancel</button>
            </form>
          </section>
        </div>
      </div>
    )
  );
}

export default ModalConnect;