import {Audience} from 'views/audience/audience';
import {AudienceWizard} from 'views/audience/audience_wizard';
import {Pixel} from 'views/pixel/pixel';
import {PixelWizard} from 'views/pixel/pixel_wizard';
import {PixelDashboard} from 'views/pixel/dashboard';
import {DownloadStatus} from 'views/pixel/download_status';
// import {Help} from 'views/dashboard/help';
import {HelpFrame} from 'views/dashboard/iframeHelp';
import {Enrichment} from 'views/enrichment/enrichmentPage';
import {Integrations} from 'views/integrations/integrations';
import Facebook from 'views/integrations/facebook';
import {UploaderPage} from 'views/enrichment/uploaderPage';
import {B2bIndex} from 'views/b2b/b2b_index';
import {B2bBuilder} from 'views/b2b/b2b_builder';

const Routes = [
  {
    path: '/help',
    view: HelpFrame,
    layout: 'app',
    permission: 'user',
    title: 'Get Help',
  },
  {
    path: '/audience',
    view: Audience,
    layout: 'app',
    permission: 'user',
    title: 'Manage Audience',
  },
  {
    path: '/audience/wizard/:id',
    view: AudienceWizard,
    layout: 'app',
    permission: 'user',
    title: 'Audience',
  },
  {
    path: '/',
    view: Audience,
    layout: 'app',
    permission: 'user',
    title: 'Manage Audiences',
  },
  {
    path: '/pixel',
    view: Pixel,
    layout: 'app',
    permission: 'user',
    title: 'Pixel',
  },
  {
    path: '/pixel/wizard',
    view: PixelWizard,
    layout: 'app',
    permission: 'user',
    title: 'Create Pixel',
  },
  {
    path: '/pixel/:id',
    view: PixelDashboard,
    layout: 'app',
    permission: 'user',
    title: 'Pixel Dashboard',
  },
  {
    path: '/pixel/:id/status',
    view: DownloadStatus,
    layout: 'app',
    permission: 'user',
    title: 'Pixel Dashboard',
  },
  {
    path: '/enrichment',
    view: Enrichment,
    layout: 'app',
    permission: 'user',
    title: 'Enrichment',
  },
  {
    path: '/integrations',
    view: Integrations,
    layout: 'app',
    permission: 'user',
    title: 'Integrations',
  },
  {
    path: '/integrations/facebook',
    view: Facebook,
    layout: 'app',
    permission: 'user',
    title: 'Facebook',
  },
  {
    path: '/enrichment/upload',
    view: UploaderPage,
    layout: 'app',
    permission: 'user',
    title: 'Enrichment',
  },
  {
    path: '/business',
    view: B2bIndex,
    layout: 'app',
    permission: 'user',
    title: 'B2B',
  },
  {
    path: '/business/:id',
    view: B2bBuilder,
    layout: 'app',
    permission: 'user',
    title: 'Business Builder',
  },
];

export default Routes;
