const fromHex = (hex) => {
  if (typeof hex === 'object' || hex === null) {
    return hex;
  }
  if (typeof hex === 'string') {
    const bytes = new Uint8Array(hex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
    const decoder = new TextDecoder();
    try {
      return JSON.parse(decoder.decode(bytes));
    } catch (error) {
      console.error('Could not decode the string:', error);
      return null;
    }
  }
  return null;
};

export {fromHex};
