/** *
*
*   FORGOT PASSWORD
*   Trigger a password reset process
*
**********/

import React, {useContext, useRef, useState} from 'react';
import {Animate, Row, Form, Card, ViewContext} from 'components/lib';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

export function ForgotPassword(props) {
  const context = useContext(ViewContext);
  const captchaRef = useRef(null);
  const [valid, setValid] = useState(false);

  // Recaptcha

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    if (valid === false) {
      context.notification('Please verify you are not a robot', 'error', true);
    }

    const res = await axios.post(
        `https://www.google.com/recaptcha/api/siteverify?secret=${process.env.REACT_APP_RECAPTCHA_SECRET_KEY}&response=${token}`, {
          headers: {
            'Content-Security-Policy': '*', 'Content-type': 'application/json; charset=UTF-8',
          },
        },
    );
    if (res.status(200)) {
      res.send('Human 👨 👩');
      setValid(true);
    } else {
      res.send('Robot 🤖');
      context.notification('Please verify you are not a robot', 'error', true);
      setValid(false);
    }
  };

  const handleOnChange = () => {
    const token = captchaRef.current.getValue();
    token ? setValid(true) : setValid(false);
  };

  return (
    <Animate type='pop'>
      <Row
        title='Reset your password'
      >
        <Card restrictWidth center>

          <p className='mb-5'>Enter your email address and we'll send you
          instructions to reset your password.</p>

          <Form
            data={{
              email: {
                label: 'Email',
                type: 'email',
                required: true,
              },
            }}
            url={valid && '/api/auth/password/reset/request'}
            method='POST'
            buttonText={valid ? 'Send' : 'Verify you are not a robot'}
            onSubmit={handleOnSubmit}
            callback={ context.signin }
          />
          <br />
          <div className="flex mx-auto">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              ref={captchaRef}
              onChange={handleOnChange}
            />
          </div>
        </Card>
      </Row>
    </Animate>
  );
}
