/* eslint-disable no-constant-condition */
import {createSlice} from '@reduxjs/toolkit';

export const facebookSlice = createSlice({
  name: 'facebook',
  initialState: {
    steps: [],
    currentStep: 1,
  },
  reducers: {
    initialStep: (state, action) => {
      state.steps = action.payload;
    },
    setStep: (state, action) => {
      const { name } = action.payload;
      const currentState = state.steps
      const targetIndex = state.steps.findIndex((step) => step.name === name);
      if (targetIndex !== -1) {
        state.steps = currentState.map((step, index) => {
          if (index < targetIndex) {
            return { ...step, status: 'complete' };
          } else if (index === targetIndex) {
            return { ...step, status: 'current' };
          } else if (index > targetIndex) {
            return { ...step, status: 'upcoming' };
          }
        });
        state.currentStep = targetIndex + 1;
        if (state.currentStep === 10) state.currentStep = 0;
      }
    },
    nextStep: (state, action) => {
      const currentState = state.steps
      const targetIndex = state.currentStep;
      if (targetIndex !== -1) {
        state.steps = currentState.map((step, index) => {
          if (index < targetIndex) {
            return { ...step, status: 'complete' };
          } else if (index === targetIndex) {
            return { ...step, status: 'current' };
          } else if (index > targetIndex) {
            return { ...step, status: 'upcoming' };
          }
        });
        state.currentStep += 1;
        if (state.currentStep === 10) state.currentStep = 0;
      }
    },
  },
});

export const { initialStep, setStep, nextStep } = facebookSlice.actions;
export default facebookSlice.reducer;
