import {useContext} from 'react';
import {ViewContext, Card, Form} from 'components/lib';
import {CSSTransition} from 'react-transition-group';

export function NewModal({ title, children, hideModal }) {
  return (
    <CSSTransition in appear timeout={0} classNames='modal'>
      <div className='modal' onClick={(e) => e.target === e.currentTarget && hideModal()}>
        <div className='modal-content'>
          <Card title={title} className="h-[350px]">
           
          <div className="mt-10 mb-10">
            {children}
          </div>
           
          </Card>
        </div>
      </div>
    </CSSTransition>
  );
}
