const Style = {

  onboarding: 'relative w-[95%] mt-16 mx-auto mb-12 shadow-md lg:w-[65%] lg:min-h-[25em]',
  sidebar: `text-white p-5 rounded-t bg-zinc-900 md:absolute md:top-0 md:left-0 md:bottom-0 
  md:z-10 md:rounded-tr-none md:rounded-bl md:w-48`,
  logo: 'm-0',
  main: 'relative min-h-[28em] p-8 pt-6 pb-14 bg-white rounded-b md:rounded md:pl-56',
  header: 'w-full pb-4 mb-4 bg-transparent border-b border-dotted border-slate-200',
  name: 'capitalize text-xl font-semibold',
  description: 'text-sm text-slate-400',
  checklist: 'mt-4 md:absolute md:top-1/2 md:-translate-y-1/2 md:left-6',
  nav: 'absolute flex flex-col space-y-4 bottom-3 left-2 right-12 mx-auto md:left-56 md:flex-row md:space-y md:place-content-between md:space-y-0',
  generator: 'flex mx-auto self-center md:w-[35] md:self-start',
  prev: 'float-left mr-4 mx-auto md:mr-1',
  next: 'float-right ml-4 mx-auto md:ml-1',
  skip: '-top-7 right-0 text-sm text-white',
  article: 'relative min-h-[25em] mb-14 overflow-y-auto md:mb-0 md:pl-1'

}

export default Style;