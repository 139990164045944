/** *
*
*   PASSWORD
*   Update the users password
*
*   if the user has a password show old and new inputs
*   if no existing password (eg. in case of social signin)
*   then allow the user to set a password on their account
*
**********/

import React, {Fragment, useContext, useState, useEffect} from 'react';
import {AuthContext, Animate, ViewContext, Message, Icon} from 'components/lib';
import axios from 'axios';

const Password = (props) => {
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const [done, setDone] = useState(false);

  // form state
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  // form
  const renderInput = (label, value, setPassword, newPassword = null) => {
    const [showPassword, setShowPassword] = useState(false);
    const [isValid, setIsValid] = useState(undefined);
  
    const handleValueChange = (e) => {
      const password = e.target.value;
      setPassword(password);
  
      if (label === 'Confirm New Password') {
        setIsValid(password === newPassword);
      } else {
        setIsValid(undefined);
      }
    };
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
  
    return (
      <div className="ml-3 justify-center items-center">
        <label>{label}</label>
        <span className='flex flex-wrap mt-2 mb-4 min-h-[42px] relative w-full rounded-lg border border-gray-300'>
          <input
            className='resize-none overflow-hidden h-8 border-none border-transparent focus:border-transparent focus:ring-0 m-1 w-full'
            id={label}
            type={showPassword ? 'text' : 'password'}
            placeholder='Type here'
            onChange={handleValueChange}
            value={value}
          />
          <button 
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
          >
            <Icon image={showPassword ? "eye" : "eye-off"} size={18} />
          </button>
          {isValid !== undefined && (
            <span className="absolute inset-y-0 right-10 pr-3 flex items-center">
              <Icon image={isValid ? "check" : "x"} size={18} color={isValid ? "green" : "red"} />
            </span>
          )}
        </span>
      </div>
    );
  };

  // password actions
  const savePassword = async () => {
    const data = {
      oldpassword: oldPassword,
      newpassword: newPassword,
      confirm_newpassword: confirmNewPassword,
      has_password: authContext.user.has_password,
    };
  
    // validations
    try {
      if (newPassword !== confirmNewPassword) {
        viewContext.notification.show('Passwords do not match', 'error', true);
        return;
      }
      if (newPassword.length < 6 || oldPassword.length < 6 || confirmNewPassword.length < 6) {
        viewContext.notification.show('Passwords must be at least 6 characters', 'error', true);
        return;
      }
      if (oldPassword === newPassword || confirmNewPassword === oldPassword) {
        viewContext.notification.show('New password must be different from old password', 'error', true);
        return;
      }
      if (confirmNewPassword === newPassword && authContext.user.has_password) {
        axios.put('/api/user/password', data);
        authContext.update({has_password: true});
        viewContext.notification.show('Password updated', 'success', true);
      }
    } catch (error) {
      viewContext.notification.show('Failed to update password', 'error', true);
      console.log(error);
      return;
    }
  };

  return (
    <Fragment>

      <Animate>
      {
        done ?
        <div className='m-6'>
          <Message
            title='Password Updated'
            text='Your password has been updated successfully.'
            type='success'
            onClose={() => setDone(false)}
          /> 
        </div>
        :
        <div className="flex flex-col justify-between h-screen">
          {/* View */}
          <div>
            {/* Title */}
            <h2 className="m-6 text-3xl font-semibold">Password settings</h2>

            {/* Form */}
            <div className='flex flex-col mr-6 ml-4'>
              {renderInput('Old Password', oldPassword, setOldPassword)}
              {renderInput('New Password', newPassword, setNewPassword)}
              {renderInput('Confirm New Password', confirmNewPassword, setConfirmNewPassword, newPassword)}
            </div>
          </div>

          {/* Buttons */}
          <div className='flex justify-between items-end m-6'>
            <div></div>
            <button
              type="button"
              onClick={savePassword}
              className="rounded-md bg-blue-600 px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Save
            </button>
          </div>
        </div>
      }
      </Animate>
    </Fragment>
  );
}

export default Password;
