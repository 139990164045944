import axios from 'axios';

class Formatation {
  dates = (date) => {
    const year = new Date(date).getFullYear();
    const month = (1 + new Date(date).getMonth()).toString().padStart(2, '0');
    const day = new Date(date).getDate().toString().padStart(2, '0');
    return `${month}/${day}/${year}`;
  };

  hours = (date) => {
    const hours = new Date(date).getHours().toString().padStart(2, '0');
    const minutes = new Date(date).getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  seconds = (date) => {
    const hours = new Date(date).getHours().toString().padStart(2, '0');
    const minutes = new Date(date).getMinutes().toString().padStart(2, '0');
    const seconds = new Date(date).getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  timestampTable = (date) => {
    return `${this.dates(new Date(date))} ${this.hours(new Date(date))}`;
  };

  timestampModal = (date) => {
    return `${this.dates(new Date(date))} ${this.seconds(new Date(date))}`;
  };

  timezones = async (setTimezone) => {
    try {
      const response = await axios.get('https://pro.ip-api.com/json/?key=wunPMq4TPEWY6fu');
      const data = response.data;
      setTimezone(data.timezone); // Update the 'timezone' property of the class with the fetched data
    } catch (error) {
      console.log('You can not see the correct timezone, because your browser is blocking the request');
    }
  };
}

export default Formatation;

// HOW TO GET TIMEZONEs:
// const [timezone, setTimezone] = useState('CST');

// useEffect(() => {
//   format.timezones(setTimezone);
// }, []);
