const Style = {

  base: 'inline-block mx-1 py-1 px-2.5 text-white uppercase text-xs rounded',
  red: 'bg-newred',
  blue: 'bg-newblue',
  green: 'bg-newgreen',
  orange: 'bg-orange-500',
  bluedark: 'bg-blueage py-1 px-2.5 text-white font-semibold uppercase rounded',

}

export default Style;