/** *
 *
 *   ACCOUNT
 *   Index page for account functions
 *
 **********/

import React, { useState, useEffect, useCallback } from 'react';
import { Animate, Loader } from 'components/lib';
import axios from 'axios';
import { checkLocation } from 'helpers/checklocation';
import { useSelector, useDispatch } from 'react-redux';
import { setStep, initialStep } from 'redux/features/facebook/facebookSlice';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Facebook = (props) => {
  const dispatch = useDispatch();
  const baseUrl = checkLocation();
  const [user, setUser] = useState({ loading: true, data: null, error: null });

  const getUser = useCallback(async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/user`);
      setUser({ loading: false, data: response?.data?.data });
    } catch (error) {
      setUser({ loading: false, data: null, error: error });
    }
  }, [baseUrl]);

  // index user interface
  const steps = useSelector((state) => state.facebook.steps);

  const handleStepClick = (stepName) => {
    dispatch(setStep({ name: stepName }));
  };

  const renderCurrentStep = () => {
    const currentStep = steps.find((step) => step.status === 'current');
    if (currentStep) {
      const componentName = currentStep.component;
      const Component = React.lazy(() => import(`./${componentName}`));
      return (
        <React.Suspense fallback={<div>Loading...</div>}>
          <Component key={currentStep.name} />
        </React.Suspense>
      );
    }
    return null;
  };

  // navigation
  const views = [
    {
      name: 'Status',
      status: 'upcoming',
      component: 'logs',
    },
    {
      name: 'Ad Accounts',
      status: 'current',
      component: 'adaccounts',
    },
    {
      name: 'Custom Audiences',
      status: 'upcoming',
      component: 'customaudiences',
    },
    {
      name: 'Business Accounts',
      status: 'upcoming',
      component: 'businessaccounts',
    },
  ];

  useEffect(() => {
    dispatch(initialStep(views));
    getUser();
  }, [dispatch, getUser]);

  if (user.loading) {
    return <Loader />;
  }

  return (
    <Animate>
      <div className='grid grid-rows-10 grid-cols-5 gap-4 h-screen pl-0 pr-2'>
        {/* Navbar */}
        <nav aria-label="Progress" className='row-span-10 col-span-1 h-screen flex flex-col justify-between border-r'>
          <ol role="list" className="overflow-hidden bg-white pt-6">
            <h2 className="text-3xl ml-3 mb-3 first-line:font-semibold">Facebook</h2>
            {steps.map((step, stepIdx) => (
              <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pb-2' : '', 'relative')}>
                {step.status === 'current' ? (
                  <>
                    <div onClick={() => handleStepClick(step.name)} className="group relative flex items-center cursor-pointer bg-gray-100 rounded-md p-2 mr-4" aria-current="step">
                      <span className="ml-2 text-sm font-medium text-blue-700">{step.name}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div onClick={() => handleStepClick(step.name)} className="group relative flex items-center cursor-pointer p-2 hover:bg-gray-50 hover:rounded-md mr-4">
                      <span className="ml-2 text-sm font-medium text-gray-500">{step.name}</span>
                    </div>
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav>

        {/* Current View */}
        <div className='col-start-2 col-end-6 flex flex-col justify-between'>
          {renderCurrentStep()}
        </div>
      </div>
    </Animate>
  );
}

export default Facebook;
