/** *
 *
 *   VIEW
 *   The view houses global components that are common to all views
 *   (notification, modal), handles errors and renders the layout
 *
 **********/

import { useState, createContext } from "react";
import {
  AppLayout,
  AuthLayout,
  HomeLayout,
  OnboardingLayout,
  Modal,
  NewModal,
  Notification,
  Loader,
  useNavigate,
} from "../lib";

// import './scss/normalize.scss';
// import './scss/view.scss';
// import './scss/typography.scss';

export const ViewContext = createContext();

export function View(props) {
  const navigate = useNavigate();

  // state
  const [notification, setNotification] = useState({
    visible: "hide",
    autoclose: true,
  });
  const [modal, setModal] = useState({});
  const [newModal, setNewModal] = useState({ show: false, content: null });

  const [loading, setLoading] = useState(false);

  // layouts
  const layouts = {
    app: AppLayout,
    home: HomeLayout,
    auth: AuthLayout,
    onboarding: OnboardingLayout,
  };

  // set title & layout
  document.title = props.title;
  const Layout = props.layout ? layouts[props.layout] : AppLayout;

  if (!props.display) {
    return false;
  }

  function hideNotification() {
    setNotification({
      text: "",
      type: "",
      show: false,
      format: null,
      icon: null,
      autoclose: true,
    });
  }

  function showNotification(text, type, autoclose, format, icon) {
    setNotification({
      text: text,
      type: type,
      show: true,
      format: format,
      icon: icon,
      autoclose: autoclose,
    });
  
    if (autoclose) setTimeout(hideNotification, 3000);
  }

  function showNotificationLong(text, type, autoclose, format, icon) {
    setNotification({
      text: text,
      type: type,
      show: true,
      format: format,
      icon: icon,
      autoclose: autoclose,
    });
  
    if (autoclose) setTimeout(hideNotification, 6000);
  }  

  function showNewModal(content) {
    setNewModal({ show: true, content });
  }

  function hideNewModal() {
    setNewModal({ show: false, content: null });
  }

  function showModal(content, callback) {
    const data = { ...modal };

    if (content) {
      for (const key in content) {
        data[key] = content[key];
      }

      data.show = true;
      data.callback = callback;
    }

    setModal(data);
  }

  function hideModal(cancel, res) {
    // callback?
    if (!cancel && modal.callback) {
      modal.callback(modal.form, res);
    }

    // reset the modal
    setModal({
      title: null,
      text: null,
      buttonText: null,
      url: null,
      method: null,
      show: false,
    });
  }

  function handleError(err) {
    let message = "There was a glitch in the matrix – please try again";

    if (err) {
      message = err.toString();

      if (err.response) {
        if (err.response.data?.message) {
          message = err.response.data.message;
        }

        if (err.response.status) {
          switch (err.response.status) {
            case 401:
              navigate("/signin");
              break;

            case 404:
              navigate("/notfound");
              break;

            case 429:
              showNotification(err.response.data, "error", false);
              break;

            case 402: // payment required
              navigate("/account/upgrade?plan=" + err.response.data.plan);
              break;
            case 500: // internal server error
              showNotification(
                "Error on Authentication. Please, try again",
                "error",
                false
              );
              break;

            default:
              showNotification(message, "error", false);
              break;
          }
        }
      }
    }
  }

  const context = {
    notification: {
      show: showNotification,
      showLong: showNotificationLong,
      hide: hideNotification,
      data: notification,
    },
    modal: {
      show: showModal,
      hide: hideModal,
      data: modal,
    },
    newModal: {
      show: showNewModal,
      hide: hideNewModal,
      data: newModal,
    },

    setLoading: (state) => setLoading(state),
    handleError: handleError,
  };

  return (
    <ViewContext.Provider value={context}>
      {notification.show && <Notification {...notification} />}

      {loading && <Loader fullScreen />}

      {modal.show && <Modal {...modal} />}

      {newModal.show && (
        <NewModal title={newModal.content?.title} hideModal={hideNewModal}>
          {newModal.content?.children}
        </NewModal>
      )}

      <Layout title={props.title} data={props.data}>
        {props.display}
      </Layout>
    </ViewContext.Provider>
  );
}
