import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Stat, Loader, Animate } from 'components/lib';
import axios from 'axios';
import { checkLocation } from 'helpers/checklocation';

const EnrichmentsQuota = () => {
  const [loading, setLoading] = useState(true);
  const [enrichmentsData, setEnrichmentsData] = useState(null);
  const [gridCount, setGridCount] = useState(3);
  const baseUrl = checkLocation();

  const getQuota = async () => {
    try {
      const quotaResponse = await axios.get(`${baseUrl}/api/access/`);

      const quotaEnrichments = quotaResponse.data.data.enrichments

      setEnrichmentsData(quotaEnrichments);

    } catch (error) {
      console.error('Failed to fetch quota:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getQuota();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Animate>
        <div className='m-6'>
          <Grid cols={gridCount.toString()}>
            {enrichmentsData?.create?.limit > 0 && (


<>

              <div className='border rounded-xl p-1 shadow-md'>
                <Stat
                  value={`${enrichmentsData.create.limit}`}
                  label={"Current Enrichment limit"}
                />
              </div>
              
              <div className='border rounded-xl p-1 shadow-md'>
                <Stat
                  value={`${enrichmentsData.create.usage}`}
                  label={"Enrichments created"}
                  tooltip={enrichmentsData.create.renew ? "refresh-cw" : null}
                />
                </div>

                </>
            )}
            <div className='border rounded-xl p-1 shadow-md'>
            <Stat
              value={`${enrichmentsData.result.limit}`}
              label={"Current Enrichment Result limit"}
            />
</div>

          </Grid>
        </div>
      </Animate>
    </Fragment>
  );
};

export default EnrichmentsQuota;
