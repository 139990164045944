const COLUMN_DATATYPE = {
  ADDR_ABREV: 'STRING',
  ADDR_POST: 'STRING',
  ADDR_PRE: 'STRING',
  ADDR_PRIMARY: 'STRING',
  ADDR_SECY: 'STRING',
  ADDR_STREET: 'STRING',
  ADDRESS: 'STRING',
  ADDRESS_ID: 'INT',
  ADDRESS_TYPE: 'STRING',
  ADULTS_F_18_24: 'STRING',
  ADULTS_F_25_34: 'STRING',
  ADULTS_F_35_44: 'STRING',
  ADULTS_F_45_54: 'STRING',
  ADULTS_F_55_64: 'STRING',
  ADULTS_F_65_74: 'STRING',
  ADULTS_F_75PLUS: 'STRING',
  ADULTS_M_18_24: 'STRING',
  ADULTS_M_25_34: 'STRING',
  ADULTS_M_35_44: 'STRING',
  ADULTS_M_45_54: 'STRING',
  ADULTS_M_55_64: 'STRING',
  ADULTS_M_65_74: 'STRING',
  ADULTS_M_75PLUS: 'STRING',
  ADULTS_U_18_24: 'STRING',
  ADULTS_U_25_34: 'STRING',
  ADULTS_U_35_44: 'STRING',
  ADULTS_U_45_54: 'STRING',
  ADULTS_U_55_64: 'STRING',
  ADULTS_U_65_74: 'STRING',
  ADULTS_U_75PLUS: 'STRING',
  AGE_RANGE: 'STRING',
  AUTO_BODY_TYPE: 'STRING',
  AUTO_DEF_ENGINE_CYLINDERS: 'INT',
  AUTO_DEF_TRANS_TYPE: 'STRING',
  AUTO_FUEL_TYPE: 'STRING',
  AUTO_GVW_RANGE: 'STRING',
  AUTO_MAKE: 'STRING',
  AUTO_MFG_CODE: 'STRING',
  AUTO_MSRP: 'INT',
  AUTO_STYLE: 'STRING',
  AUTO_VEHICLE_TYPE: 'STRING',
  AUTO_VIN_: 'STRING',
  AUTO_YEAR_: 'STRING',
  AUTOALARMTYPE: 'STRING',
  AUTOANNUALMILES: 'STRING',
  AUTOCOMMUTEDISTANCE: 'INT',
  AUTOHASABS: 'STRING',
  AUTOHASAIRBAGS: 'STRING',
  AUTOINSBODILYINJURYCOVERAGE: 'STRING',
  AUTOINSCOLLISIONDEDUCTIBLE: 'INT',
  AUTOINSCOMPREHENSIVEDEDUCTIBLE: 'INT',
  AUTOINSCOVERAGETYPE: 'STRING',
  AUTOINSCOVERAGETYPEREQUESTED: 'STRING',
  AUTOINSPROPERTYDAMAGECOVERAGE: 'INT',
  AUTOMAKE: 'STRING',
  AUTOPRIMARYUSE: 'STRING',
  AUTOYEAR: 'INT',
  CC_AMEX_PREM: 'STRING',
  CC_AMEX_REG: 'STRING',
  CC_BANK_CD_IN_HH: 'INT',
  CC_DISC_PREM: 'STRING',
  CC_DISC_REG: 'STRING',
  CC_GAS_PREM: 'STRING',
  CC_GAS_REG: 'STRING',
  CC_HLDR_BANK: 'STRING',
  CC_HLDR_GAS: 'STRING',
  CC_HLDR_PREM: 'STRING',
  CC_HLDR_TE: 'STRING',
  CC_HLDR_UNK: 'STRING',
  CC_HLDR_UPS_DEPT: 'STRING',
  CC_MC_PREM: 'STRING',
  CC_MC_REG: 'STRING',
  CC_NEW_ISSUE: 'STRING',
  CC_USER: 'STRING',
  CC_VISA_PREM: 'STRING',
  CC_VISA_REG: 'STRING',
  CELLPHONE_1: 'BIGINT',
  CELLPHONE_1_CARRIER: 'STRING',
  CELLPHONE_1_DNC_FLAG: 'STRING',
  CELLPHONE_2: 'BIGINT',
  CELLPHONE_2_CARRIER: 'STRING',
  CELLPHONE_2_DNC_FLAG: 'STRING',
  CENSUS_BLOCK_GROUP: 'INT',
  CENSUS_TRACT: 'INT',
  CITY: 'STRING',
  CREDIT_LINES: 'INT',
  CREDIT_RANGE_NEW: 'STRING',
  CREDIT_RATING: 'STRING',
  DOB_DAY: 'INT',
  DOB_MON: 'INT',
  DOB_YR: 'INT',
  DOMAIN: 'STRING',
  DONR_ANIMAL: 'STRING',
  DONR_ARTS: 'STRING',
  DONR_CHARITABLE: 'STRING',
  DONR_COMM_CHAR: 'STRING',
  DONR_ENVIRON: 'STRING',
  DONR_HEALTH: 'STRING',
  DONR_INTL_AID: 'STRING',
  DONR_KIDS: 'STRING',
  DONR_MAIL_ORD: 'STRING',
  DONR_OTH: 'STRING',
  DONR_POL: 'STRING',
  DONR_POL_CONS: 'STRING',
  DONR_POL_LIB: 'STRING',
  DONR_RELIG: 'STRING',
  DONR_VETS: 'STRING',
  DONR_WILDLIFE: 'STRING',
  DRIVERSLICENSEEVERREVOKED: 'STRING',
  DRIVERSLICENSESTATE: 'STRING',
  DWELL_TYPE: 'STRING',
  EDUCATION_LEVEL: 'STRING',
  EMAIL_ADDRESS_1: 'STRING',
  EMAIL_ADDRESS_1_PRESENT_FLAG: 'STRING',
  ETHNIC_ASSIM: 'STRING',
  ETHNIC_CONF: 'STRING',
  ETHNIC_GRP: 'STRING',
  ETHNIC_HISP_CNTRY: 'STRING',
  ETHNIC_LANG: 'STRING',
  ETHNIC_RELIG: 'STRING',
  EXACT_AGE: 'INT',
  FIPS_STATE_CODE: 'INT',
  FIRST_NAME: 'STRING',
  GENDER: 'STRING',
  GENERATIONS: 'INT',
  GENL_PP_HOME_VALUE: 'INT',
  INCOME_RANGE: 'STRING',
  HH_MARITAL_STATUS: 'STRING',
  HOME_OWNER: 'STRING',
  HOUSEHOLD_ID: 'INT',
  ID: 'BIGINT',
  INVEST_ACT: 'STRING',
  INVEST_FOREIGN: 'STRING',
  INVEST_MONEY_SEEKR: 'STRING',
  INVEST_PERS: 'STRING',
  INVEST_READ_FIN_NEWS: 'STRING',
  INVEST_RL_EST: 'STRING',
  INVEST_STOCKS: 'STRING',
  IP: 'STRING',
  KIDS_F_0_2: 'STRING',
  KIDS_F_11_15: 'STRING',
  KIDS_F_16_17: 'STRING',
  KIDS_F_3_5: 'STRING',
  KIDS_F_6_10: 'STRING',
  KIDS_M_0_2: 'STRING',
  KIDS_M_11_15: 'STRING',
  KIDS_M_16_17: 'STRING',
  KIDS_M_3_5: 'STRING',
  KIDS_M_6_10: 'STRING',
  KIDS_U_0_2: 'STRING',
  KIDS_U_11_15: 'STRING',
  KIDS_U_16_17: 'STRING',
  KIDS_U_3_5: 'STRING',
  KIDS_U_6_10: 'STRING',
  LAST_NAME: 'STRING',
  LATITUDE: 'DOUBLE',
  LENGTH_OF_RESIDENCE: 'INT',
  LONGITUDE: 'DOUBLE',
  MAGS: 'STRING',
  MOTORCYCLE_DATE: 'DATE',
  MOTORCYCLE_MODEL: 'STRING',
  MOTORCYCLE_VIN: 'STRING',
  MR_LENDR_CD: 'INT',
  MR_LOAN_TYP: 'STRING',
  MR2_AMT: 'INT',
  MR2_LENDR: 'STRING',
  MR2_LENDR_CD: 'INT',
  MR2_LOAN_TYP: 'STRING',
  MR2_RATE_TYP: 'STRING',
  NAME_SUFFIX: 'STRING',
  NET_WORTH: 'STRING',
  NUM_ADULTS: 'INT',
  NUM_KIDS: 'INT',
  OCC_AFRIC_AMER_PROF: 'STRING',
  OCC_BUSN_OWNR: 'STRING',
  OCC_HOME_OFF: 'STRING',
  OCC_SOHO_IND: 'STRING',
  OCC_WORKING_WMN: 'STRING',
  OPTIN_DATE: 'DATE',
  P2_AMT: 'INT',
  P2_LOAN_TYP: 'STRING',
  P2_RATE_TYP: 'STRING',
  PARTY_AFFILIATION: 'STRING',
  PAYDAY_EMPLOYER_NAME: 'STRING',
  PAYDAY_EMPLOYMENT_STATUS: 'STRING',
  PAYDAY_FLAG: 'STRING',
  PAYDAY_LOAN_REQUESTED_AMT: 'STRING',
  PAYDAY_MILITARY_FLAG: 'STRING',
  PAYDAY_OCCUPATION: 'STRING',
  PRES_KIDS: 'STRING',
  PROP_AC: 'STRING',
  PROP_FUEL: 'STRING',
  PROP_POOL: 'STRING',
  PROP_SEWER: 'STRING',
  PROP_TYPE: 'BIGINT',
  PROP_WATER: 'STRING',
  SINGLE_PARENT: 'STRING',
  SR_ADULT_IN_HH: 'STRING',
  STATE: 'STRING',
  TELEPHONE_LANDLINE_2: 'STRING',
  TELEPHONE_WORK_1: 'STRING',
  URL: 'STRING',
  VET_IN_HH: 'STRING',
  WALK_SEQUENCE_CODE: 'STRING',
  YOUNG_ADULT_IN_HH: 'STRING',
  ZIP: 'INT',
  ZIP4: 'INT',
};

const checkType = (field, value) => {
  if (COLUMN_DATATYPE[field]) {
    switch (COLUMN_DATATYPE[field]) {
      case 'STRING':
        return `${value}`;
      case 'INT':
        return parseInt(value);
      case 'DOUBLE':
        return parseFloat(value);
      case 'DATE':
        return new Date(value);
      default:
        return value;
    }
  }
  return value;
};

export {checkType};
