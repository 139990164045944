import {Button} from 'components/lib';
import Style from './audienceItem.module.scss';

export function AudienceItem(props) {
  if (!props.data) return false;
  return (
    <tr className={ Style.audienceItem } >
      <td>{props.data.name}</td>
      <td>{props.data.created_on}</td>
      <td>{props.data.status}</td>
      <td className='flex-row space-x-1'>
        <Button icon='download' action={ props.downloadCSV } />
        <Button icon='copy' size={20} action={ props.copyLink } />
        <Button icon='trash' size={20} action={ props.deleteAudience } />
      </td>
    </tr>
  );
}
