import {createSlice} from '@reduxjs/toolkit';
import uuid4 from 'uuid4';

export const enrichmentSlice = createSlice({
  name: 'enrichment',
  initialState: {
    name: '',
    enrichmentsTable: [],
    searchResults: [],
    loading: true,
    currentPage: 1,
    pagValues: {},
    showButtons: false,
  },
  reducers: {
    updateEnrichment: (state, action) => {
      /**
       * @param {object} action.payload = {
       *  type: string,
       *  id: string,
       * fileName: string,
       *  progress: {
       *   status: string,
       *   currentStep: number,
       *   totalSteps: number,
       *   stepName: string,
       *  stepDescription: string,
       *   stepProgress: number,
       *   totalProgress: number,
       * }
       * }
       */
      const data = action.payload;
      const enrichmentTable = JSON.parse(JSON.stringify(state.enrichmentsTable));
      const enrichmentProgress = enrichmentTable?.find((enrichment) => enrichment.id === data.id);
      if (enrichmentProgress) {
        enrichmentProgress.progress = data.progress;
        if (data.progress?.totalProgress && data.progress?.totalProgress === 100) {
          enrichmentProgress.fileDetailsList[0].status = 'COMPLETE';
          if (data.fileName || data.url) {
            enrichmentProgress.fileDetailsList.push({
              name: data.fileName,
              url: data.url,
              _id: uuid4(),
              date: new Date(Date.now()).toISOString(),
            });
          }
        } else if (data.progress?.status === 'PROCESSING') {
          enrichmentProgress.fileDetailsList[0].status = `PROCESSING ${data.progress.totalProgress}%`;
        } else {
          enrichmentProgress.fileDetailsList[0].status = data.progress?.status;
        }
      }
      state.enrichmentsTable = enrichmentTable.map((enrichment) => {
        if (enrichment._id === data.id) {
          return enrichmentProgress;
        } else {
          return enrichment;
        }
      });
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setEnrichmentsTable: (state, action) => {
      state.enrichmentsTable = action.payload;
    },
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPagValues: (state, action) => {
      state.pagValues = action.payload;
    },
    deleteEnrichment: (state, action) => {
      state.enrichmentsTable = state.enrichmentsTable.filter((item) => item.id !== action.payload);
    },
  },
});

export const {setEnrichmentsTable, setSearchResults, deleteEnrichment, setName, setLoading, setCurrentPage, setPagValues, updateEnrichment} = enrichmentSlice.actions;

export default enrichmentSlice.reducer;
