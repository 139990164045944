import { ClassHelper, Error, Label } from "components/lib.js";
import React, { useState } from "react";
import Style from "./../input.tailwind.js";
import WindowedSelect from "react-windowed-select";
import Tippy from '@tippyjs/react';
import './tippy.css';
import 'tippy.js/animations/scale.css';
import b2c from '../../../../views/audience/premade_inputs/b2cList';
import b2b from '../../../../views/audience/premade_inputs/b2bList';
import { useSelector } from "react-redux";
import { useMemo } from "react";

export const SearchSelect = (props) => {
  const list = useSelector((store) => store.audienceBuilder.list);
  const [description, setDescription] = useState([]);
  const error = props.errorMessage || "Please enter a value";
  const minChars = props.minChars || 3;
  const messages = {
    typingRequired: `Type at least ${minChars} characters`,
    noDataFound: `No data found`,
  };

  const [options, setOptions] = useState([]);
  const [emptyMessage, setEmptyMessage] = useState(messages.noDataFound);

  const onInputChange = (inputText) => {
    if (inputText.length >= minChars) {
      const optionsFiltered = props.options.filter((option) =>
        option.label.toLowerCase().includes(inputText.toLowerCase())
      );
      setOptions(optionsFiltered);
      if (optionsFiltered.length === 0);
      setEmptyMessage(messages.noDataFound);
    } else {
      setEmptyMessage(messages.noDataFound);
    }
  };

  function change(e) {
    let value = e ? e.value : "unselected";
    let valid = undefined;

    // validate
    valid = props.required && value === "unselected" ? false : true;
    props.onChange(props.name, e, valid);
    props.callback && props.callback(value);
  }
  // style
  const textStyle = ClassHelper(Style, {
    textbox: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
  });

  // tooltip
  const toolTipContent = (innerProps, label) => {
    const selectedOne = props.taxonomy === 'B2C' ? b2c.filter((option) => option['field'].includes(label)) : b2b.filter((option) => option['field'].includes(label));
    if (selectedOne.length > 0 && selectedOne[0].des !== '') {
      return (
        <Tippy content={selectedOne[0].des} animation='scale'>
          <div {...innerProps} className="cursor-pointer my-2 px-2 hover:bg-blue-200">
            {label}
          </div>
        </Tippy>
      );
    }

    return (
      <div {...innerProps} className="cursor-pointer my-1 px-2 hover:bg-blue-200">
        {label}
      </div>
    );
  };

  useMemo(() => {
    if (props.defaultValue) {
      setDescription(list.filter((option) => option.label === props.defaultValue?.label));
    }
  }, [props.defaultValue]);

  return (
    <div className={Style.input + " " + props.containerClass}>
      {props.label && (
        <Label text={props.label} required={props.required} for={props.name} />
      )}
      <WindowedSelect
        className="mb-4"
        options={props.lazyLoad === true ? options : props.options}
        onChange={(e) => change(e)}
        onInputChange={onInputChange}
        isMulti={props.isMulti}
        isSearchable={props.isSearchable}
        defaultValue={props.defaultValue}
        theme={props.theme}
        styles={props.styles}
        closeMenuOnSelect={props.closeMenuOnSelect}
        noOptionsMessage={() => emptyMessage}
        isOptionDisabled={(option) => option.value === undefined}
        id={props.name}
        maxMenuHeight={props.maxMenuHeight || 200}
        components={{
          Option: ({innerProps, label, data}) => toolTipContent(innerProps, label, data)}}
      />
      {props.valid === false && <Error message={error} />}
      <div className="flex flex-col">
        {description[0]?.desc && (
          <div className="bg-gray-200 p-4 rounded-lg w-full min-h-[30vh]">
            Description: <p>{description[0]?.desc}</p>
          </div>
        )}
      </div>

    </div>
  );
};
