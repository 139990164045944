import {Signup} from 'views/auth/signup/account';
import {SignupPlan} from 'views/auth/signup/plan';
import {SignupPassword} from 'views/auth/signup/password';
import {SignupUser} from 'views/auth/signup/user';
import {Signin} from 'views/auth/signin';
import {SigninOTP} from 'views/auth/signin/otp';
import {SocialSignin} from 'views/auth/signin/social';
import {ForgotPassword} from 'views/auth/signin/forgotpassword';
import {ResetPassword} from 'views/auth/signin/resetpassword';
import {MagicSignin} from 'views/auth/signin/magic';
import {MagicSignup} from 'views/auth/signup/magic';
import {ImpersonateSignin} from 'views/auth/signin/impersonate';

const Routes = [
  {
    path: '/signup',
    view: Signup,
    layout: 'auth',
    permission: 'admin',
    title: 'Sign up to AudienceLab',
  },
  {
    path: '/signup/plan',
    view: SignupPlan,
    layout: 'auth',
    title: 'Sign up to AudienceLab',
  },
  {
    path: '/signup/user',
    view: SignupUser,
    layout: 'auth',
    title: 'Sign up to AudienceLab',
  },
  {
    path: '/signup/magic',
    view: MagicSignup,
    layout: 'auth',
    title: 'Sign Up',
  },
  {
    path: '/signup/password',
    view: SignupPassword,
    layout: 'auth',
    permission: 'admin',
    title: 'Change Password',
  },
  {
    path: '/signin',
    view: Signin,
    layout: 'auth',
    title: 'Welcome to The AudienceLab',
  },
  {
    path: '/signin/otp',
    view: SigninOTP,
    layout: 'auth',
    title: 'Enter verification code',
  },
  {
    path: '/signin/social',
    view: SocialSignin,
    layout: 'auth',
    title: 'Completing Sign In',
  },
  {
    path: '/magic',
    view: MagicSignin,
    layout: 'auth',
    title: 'Sign In',
  },
  {
    path: '/forgotpassword',
    view: ForgotPassword,
    layout: 'auth',
    title: 'Forgot Your Password?',
  },
  {
    path: '/resetpassword',
    view: ResetPassword,
    layout: 'auth',
    title: 'Reset Your Password',
  },
  {
    path: '/signin/impersonate',
    view: ImpersonateSignin,
    layout: 'auth',
    title: 'Sign in via Mission Control',
  },
];

export default Routes;
