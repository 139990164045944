import React, { Fragment, useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  Stat,
  Animate,
  TitleRow,
  Message,
  AuthContext
} from "components/lib";
import { checkLocation } from "helpers/checklocation";

const Usage = () => {
  const authContext = useContext(AuthContext);

  const baseUrl = checkLocation();
  const [access, setAccess] = useState(null);

  const getUsage = async () => {
    const accountId = authContext.user.account_id;

    const accessResponse = await axios.get(
      `${baseUrl}/api/access`
    );
    setAccess(accessResponse.data.data);
  };

  useEffect(() => {
    getUsage();
  }, []);

  if (!access) {
    return null;
  }

  console.log(access);

  const getQuota = (limit, usage) => {
    const quota = Math.max(0, limit - usage);
    return quota;
  };

  const hasMultipleStats = (feature, mode) => {
    const permit = access[feature].permit;
    const limit = access[feature][mode].limit;

    const condition = !(permit === false || limit === 0);
    return condition
  };


  const hasMaxQuery = (feature) => {
    const condition = ["b2b", "enrichments"].includes(feature);
    return condition
  };


  const renderAccessCard = (feature, mode) => {
    const multipleStats = hasMultipleStats(feature, mode);
    const maxQueryFeature = hasMaxQuery(feature)

    const gridClass = `grid ${maxQueryFeature ? 'grid-cols-4 w-full' : 'grid-cols-3 w-full'
      } border rounded-2xl p-1 items-baseline justify-center pt-5 max-h-[150px] overflow-hidden`;

    if (multipleStats) {
      return (
        <div className={gridClass}>
          <Stat
            value={access[feature][mode].limit}
            label="Limit"
          />
          <Stat
            value={access[feature][mode].usage}
            label="Posted"
          />
          <Stat
            value={getQuota(access[feature][mode].limit, access[feature][mode].usage)}
            label="Left"
          />
          {maxQueryFeature && (
            <Stat
              value={access[feature].result.limit}
              label="Query Limit"
            />
          )}
        </div>
      );
    } else {
      // When there's a single stat, we'll display 'Unlimited access' or 'No access' depending on the permit.
      const singleStatLabel = access[feature].permit ? 'Unlimited access' : 'No access';
      const singleStatValue = access[feature].permit ? '∞' : '';

      return (
        <div className={gridClass}>
          <div></div>
          <Stat
            value={singleStatValue}
            label={singleStatLabel}
          />
          
          {maxQueryFeature && (
            <Stat
              value={access[feature].result.limit}
              label="Query Limit"
            />
          )}

          <div></div>
        </div>
      );
    }
  };

  return (
    <Fragment>
      <h2 className="m-6 text-3xl font-semibold">Usage</h2>
      <div className="hidden md:block">
        <Message
          title="Limits are renewed on the first day of every month"
          type="info"
        />
      </div>
      <Animate>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 justify-center mb-20 m-6">
          <div className="flex flex-col items-center">
            <TitleRow title="Audiences" />
            {renderAccessCard("audiences", "create")}
          </div>
          <div className="flex flex-col items-center">
            <TitleRow title="Pixel Units" />
            {renderAccessCard("pixels", "create")}
          </div>
          <div className="flex flex-col items-center">
            <TitleRow title="Pixel Resolutions" />
            {renderAccessCard("pixels", "result")}
          </div>
          <div className="flex flex-col items-center">
            <TitleRow title="Enrichments" />
            {renderAccessCard("enrichments", "create")}
          </div>
          <div className="flex flex-col items-center">
            <TitleRow title="B2B" />
            {renderAccessCard("b2b", "create")}
          </div>

          <div className="flex flex-col items-center">
            <TitleRow title="Facebook sync" />
            {renderAccessCard("integrations_facebook", "create")}
          </div>

        </div>
      </Animate>
    </Fragment>
  );
}

export default Usage;
