// views.js
export const defaultViews = (buildType) => [
  {
    name: `${buildType?.charAt(0).toUpperCase() + buildType?.slice(1)}`,
    description: 'What interests your audience?',
    status: 'current',
    component: `audience_${buildType}`,
  },
  {
    name: 'Business',
    description: 'What is their business like?',
    status: 'upcoming',
    component: 'audience_business',
  },
  {
    name: 'Personal',
    description: 'What defines them personally?',
    status: 'upcoming',
    component: 'audience_personal',
  },
  {
    name: 'Family',
    description: 'What characterizes their family life?',
    status: 'upcoming',
    component: 'audience_family',
  },
  {
    name: 'Financial',
    description: 'What is their financial situation like?',
    status: 'upcoming',
    component: 'audience_financial',
  },
  {
    name: 'House',
    description: 'What is their housing situation?',
    status: 'upcoming',
    component: 'audience_house',
  },
  {
    name: 'Auto',
    description: 'What type of vehicle do they drive?',
    status: 'upcoming',
    component: 'audience_auto',
  },
  {
    name: 'Location',
    description: 'Where does your audience reside?',
    status: 'upcoming',
    component: 'audience_location',
  },
  {
    name: 'Age and Gender',
    description: 'What are their age and gender?',
    status: 'upcoming',
    component: 'audience_age_gender',
  },
  {
    name: 'Audience Name',
    description: 'Do you want to rename your audience?',
    status: 'upcoming',
    component: 'audience_name',
  },
  {
    name: 'Audience Date',
    description: 'What is the date range for your audience?',
    status: 'upcoming',
    component: 'audience_date',
  },
  {
    name: 'Audience Filter',
    description: 'What kind of filter do you need?',
    status: 'upcoming',
    component: 'audience_filter',
  }
];

export const newViews = (buildType) => [
  {
    name: `${buildType?.charAt(0).toUpperCase() + buildType?.slice(1)}`,
    description: 'What interests your audience?',
    status: 'current',
    component: `audience_${buildType}`,
  },
  {
    name: 'Business',
    description: 'What is their business like?',
    status: 'upcoming',
    component: 'audience_business',
  },
  {
    name: 'Personal',
    description: 'What defines them personally?',
    status: 'upcoming',
    component: 'new_audience_personal',
  },
  {
    name: 'Financial',
    description: 'What is their financial situation like?',
    status: 'upcoming',
    component: 'new_audience_financial',
  },
  {
    name: 'House',
    description: 'What is their housing situation?',
    status: 'upcoming',
    component: 'new_audience_house',
  },
  {
    name: 'Location',
    description: 'Where does your audience reside?',
    status: 'upcoming',
    component: 'audience_location',
  },
  {
    name: 'Age and Gender',
    description: 'What are their age and gender?',
    status: 'upcoming',
    component: 'audience_age_gender',
  },
  {
    name: 'Audience Name',
    description: 'Do you want to rename your audience?',
    status: 'upcoming',
    component: 'audience_name',
  },
  {
    name: 'Audience Date',
    description: 'What is the date range for your audience?',
    status: 'upcoming',
    component: 'audience_date',
  },
  {
    name: 'Audience Filter',
    description: 'What kind of filters do you need?',
    status: 'upcoming',
    component: 'audience_filter',
  }
];
