import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ZendeskHelper = () => {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(true);

  const zendeskScript = (shouldLoad) => {
    if (shouldLoad) {
      // create the script element here
      const script = document.createElement('script');
      script.src = 'https://static.zdassets.com/ekr/snippet.js?key=7c76ef93-0dd0-4e89-9918-62c5ae01f309';
      script.id = 'ze-snippet';
      document.body.appendChild(script);

      script.onload = () => {
        const style = document.createElement('style');
        style.innerHTML = `
          #launcher {
            bottom: 145px !important; /* Adjust the value as needed */
          }
        `;
        document.head.appendChild(style);

        // create the X button here
        const closeButton = document.createElement('div');
        closeButton.innerText = 'x';
        closeButton.id = 'closeButton';
        Object.assign(closeButton.style, closeButtonStyle);
        closeButton.onclick = () => setIsVisible(false);
        document.body.appendChild(closeButton);
      };
    } else {
      const existingScript = document.getElementById('ze-snippet');
      if (existingScript) {
        existingScript.remove();
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    zendeskScript(!location.pathname.endsWith('/help'));
  }, [location.pathname]);

  useEffect(() => {
    const launcher = document.getElementById('launcher');
    const closeButton = document.getElementById('closeButton');
    if (launcher) {
      launcher.style.display = isVisible ? 'block' : 'none';
    }
    if (closeButton) {
      closeButton.style.display = isVisible ? 'block' : 'none';
    }
  }, [isVisible]);

  return null; // No need to render anything in the component itself
};

const closeButtonStyle = {
  display: 'block',
  position: 'fixed',
  bottom: '200px', // Adjust the position as needed
  right: '10px',
  color: 'black',
  backgroundColor: 'white',
  cursor: 'pointer',
  zIndex: 1000,
  padding: '5px',
};

export default ZendeskHelper;
