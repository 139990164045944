import React, { Fragment } from 'react';
import { Grid, Stat, Animate } from 'components/lib';

const AudienceQuota = ({ quotaResponse }) => {
  const quotaAudience = quotaResponse?.data?.audiences;
  const quotaCustomAudience = quotaResponse?.data?.custom_audiences;

  const conditions = [
    quotaAudience?.create?.limit > 0,
    quotaAudience?.refresh?.limit > 0,
    quotaCustomAudience?.permit,
    quotaAudience?.create?.options && quotaAudience.create.options.length > 0, 
  ].filter(Boolean); 

  const trueCount = conditions.reduce((count, current) => count + (current === true ? 1 : 0), 0);

  const gridCount = trueCount + 1;

  return (
    <Fragment>
      <Animate>
        <div className='m-6'>
          <Grid cols={gridCount.toString()}>
            {quotaAudience?.create?.limit > 0 && (
              <div className='border rounded-xl p-1 shadow-md'>
                <Stat
                  value={`${quotaAudience.create.usage} / ${quotaAudience.create.limit}`}
                  label={"Audience units"}
                  tooltip={quotaAudience.create.renew ? "refresh-cw" : null}
                />
              </div>
            )}
            {quotaAudience?.refresh?.limit > 0 && (
              <div className='border rounded-xl p-1 shadow-md'>
                <Stat
                  value={`${quotaAudience.refresh.usage} / ${quotaAudience.refresh.limit}`}
                  label={"Audience Refreshes"}
                  tooltip={quotaAudience.refresh.renew ? "refresh-cw" : null}
                />
              </div>
            )}
            {quotaAudience?.create?.options?.length > 0 && ( 
              <div className='border rounded-xl p-1 shadow-md'>
                <Stat
                  value={
                    quotaAudience.create.options.length > 1
                      ? quotaAudience.create.options.join(" and ")
                      : quotaAudience.create.options[0]
                  }
                  label={"Audience options"}
                />
              </div>
            )}
            {quotaCustomAudience?.permit && (
              <div className='border rounded-xl p-1 shadow-md'>
                <Stat
                  value={
                    quotaCustomAudience.create.limit === 0
                      ? "Unlimited"
                      : `${quotaCustomAudience.create.usage} / ${quotaCustomAudience.create.limit}`
                  }
                  label={"Custom Audiences"}
                  tooltip={quotaCustomAudience.create.renew ? "refresh-cw" : null}
                />
              </div>
            )}
          </Grid>
        </div>
      </Animate>
    </Fragment>
  );
};

export default AudienceQuota;
