import {createSlice} from '@reduxjs/toolkit';

export const PixelProcessorSlice = createSlice({
  name: 'pixelProcessor',
  initialState: {
    pixelProcessorList: [],
    pixel2ProcessorList: [],
    pixelProcessorForm: {},
    term: '',
    loading: true,
    currentPage: 1,
    pagValues: {},
    table: [],
    initialData: [],
    visitors: [],
    uniqueVisitors: [],
    identifiedVisitors: [],
    active: true,
    results: [],
  },
  reducers: {
    addPixel: (state, action) => {
      // if action.payload.id in state.pixelProcessorList exists, update it
      // else, add it
      const index = state.pixelProcessorList.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        state.pixelProcessorList[index] = action.payload;
      } else {
        state.pixelProcessorList.push(action.payload);
        // state.pixelProcessorForm = {};
      }
    },
    deletePixel: (state, action) => {
      state.pixelProcessorList = [...state.pixelProcessorList.filter((item) => item.id !== action.payload)];
    },
    setPixel: (state, action) => {
      state.pixelProcessorList = [...action.payload];
    },
    setPixel2: (state, action) => {
      state.pixel2ProcessorList = [...action.payload];
    },
    updatePixel: (state, action) => {
      const index = state.pixelProcessorList.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        const pixelToUpdate = state.pixelProcessorList[index];
        Object.entries(action.payload.updates).forEach(([path, value]) => {
          const keys = path.split('.');
          let current = pixelToUpdate;
          keys.slice(0, -1).forEach(key => {
            if (!current[key]) current[key] = {};
            current = current[key];
          });
          current[keys[keys.length - 1]] = value;
        });
      }
    },
    updatePixelFileProgress: (state, action) => {
      // const currentState = state;
      // const index = currentState.pixelProcessorList.findIndex((item) => item.id === action.payload.id);
      // const fileList = currentState.pixelProcessorList[index].file_list;
      // const fileIndex = fileList.findIndex((item) => item.name === action.payload.fileName);
      // fileList[fileIndex].status = action.payload.progress.status;
      // fileList[fileIndex].progress = action.payload.progress;
      const {id, fileName, progress} = action.payload;
      const index = state.pixelProcessorList.findIndex((item) => item.id === id);
      const fileList = state.pixelProcessorList[index].file_list;
      const fileIndex = fileList.findIndex((item) => {
        return item.name === fileName;
      });
      if (fileIndex === -1) {
        fileList.push({
          name: fileName,
          createdAt: Date.now(),
          status: progress.status,
        });
      } else {
        if (progress.status === 'COPLETE') {
          fileList[fileIndex].status = progress.status;
        } else {
          fileList[fileIndex].status = `${progress.status} ${progress.totalProgress}%`;
        }
        fileList[fileIndex].progress = progress;
      }
    },
    updateForm: (state, action) => {
      state.pixelProcessorForm[`${action.payload.fieldName}`] = action.payload.value;
    },
    setTerm: (state, action) => {
      state.term = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPagValues: (state, action) => {
      state.pagValues = action.payload;
    },
    setTable: (state, action) => {
      state.table = action.payload;
    },
    setInitialData: (state, action) => {
      state.initialData = action.payload;
    },
    setVisitors: (state, action) => {
      state.visitors = action.payload;
    },
    setUnique: (state, action) => {
      state.uniqueVisitors = action.payload;
    },
    setIdentified: (state, action) => {
      state.identifiedVisitors = action.payload;
    },
    setActive: (state, action) => {
      state.active = action.payload;
    },
    setResults: (state, action) => {
      state.results = action.payload;
    }
  },
});

export const {addPixel, deletePixel, setPixel, setPixel2, updatePixel, updateForm, setTerm, setLoading, setCurrentPage, setPagValues, setTable, setInitialData, setActive, setVisitors, setUnique, setIdentified, updatePixelFileProgress, setResults} = PixelProcessorSlice.actions;
export default PixelProcessorSlice.reducer;
