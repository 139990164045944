/* eslint-disable no-constant-condition */
import {createSlice} from '@reduxjs/toolkit';
import uuid4 from 'uuid4';
import {checkType} from 'helpers/checkDataType';

export const audienceBuilderSlice = createSlice({
  name: 'audienceBuilder',
  initialState: {
    audienceList: [],
    audienceBuilderForm: {},
    list: [],
    targets: '',
  },
  reducers: {
    addAudience: (state, action) => {
      state.audienceList.push(action.payload);
      state.audienceBuilderForm = {};
    },
    deleteAudience: (state, action) => {
      state.audienceList = [...state.audienceList.filter((item) => item.id !== action.payload)];
    },
    updateProgress: (state, action) => {
      const data = action.payload;
      const audienceProgress = state.audienceList.find((audience) => audience.id === data.id);
      if (audienceProgress) {
        audienceProgress.progress = data.progress;
        if (data.progress?.totalProgress && data.progress?.totalProgress === 100) {
          audienceProgress.status = 'COMPLETE';
          if (data.url) {
            if (!audienceProgress.fileDetailsList) {
              audienceProgress.fileDetailsList = [];
            }
            audienceProgress.fileDetailsList.push({
              name: data.url.split('/').pop(),
              url: data.url,
              _id: uuid4(),
              date: new Date(Date.now()).toISOString(),
            });
          }
        } else if (data.progress?.status === 'PROCESSING') {
          audienceProgress.status = `PROCESSING ${data.progress.totalProgress}%`;
        } else {
          audienceProgress.status = data.progress?.status;
        }
      }
    },
    setAudience: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        state.audienceList = [...action.payload];
      }
    },
    deleteInput: (state, action) => {
      state.audienceBuilderForm.keywordList = [...state.audienceBuilderForm.keywordList.filter((item) => item.keyword !== action.payload.value)];
    },
    updateForm: (state, action) => {
      if (action.payload.fieldName && action.payload.value) {
        if (action.payload.fieldName === 'keywordList') {
          state.audienceBuilderForm.iabList = [];
          state.audienceBuilderForm[`${action.payload.fieldName}`] = action.payload.value;
        } else if (action.payload.fieldName === 'iabList') {
          state.audienceBuilderForm.keywordList = [];
          state.audienceBuilderForm[`${action.payload.fieldName}`] = action.payload.value;
        } else if ((action.payload.fieldName === '') && action.payload.value) {
          state.audienceBuilderForm = action.payload.value;
        }
        state.audienceBuilderForm[`${action.payload.fieldName}`] = action.payload.value;
      }
    },
    removeForm: (state, action) => {
      state.audienceBuilderForm = {};
    },
    setTargets: (state, action) => {
      state.targets = action.payload;
    },
    setForm: (state, action) => {
      const reduxData = action.payload;
      const data = {
        zipcodeList: reduxData?.zipcodeList?.map((item) => ({value: item.value || item, label: item.label || item})) || [],
        zipcodeListText: reduxData?.zipcodeListText?.map((item) => ({value: item.value || item, label: item.label || item})) || [],
        cityList: reduxData?.cityList?.map((item) => ({value: item.value || item, label: item.label || item})) || [],
        notNull: reduxData?.notNull || [],
        nullOnly: reduxData?.nullOnly || [],
        stateList: reduxData?.stateList?.map((item) => ({value: item.value || item, label: item.label || item})) || [],
        businessList: reduxData?.businessList || [],
        job_title: reduxData?.businessList?.job_title || reduxData.job_title || [],
        seniority: reduxData?.businessList?.seniority || reduxData.seniority || [],
        department: reduxData?.businessList?.department || reduxData.department || [],
        company_name: reduxData?.businessList?.company_name || reduxData.company_name || [],
        company_domain: reduxData?.businessList?.company_domain || reduxData.company_domain || [],
        industry: reduxData?.businessList?.industry || reduxData.industry || [],
        sic: reduxData?.businessList?.sic || reduxData.sic || [],
        naics: reduxData?.businessList?.naics || reduxData.naics || [],
        employee_count: reduxData?.businessList?.employee_count || reduxData.employee_count || [],
        est_company_revenue: reduxData?.businessList?.est_company_revenue || reduxData.est_company_revenue || [],
        ageList: reduxData?.ageList || [],
        genderList: reduxData?.genderList || [],
        iabList: reduxData?.iabList || [],
        optionType: reduxData?.optionType || 'B2C',
        keywordList: reduxData?.keywordList || [],
        personal: reduxData?.profile?.personal?.map((item) => ({id: uuid4(), name: item[0], field: item[0], operator: item[1], value_back: checkType(item[0], item[2]), value: item[2]})) || [],
        family: reduxData?.profile?.family?.map((item) => ({id: uuid4(), name: item[0], field: item[0], operator: item[1], value_back: checkType(item[0], item[2]), value: item[2]})) || [],
        financial: reduxData?.profile?.financial?.map((item) => ({id: uuid4(), name: item[0], field: item[0], operator: item[1], value_back: checkType(item[0], item[2]), value: item[2]})) || [],
        house: reduxData?.profile?.house?.map((item) => ({id: uuid4(), name: item[0], field: item[0], operator: item[1], value_back: checkType(item[0], item[2]), value: item[2]})) || [],
        auto: reduxData?.profile?.auto?.map((item) => ({id: uuid4(), name: item[0], field: item[0], operator: item[1], value_back: checkType(item[0], item[2]), value: item[2]})) || [],
        dateRange: reduxData?.dateRange || {},
        dateRangeLimit: state?.audienceBuilderForm?.dateRangeLimit || {},
      };
      state.audienceBuilderForm = data;
    },
    setZipcodes: (state, action) => {
      if (state.audienceBuilderForm.zipcodeList) {
        const existingBackValues = new Set(state.audienceBuilderForm.zipcodeList.map(item => item.back));
    
        const uniqueZipcodesToAdd = action.payload.filter(item => !existingBackValues.has(item.back));
    
        state.audienceBuilderForm.zipcodeList = [...state.audienceBuilderForm.zipcodeList, ...uniqueZipcodesToAdd];
      }
    },
    updateList: (state, action) => {
      state.list = action.payload;
    }
  },
});

export const { addAudience, deleteAudience, setAudience, deleteInput, updateForm, updateList, removeForm, setForm, setCity, updateProgress, setZipcodes, setTargets } = audienceBuilderSlice.actions;
export default audienceBuilderSlice.reducer;
