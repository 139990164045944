import React, { useState } from "react";
import WindowedSelect from "react-windowed-select";
import { ClassHelper, Error, Label } from "components/lib.js";
import Style from "components/form/input/input.tailwind.js";

export const SearchSelectFacebook = ({ options, value, onChange, ...props }) => {
  const error = props.errorMessage || "Please enter a value";
  const minChars = props.minChars || 3;
  const messages = {
    typingRequired: `Type at least ${minChars} characters`,
    noDataFound: `No data found`,
  };

  const [filteredOptions, setFilteredOptions] = useState([]);
  const [emptyMessage, setEmptyMessage] = useState(messages.noDataFound);

  const onInputChange = (inputText) => {
    if (inputText.length >= minChars) {
      const optionsFiltered = options.filter((option) =>
        option.label.toLowerCase().includes(inputText.toLowerCase())
      );
      setFilteredOptions(optionsFiltered);
      setEmptyMessage(optionsFiltered.length === 0 ? messages.noDataFound : '');
    } else {
      setEmptyMessage(messages.typingRequired);
    }
  };

  return (
    <div className={Style.input + " " + props.containerClass}>
      {props.label && (
        <Label text={props.label} required={props.required} for={props.name} />
      )}
      <WindowedSelect
        className="mb-4"
        options={props.lazyLoad ? filteredOptions : options}
        value={value} // Use the value prop to control the component
        onChange={onChange} // Use the onChange prop directly
        onInputChange={onInputChange}
        isMulti={props.isMulti}
        isSearchable={props.isSearchable}
        defaultValue={props.defaultValue}
        theme={props.theme}
        styles={props.styles}
        closeMenuOnSelect={props.closeMenuOnSelect}
        noOptionsMessage={() => emptyMessage}
        isOptionDisabled={(option) => option.value === undefined}
        id={props.name}
        maxMenuHeight={props.maxMenuHeight || 200}
      />

      {props.valid === false && <Error message={error} />}
    </div>
  );
};
