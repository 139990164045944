import Style from './badge.tailwind.js';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

export const Badge = (props) => {
  const badgeContent = (
    <span className={`${Style.span} bg-new${props.color}`}>
      {props.text}
    </span>
  );

  return (
    props.tooltip ? (
      <Tippy content={props.tooltip}>
        {badgeContent}
      </Tippy>
    ) : (
      badgeContent
    )
  );
};
