import {useState, useEffect} from 'react';
import Formatation from 'helpers/format';
import {Badge} from './badge/badge';
import {Checkbox} from './checkbox/checkbox';
import {Icon} from './icon/icon';
import Style from './table.tailwind.js';

export const Body = (props) => {
  // GET TIMEZONE
  const format = new Formatation();
  const [timezone, setTimezone] = useState('CST');
  useEffect(() => {
    format.timezones(setTimezone);
  }, []);
  // CHECKBOX
  const renderCheckboxCell = (item, column) => {
    return (
      <td key={Math.random()} className="relative px-8 sm:w-12 sm:px-6 float-left w-full p-0 mb-2 first:pl-0 last:pr-0 last:pb-5 last:border-b last:border-solid last:border-slate-100 md:float-none md:w-auto md:mb-0 md:p-5 md:border-b md:border-solid md:border-slate-100">
        {props.selectedItem?.includes(item) && <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-500" />}
        <Checkbox selectedItem={props.selectedItem} setSelectedItem={props.setSelectedItem} item={item} body />
      </td>
    );
  };
  // BADGE
  const renderBadgeCell = (value) => {
    const color = (value) => {
      const matchedCondition = props.badge.condition.find((condition) => condition.value === value);
      if (matchedCondition) {
        return matchedCondition.color ? matchedCondition.color : props.badge.color;
      } else {
        return props.badge.color;
      }
    };

    const rename = (value) => {
      const matchedCondition = props.badge.condition.find((cond) => cond.value === value);
      if (matchedCondition) {
        return matchedCondition.label ? matchedCondition.label : value;
      } else {
        return value;
      }
    };

    const tooltip = (value) => {
      const condition = props.badge.condition.find((condition) => condition.value === value)?.tooltip;
      return condition || null;
    };
    return (
      <td key={Math.random()} className={Style.td}>
        <Badge
          color={color(value)}
          text={rename(value)}
          tooltip={tooltip(value)}
        />
      </td>
    );
  };
  // ACTIONS
  const renderActionCell = (row) => {
    const isActionEnabledAndFirstConditionCheck = (action) => {
      if (!action.condition) return {enabled: true, firstConditionPassed: true}; 
  
      let firstConditionPassed = true; 
      const enabled = action.condition.every((cond, index) => {
        const cellValue = row[cond.col];
        const conditionMet = cond.values.includes(cellValue);
        
        if (index === 0 && !conditionMet) firstConditionPassed = false;
        return conditionMet;
      });
  
      return {enabled, firstConditionPassed};
    };
  
    const getActionColor = (action, enabled) => {
      return enabled ? action.color || 'dark' : 'grey';
    };
  
    const getTooltip = (action, enabled, firstConditionPassed) => {
      
      if (!enabled && !firstConditionPassed && action.condition[0].col === 'isNotOld') {
        return "Audience too old to be refreshed";
      } 
      else if (!enabled && !firstConditionPassed) {
        return "Premade type not available";
      } else if (enabled && action.tooltip && action.tooltip.length > 0) {
        return action.tooltip[0];
      } 
      else if (!enabled && action.tooltip && action.tooltip.length > 1) {
        return action.tooltip[1];
      } 
      
      return '';
    };
  
    return (
      <td key={Math.random()} className="sm:w-12 md:w-auto md:py-5 md:border-b md:border-solid md:border-slate-100">
        <div className="flex justify-end items-start space-x-3">
          {props.actions.map((action) => {
            const {enabled, firstConditionPassed} = isActionEnabledAndFirstConditionCheck(action);
            const actionColor = getActionColor(action, enabled);
            const tooltipText = getTooltip(action, enabled, firstConditionPassed);
  
            return (
              <div
                key={Math.random()}
                onClick={() => enabled ? action.onClick[0](row) : (action.onClick[1] ? action.onClick[1](row) : null)}
                className={`cursor-pointer font-sans flex whitespace-nowrap bg-transparent relative p-0 w-4 h-4 ${!enabled ? 'opacity-50' : ''}`}
                title={tooltipText}
              >
                <Icon
                  icon={action.icon}
                  size={18}
                  color={actionColor}
                />
              </div>
            );
          })}
        </div>
      </td>
    );
  };
  
  
  // CELLS
  const renderCells = (row) => {
    const cells = [];
    if (props.checkbox) {
      cells.push(renderCheckboxCell(row));
    }

    props.show.forEach((column) => {
      const value = row[column];
      if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value)) {
        // Handle case when cell value is a date
        cells.push(<td className={Style.td} key={Math.random()}>{`${format.timestampTable(new Date(value))} ${timezone.replace(/_/g, ' ')}`}</td>);
      } else if (column === props.badge?.col) {
        // Handle case when cell value is a badge
        cells.push(renderBadgeCell(value));
      } else if (column === 'actions') {
        // Handle case when cell value is an action
        cells.push(renderActionCell(row));
      } else {
        cells.push(<td className={Style.td} key={Math.random()}>{value}</td>);
      }
    });

    return cells;
  };
  // ROWS
  const renderRows = () => {
    const dataToRender = props.search ? props.search : props.data;
    return dataToRender.map((row, rowIndex) => (
      <tr key={rowIndex} className={props.selectedItem?.includes(row) ? 'bg-gray-50' : undefined}>
        {renderCells(row)}
      </tr>
    ));
  };

  return <tbody className="divide-y divide-gray-200 bg-white">{renderRows()}</tbody>;
};
