import React, { useContext, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearForm,
  setList,
  setForm,
  setCurrentIndexPage,
  setPagValues,
} from 'redux/features/businessBuilder/businessBuilderSlice';
import { Card, ViewContext, Button, useNavigate, TitleRow, NewPaginate, AuthContext, Icon } from 'components/lib';
import { Table } from 'gsd-components/table/table';
import { addTypeCheck } from 'helpers/checkPermissionType';
import { checkLocation } from 'helpers/checklocation';
import Quota from 'helpers/quota';
import uuid4 from 'uuid4';
import axios from 'axios';
import { useFacebook } from 'gsd-components/facebook/hook';


export const B2bIndex = () => {
  const [quota, setQuota] = useState(null);
  const b2bList = useSelector((state) => state.businessBuilder.businessList);
  const currentIndexPage = useSelector((state) => state.businessBuilder.currentIndexPage);
  const pagValues = useSelector((state) => state.businessBuilder.pagValues);
  const context = useContext(ViewContext);
  const baseUrl = checkLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const accountId = authContext.user.account_id;


  const {
    isSyncSuccess,
    FacebookSuccessModal,
    showSyncModal,
    setShowSyncModal,
    SyncAudienceModal,
    facebookIntegrationAccess,
    hasLoggedCondition,
    FacebookLoginModal,
    setFacebookAudienceData

  } = useFacebook();


  const createB2bSearch = async () => {
    try {
      const userString = localStorage.getItem('user');
      const user = userString ? JSON.parse(userString) : null;
      const quota = await axios.get(`${baseUrl}/api/access/quota/b2b`);
      const permit = quota.data.permit;

      if (permit) {
        context.modal.show({
          title: 'Create B2B List',
          form: {
            id: { label: 'id', type: 'hidden', value: uuid4() },
            name: { label: 'Name', type: 'text', value: '', required: true },
            description: { label: 'Description', type: 'text', value: '', required: true },
          },
          buttonText: 'Create',
          url: '/api/b2bSearch',
          method: 'POST',
        }, async (response) => {
          try {
            context.notification.show('created successfully', 'success', true);
            const copyData = [...b2bList];
            copyData.push({
              id: response.id.value,
              name: response.name.value,
              description: response.description.value,
              status: 'CREATED',
              formStateList: [],
              fileDetailsList: [],
              visualData: [],
            });
            dispatch(setList(copyData));
            dispatch(clearForm());
            dispatch(setPagValues({ total: 1, limit: 25 }));
            dispatch(setCurrentIndexPage(1));
            navigate(`${response.id.value}`);

            await axios.patch(`${baseUrl}/api/access/usage/b2b`);

          } catch (error) {
            console.error(error);
          }
        });

      } else {
        context.notification.show(`Please contact support for B2B credits`, 'error', true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const editB2bSearch = (row) => {
    try {
      const item = b2bList?.find((f) => f.id === row.id);

      if (!item) {
        console.error('Invalid item for editing');
        return;
      }

      const { status, formStateList, visualData } = item;
      const formState = formStateList?.[formStateList.length - 1]?.formState;

      if (formState && status !== 'NO_DATA') {
        dispatch(clearForm());
        dispatch(setForm(formState));
        navigate(`${row.id}`);
      } else if (status !== 'NO_DATA') {
        navigate(`${row.id}`);
      } else {
        context.notification.show('No data found', 'error', true);
      }

      dispatch(setPagValues({ total: visualData?.length || 1, limit: 25 }));
      dispatch(setCurrentIndexPage(1));
    } catch (error) {
      console.error(error);
      context.notification.show('An error occurred during editing', 'error', true);
    }
  };

  const downloadB2bSearch = (row) => {
    try {
      const item = b2bList?.find((f) => f.id === row.id);

      if (!item) {
        console.error('Invalid item for downloading');
        return;
      }

      const { status, fileDetailsList } = item;
      const file = fileDetailsList?.[fileDetailsList.length - 1]?.name;

      if (file && status !== 'NO_DATA' && status !== 'CREATED' && status !== 'PROCESSING' && status !== 'FAILED') {
        const CSV = 'https://audiencelab-builder.s3.amazonaws.com/' + file;
        window.open(CSV, '_blank').focus();
      } else {
        context.notification.show('You can only download completed searches', 'error', true);
      }
    } catch (error) {
      console.error(error);
      context.notification.show('An error occurred during download', 'error', true);
    }
  };

  const duplicateB2bSearch = async (row) => {
    try {
      const item = b2bList && b2bList.filter((f) => f.id === row.id);

      if (!item || item.length === 0) {
        console.error('Invalid item for duplication');
        return;
      }

      const id = uuid4();
      const formId = uuid4();
      const formState = item[0].formStateList[item[0].formStateList.length - 1].formState;

      context.modal.show({
        title: 'Duplicate B2B',
        form: {
          id: { label: 'id', type: 'hidden', value: id },
          name: { label: 'Name', type: 'text', value: `Copy of ${item[0].name}`, required: true },
          description: { label: 'Description', type: 'text', value: item[0].description },
        },
        buttonText: 'Save',
        url: '/api/b2bSearch',
        method: 'POST',
      },
        async (response) => {
          try {
            context.notification.show('duplicated successfully', 'success', true);

            const updateResponse = await axios.patch(
              `${baseUrl}/api/b2bSearch/${response.id.value}/updateFormState/${formId}`,
              {
                contacts: formState.contacts || [],
                companies: formState.companies || [],
              }
            );

            const formStateResponse = updateResponse.data?.data;

            if (!formStateResponse) {
              console.error('Invalid response after updating form state');
              return;
            }

            const copyData = [...b2bList];
            copyData.push({
              id: response.id.value,
              name: response.name.value,
              description: response.description.value,
              status: 'CREATED',
              account_id: item[0]?.account_id,
              created_on: item[0]?.created_on,
              updatedAt: item[0]?.updatedAt,
              formStateList: [formStateResponse],
              fileDetailsList: [],
              visualData: [],
            });

            dispatch(setList(copyData));
            dispatch(clearForm());
            dispatch(setForm(formStateResponse));
            dispatch(setPagValues({ total: 1, limit: 25 }));
            dispatch(setCurrentIndexPage(1));
            navigate(`${response.id.value}`);
          } catch (error) {
            console.error(error);
            context.notification.show('An error occurred during duplication', 'error', true);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteB2bSearch = (row) => {
    context.modal.show({
      title: 'Are you sure you want to delete this B2B?',
      form: {
        id: { label: 'id', type: 'hidden', value: row.id },
      },
      buttonText: 'Delete',
      url: `/api/b2bSearch/${row.id}`,
      method: 'DELETE',
    }, (response) => {
      try {
        context.notification.show('deleted successfully', 'success', true);
        const copyData = [...b2bList];
        const index = copyData.findIndex((f) => f.id === row.id);
        copyData.splice(index, 1);
        dispatch(setList(copyData));
        dispatch(clearForm());
        dispatch(setPagValues({ total: 1, limit: 25 }));
        dispatch(setCurrentIndexPage(1));
      } catch (error) {
        console.error(error);
        context.notification.show('Something went wrong', 'error', true);
      }
    });
  };

  const hasFilesToDownload = (data) => {
    data.forEach((row) => {
      if (row.fileDetailsList?.length === 0) {
        row.canDownload = 'CANT_DOWNLOAD';
      } else {
        row.canDownload = 'CAN_DOWNLOAD';
      }
    });
    return data;
  };


  const getB2bList = async () => {
    try {
      const res = await axios.get(`${baseUrl}/api/b2bSearch?page=${currentIndexPage}&limit=10`);
      const data = res.data.data;
      dispatch(setPagValues({ total: data.totalDocs, limit: parseInt(data.limit) }));
      dispatch(setList(addTypeCheck(hasFilesToDownload(data.docs))));
    } catch (error) {
      if (error.response && error.response.status === 403) {
        context.notification.show('Please contact support for B2B access', 'error', true)
      }
      console.error(error);
    }
  };

  // Search Results:
  const [searchResult, setSearchResult] = useState([]);
  const searchB2B = async () => {
    const res = await axios.get(`${baseUrl}/api/b2bSearch?limit=1000`);
    setSearchResult(res.data.data.docs);
  };

  useEffect(() => {
    dispatch(setCurrentIndexPage(1));
    searchB2B();
  }, []);

  useEffect(() => {
    getB2bList();
  }, [currentIndexPage]);



  // FACEBOOK INTEGRATION
  const FacebookB2B = async (data) => {
    let audienceHeader = {...data}; 
    audienceHeader["featuretype"] = "AudiencelabB2B";
    setFacebookAudienceData(audienceHeader);

    const b2bId = data.id;
    const isSyncedRequest = await axios.get(`${baseUrl}/api/facebook/hasSyncedB2B/${b2bId}?account_id=${accountId}`);
    const isSynced = isSyncedRequest.data.message;

    if (hasLoggedCondition) {

      if (isSynced) {
        context.notification.show('Your B2B is already synced', 'success', true);

      } else {

        setShowSyncModal(true);
      }
      
    } else {
      context.newModal.show({
        title: "",
        children: <FacebookLoginModal/>,
    });
    }

  };
  
  function filterActions(actions) {
    let filtered;

    if (facebookIntegrationAccess) {
      filtered = actions;
    } else {
      filtered = actions.filter(action => action.icon !== "facebook");
    }

    return filtered;
  }

  const actionList = [
    {
      icon: 'download', tooltip: ['Download B2B', `This B2B can't be downloaded`], onClick: [downloadB2bSearch],
      condition: [
        { col: 'canDownload', values: ['CAN_DOWNLOAD'] }
      ]
    },
    {
      icon: "facebook",
      color: "blue",
      tooltip: ["Sync Audience with Facebook","Build audience first"],
      onClick: [FacebookB2B],
    },
    { icon: 'arrow-right', tooltip: ['Enter B2B Search'], onClick: [editB2bSearch] },
    {
      icon: 'copy', tooltip: ['Copy B2B Search'], onClick: [duplicateB2bSearch],
      condition: [
        { col: 'status', values: ['COMPLETE', 'IN QUEUE', 'CREATED', 'PROCESSING', 'GENERATE_DATA'] },
      ],
    },

    { icon: 'trash', tooltip: ['Delete B2B Search'], onClick: [deleteB2bSearch], color: 'red' },
  ]

  const filteredActions = filterActions(actionList)

  return (
    <Fragment>
      {/* Title */}
      <div className='flex justify-between items-center'>
          <h2 className="m-6 text-3xl font-semibold">Business</h2>
          {
            quota?.permit &&
            <button
              type="button"
              onClick={createB2bSearch}
              className="m-6 inline-flex items-center rounded-md bg-blue-600 px-5 py-2.5 text-sm font-semibold text-white shadow-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          >
          <Icon image="plus-square" size={18} />
              Create
          </button>
          }
      </div>

      {showSyncModal && <SyncAudienceModal />}
      {isSyncSuccess && <FacebookSuccessModal />}
      <Quota
        type='b2b'
        quota={quota}
        setQuota={setQuota}
        isDetailedQuota
        limitLabel='Current B2B limit'
        usageLabel='B2B posted'
        remainingLabel='B2B left'
      />

      {/* Table */}
      <div className='m-6'>
        <NewPaginate
          offset={currentIndexPage - 1}
          limit={pagValues.limit}
          total={pagValues.total}
          currentPage={currentIndexPage}
          next={() => dispatch(setCurrentIndexPage(currentIndexPage + 1))}
          prev={() => dispatch(setCurrentIndexPage(currentIndexPage - 1))}
          onChange={() => dispatch(setCurrentIndexPage())}
        />
        <Table
          search={searchResult}
          data={b2bList.length <= 10 ? b2bList : {}}
          show={['name', 'description', 'created_on', 'updatedAt', 'status']}
          badge={{
            col: 'status', color: 'blue', condition: [
              { value: 'Empty query and no keywords provided', label: 'FAILED', color: 'red', tooltip: 'Please add keywords to your audience' },
              { value: 'Kindly Try Again', label: 'FAILED', color: 'red', tooltip: 'Please try again' },
              { value: 'FAILED', color: 'red' },
              { value: 'COMPLETE', color: 'green' },
              { value: 'GENERATE_DATA', label: 'generate data', color: 'blue' },
              { value: 'NO_DATA', color: 'red' },
            ],
          }}
          actions={filteredActions}
        />
      </div>
    </Fragment>
  );
};
