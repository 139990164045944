/** *
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { UploadIcon, TrashIcon, UserIcon } from '@heroicons/react/outline';
import { checkLocation } from 'helpers/checklocation';
import { Animate, AuthContext, ViewContext } from 'components/lib';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './profile.css';
import axios from 'axios';

const Profile = () => {
  const baseUrl = checkLocation();
  // User data state
  const [userData, setUserData] = useState({});
  // Local form state
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  // Fetch user data
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/user`);
        const data = response.data.data;
        const names = data.name.trim().split(' ') || data.name;
        setUserData(data);
        setFirstName(data.first_name ? data.first_name : names[0]);
        setLastName(data.last_name ? data.last_name : names[1]);
        setEmail(data.email || '');
        setPhoneNumber(data.phone_number || '');
      } catch (error) {
        console.error('Failed to fetch user:', error);
      }
    };
    getUser();
  }, [baseUrl]);
  
  // form
  const renderInput = (label, value, setValue) => {
    const textInput = (
      <span className='flex flex-wrap mt-2 mb-4 min-h-[42px] relative w-full rounded-lg border border-gray-300'>
        <input
          className='resize-none overflow-hidden h-8 border-none border-transparent focus:border-transparent focus:ring-0 m-1 w-full'
          id={label}
          type='text'
          placeholder='Type here'
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
      </span>
    );
  
    const phoneInput = (
      <div className="flex flex-wrap mt-2 mb-4 min-h-[42px] relative w-full rounded-lg border border-gray-300 no-focus-border">
        <PhoneInput
          country={'us'}
          containerStyle={{
            display: 'flex',
            flexWrap: 'wrap',            
            boxShadow: 'none',
          }}
          inputStyle={{
            height: '32px',
            margin: '0.25rem',
            border: 'none',
            outline: 'none',
            width: 'calc(100% - 0.5rem)',
            fontSize: '1rem',
            borderRadius: '0.375rem',
          }}
          value={value}
          onChange={(phone) => setValue(phone)}
        />
      </div>
    );
  
    return (
      <div className="ml-3 justify-center items-center">
        <label>{label}</label>
        {label === "Phone Number" ? phoneInput : textInput}
      </div>
    );
  };

  // Profile Settings - Delete Account, Save Profile, Upload Image, Delete Image
  const closeAccount = () => {
    viewContext.modal.show({

      title: 'Close Your Account',
      form: {},
      buttonText: 'Close Account',
      url: authContext.permission.owner ? '/api/account' : '/api/user',
      method: 'DELETE',
      destructive: true,
      text: 'Are you sure you want to delete your account? ' +
      'You will lose all of your data and this can not be undone.',

    }, () => {
      // destory user
      Event('closed_account');
      localStorage.clear();
      navigate('/signup');
    });
  }

  const saveProfile = async () => {
    try {
      const res = await axios.patch('/api/user', {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phoneNumber
      });
      if (res.status === 200) {
        if (authContext.user?.accounts?.length > 0) {
          const accounts = [...authContext.user.accounts];
          accounts[accounts.findIndex((x) => x.id === authContext.user.account_id)].name = res.data.data.account_name;
          authContext.update({accounts: accounts});
        }
        viewContext.notification.show('Profile updated successfully','success', true);
        authContext.update({name: res.data.data.first_name + ' ' + res.data.data.last_name});
      }
    } catch (error) {
      console.error('Failed to update profile:', error);
      viewContext.notification.show('Failed to update profile','error', true);
    }
  };

  // Profile Settings - Image upload controls
  const fileInputRef = useRef(null);

  const uploadImage = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await axios.post(`${baseUrl}/api/user/profile/image`, formData);
      setUserData(prev => ({ ...prev, image: response.data.image }));
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const deleteImage = async () => {
    try {
      await axios.delete(`${baseUrl}/api/user/profile/image`);
      setUserData(prev => ({ ...prev, image: null }));
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  }

  return (
    <Fragment>
      <Animate>
        <div className="flex flex-col justify-between h-screen">
          {/* View */}
          <div>
            {/* Title */}
            <h2 className="m-6 text-3xl font-semibold">Profile settings</h2>

            {/* Header */}
            <div className="m-6 bg-gray-100 p-4 rounded-xl">
              <div className="max-w-7xl mx-auto">
                <div className="mt-4 flex justify-center">
                  <div className="profile-image-uploader">
                    {/* Profile image */}
                    <div className="flex justify-center items-center mb-8">
                      <div className={`${userData.image ? 'p-2.5' : 'p-8'} bg-gray-200 rounded-full flex justify-center items-center`}>
                          {userData?.image ? (
                            <div className='rounded-full p-2 inline-flex justify-center items-center overflow-hidden h-20 w-20'>
                              <img
                                src={`https://audiencelab-builder.s3.amazonaws.com/${userData?.image}`}
                                alt="Profile"
                                className="rounded-full h-full w-full object-cover"
                              />
                            </div>
                          ) : (
                            <div className='bg-gray-900 rounded-full p-2 inline-flex justify-center items-center'>
                              <UserIcon className="text-white h-5 w-5" />
                            </div>
                          )}
                          
                      </div>
                    </div>
                    {/* Image upload controls */}
                    <div className="image-upload-controls">
                      <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                    {/* Image upload controls */}
                      <div className="image-upload-controls">
                        <button
                          className="upload-button"
                          onClick={uploadImage}
                        >
                          <UploadIcon className="icon" />
                          Upload image
                        </button>
                        <button
                          className="delete-button"
                          onClick={deleteImage}
                          >
                          <TrashIcon className="icon" />
                          Delete image
                        </button>
                      </div>
                    </div>
                    {/* Image requirements */}
                    <p className="image-requirements">Image must be 256 x 256px - Max 2MB</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Form */}
            {Object.keys(userData).length <= 0 ?
              <span className='m-6'>Loading...</span> :
              <div className='grid grid-cols-2 mr-6 ml-4'>
                {renderInput('First Name', firstName, setFirstName)}
                {renderInput('Last Name', lastName, setLastName)}
                {renderInput('Email', email, setEmail)}
                {renderInput('Phone Number', phoneNumber, setPhoneNumber)}
              </div>
            }
          </div>

          {/* Buttons */}
          <div className='flex justify-between items-end m-6'>
            <button
              type="button"
              onClick={closeAccount}
              className="rounded-md border border-gray-300 px-7 py-2.5 text-sm text-gray-500 hover:text-white hover:bg-red-400"
              >
              Delete Account
            </button>
            <button
              type="button"
              onClick={saveProfile}
              className={'rounded-md bg-blue-600 px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'}
            >
              Save
            </button>
          </div>
        </div>
      </Animate>
    </Fragment>
  );
}

export default Profile;
