import React, {useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setEnrichmentsTable, setSearchResults, deleteEnrichment, setLoading, setCurrentPage, setPagValues} from 'redux/features/enrichment/enrichmentSlice';
import {Animate, ViewContext, NewPaginate} from 'components/lib';
import {Table} from '../../gsd-components/table/table';
import {addTypeCheck} from 'helpers/checkPermissionType';
import {checkLocation} from 'helpers/checklocation';
import Formatation from '../../helpers/format';
import axios from 'axios';

export function EnrichmentTable() {
  const context = useContext(ViewContext);
  const format = new Formatation();
  const dispatch = useDispatch();
  const table = useSelector((state) => state.enrichment.enrichmentsTable);
  const searchResults = useSelector((state) => state.enrichment.searchResults);
  const loading = useSelector((state) => state.enrichment.loading);
  const currentPage = useSelector((state) => state.enrichment.currentPage);
  const pagValues = useSelector((state) => state.enrichment.pagValues);
  const baseUrl = checkLocation();

  const getData = async () => {
    try {
      const limit = pagValues.limit ? pagValues.limit : 10;
      const res = await axios.get(`${baseUrl}/api/enrichment/${currentPage}?limit=${limit}`);
      const data = await res.data.data;
      dispatch(setEnrichmentsTable(addTypeCheck(data?.docs)));
      dispatch(setPagValues({total: data.totalDocs, limit: data.limit}));
      dispatch(setLoading(false));
    } catch (error) {
      if (error.response && error.response.status === 403) {
        context.notification.show('Please contact support for Enrichment access', 'error', true);
      }
    }
  };

  const getSearch = async () => {
    try {
      const res = await axios.get(`${baseUrl}/api/enrichment/${currentPage}?limit=1000`);
      const data = await res.data.data;
      dispatch(setSearchResults(addTypeCheck(data?.docs)));
    } catch (error) {
      if (error.response && error.response.status === 403) {
        context.notification.show('Please contact support for Enrichment access', 'error', true);
      }
    }
  };

  const downloadCSV = async (e, file) => {
    if (e.fileDetailsList) {
      let CSV;
      if (file.url) {
        CSV = file.url;
        context.notification.show('Gathering data... Your download will start soon.', 'success', true);
        try {
          const response = await fetch(`${baseUrl}/api/download-from-url?url=${file.url}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const csvData = await response.blob();
          const url = window.URL.createObjectURL(csvData);

          // Create a link and trigger the download
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = file.name;
          document.body.appendChild(a);
          a.click();

          // Clean up and remove the link
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);

          context.notification.show('Downloaded successfully', 'success', true);
        } catch (error) {
          console.error('Failed to download CSV file', error);
        }
      } else {
        CSV = 'https://audiencelab-builder.s3.amazonaws.com/' + file.name;
        try {
          const link = document.createElement('a');
          link.href = CSV;
          link.download = file.name; // Set the filename for download
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          context.notification.show('Downloaded successfully', 'success', true);
        } catch (error) {
          console.error('Failed to download CSV file', error);
        }
      }
    }
    return null;
};

  const csvShowModal = (e) => {
    if (!e.fileDetailsList || e.fileDetailsList === null || e.status !== 'COMPLETE') {
      context.notification.show('No CSV files to download', 'error', true);
      return null;
    } else {
      context.modal.show({
        title: 'Download CSV',
        text: 'Please select a version of pixel to download.',
        form: {
          csv: {
            label: 'CSV',
            type: 'checkbox',
            options: e.fileDetailsList
                .filter((file) => file.createdOn)
                .map((file) => format.timestampModal(file.createdOn)),
          },
        },
        buttonText: 'Download',
        url: `/api/enrichment/callback/`,
        method: 'GET',
      }, (data) => {
        const filesToDownload = e.fileDetailsList.map((file) => data.csv.value.includes(format.timestampModal(file.createdOn)) ? file : null).filter((f) => f !== null);
        filesToDownload.forEach((d) => downloadCSV(e, d));
      });
    }
  };

  useEffect(() => {
    dispatch(setCurrentPage(1));
    getSearch();
  }, []);

  useEffect(() => {
    getData();
  }, [currentPage, loading]);

  const delEnrichment = (e) => {
    context.modal.show({
      title: 'Delete Enrichment',
      form: {},
      text: 'Are you sure you want to delete this enrichment?',
      buttonText: 'Delete',
      url: `/api/enrichment/${e._id}`,
      method: 'DELETE',
    }, () => {
      context.notification.show('deleted successfully', 'success', true);
      dispatch(deleteEnrichment(e._id));
      dispatch(setLoading(true));
    });
  };

  return (
    <Animate>
      <div className='m-6'>
        <NewPaginate
          offset={currentPage - 1}
          limit={pagValues.limit}
          total={pagValues.total}
          next={() => dispatch(setCurrentPage(currentPage + 1))}
          prev={() => dispatch(setCurrentPage(currentPage - 1))}
          onChange={() => dispatch(setCurrentPage())}
        />
        <Table
          search={searchResults?.map((item) => ({...item, status: item.fileDetailsList[0].status.replace(/_/g, ' '), date: format.timestampTable(item.createdAt)}))}
          data={table?.map((item) => ({...item, status: item.fileDetailsList[0].status.replace(/_/g, ' '), date: format.timestampTable(item.createdAt)}))}
          show={['date', 'name', 'status']}
          badge={{
            col: 'status', color: 'blue', condition: [
              {value: `PROCESSING`, color: 'blue'},
              {value: 'COMPLETE', color: 'green'},
              {value: 'FAILED', color: 'red'},
              {value: 'NO_DATA', color: 'blue'},
            ],
          }}
          actions={[
          {icon: 'download', tooltip: ['Download CSV'], onClick: [csvShowModal],
            condition: [
              {col: 'status', values: ['COMPLETE']}
            ],
          },
          {icon: 'trash-2', tooltip: ['Delete'], onClick: [delEnrichment]},
          ]}
        />
      </div>
    </Animate>
  );
}
