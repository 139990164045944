import { Fragment, useContext, useState, useEffect } from 'react';
import { AuthContext, AppNav, Header, User } from 'components/lib';
// import { useAccess } from 'gsd-hooks';
import Style from './app.module.scss';
import { fromHex } from 'helpers/decode';

export function AppLayout(props) {
  const [visibleItemsData, setVisibleItemsData] = useState([]);
  const context = useContext(AuthContext);

  let itemsData = [
    { label: 'Audience', icon: 'users', link: '/audience', permit: false },
    { label: 'CustomAudience', icon: 'users', link: '/audience', permit: false },
    { label: 'Audience Prototype', icon: 'users', link: '/audience-prototype', permit: false },
    { label: 'Keywords', icon: 'search', link: '/keyword-planner', permit: false },
    { label: 'Pixel', icon: 'code', link: '/pixel', permit: false },
    { label: 'Enrichment', icon: 'map', link: '/enrichment', permit: false },
    { label: 'Business', icon: 'briefcase', link: '/business', permit: false },
    { label: 'Integrations', icon: 'link', link: '/integrations', permit: false },
    { label: 'Account', icon: 'user', link: '/account', permit: true },
    { label: 'Help', icon: 'help-circle', link: '/help', permit: true },
    { label: 'Sign Out', icon: 'log-out', action: context.signout, permit: true },
  ];

  useEffect(() => {
    const accessData = fromHex(context.user.access);

    function checkProperties(obj) {
      for (let key in obj) {
        if (obj[key] === true) {
          return true;
        }
      }
      return false;
    }

    const integrationsPermit = checkProperties(accessData)

    if (accessData) {

      const customPermit = accessData.audiences === false && accessData.custom_audiences === true;


      const accessMappingData = {
        'Audience': accessData.audiences,
        'CustomAudience': customPermit,
        'Pixel': accessData.pixels,
        'Enrichment': accessData.enrichments,
        'Business': accessData.b2b,
        'Integrations': integrationsPermit
      };

      const updatedItemsData = itemsData.map((item) => {
        return { ...item, permit: accessMappingData[item.label] ?? item.permit };
      });

      setVisibleItemsData(updatedItemsData.filter((item) => item.permit));
    } else {
      const access = fromHex(context.user.account_access);

      const customPermit = this.access?.audiences?.allow === false && this.access?.custom_audiences?.allow === true;
      console.log("custom permit", customPermit);

      if (access) {
        const accessMapping = {
          'Audience': access.audiences?.allow,
          'CustomAudience': customPermit,
          'Pixel': access.pixels?.allow,
          'Enrichment': access.enrichments?.allow,
          'Business': access.b2b?.allow,
          'Integrations': integrationsPermit
        };

        const updatedItems = itemsData.map((item) => {
          return { ...item, allow: accessMapping[item.label] ?? item.permit };
        });

        setVisibleItemsData(updatedItems.filter((item) => item.permit));
      }
    }

    if (!context.user?.account_access && !context.user?.access) {
      const allowedLabels = ['Audience', 'Pixel', 'Account', 'Help', 'Sign Out'];
      setVisibleItemsData(itemsData.filter((item) => allowedLabels.includes(item.label)));
    }
  }, []);

  return (
    <Fragment>
      <AppNav items={visibleItemsData} />
      <main className={Style.app}>
        {<props.children {...props.data} />}
      </main>
    </Fragment>
  );
}
