import {configureStore} from '@reduxjs/toolkit';
import accountSliceReducer from './features/account/accountSlice';
import audienceBuilderSliceReducer from './features/audienceBuilder/audienceBuilderSlice';
import audienceWizardSliceReducer from './features/audienceWizard/audienceWizardSlice';
import businessBuilderSliceReducer from './features/businessBuilder/businessBuilderSlice';
import enrichmentSliceReducer from './features/enrichment/enrichmentSlice';
import facebookSliceReducer from './features/facebook/facebookSlice';
import keywordPlannerSliceReducer from './features/audienceBuilder/keywordPlannerSlice';
import pixelProcessorSliceReducer from './features/pixelProcessor/pixelProcessorSlice';

export default configureStore({
  reducer: {
    account: accountSliceReducer,
    audienceBuilder: audienceBuilderSliceReducer,
    audienceWizard: audienceWizardSliceReducer,
    businessBuilder: businessBuilderSliceReducer,
    enrichment: enrichmentSliceReducer,
    facebook: facebookSliceReducer,
    keywordPlanner: keywordPlannerSliceReducer,
    pixelProcessor: pixelProcessorSliceReducer,
  },
});
