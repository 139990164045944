import { useState, useCallback, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { v4 as uuid4 } from 'uuid';
import { checkLocation } from 'helpers/checklocation';

import { Card, AuthContext, ViewContext, useNavigate } from 'components/lib';
import { SearchSelectFacebook } from './search_select_facebook';

export function useFacebook() {
  const [facebookAudienceData, setFacebookAudienceData] = useState(null);
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [isSyncSuccess, setIsSyncSuccess] = useState(false);
  const [AdAccounts, setAdAccounts] = useState([]);
  const [selectedAdAccount, setSelectedAdAccount] = useState(null);
  const [facebookIntegrationAccess, setFacebookIntegrationAccess] = useState(null);
  const [tempAudienceName, setTempAudienceName] = useState('');
  const [audienceName, setAudienceName] = useState('');
  const [hasLoggedCondition, sethasLoggedCondition] = useState(false);
  const audienceNameRef = useRef('');
  const navigate = useNavigate();
  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const baseUrl = checkLocation();

  // gsdBusiness
//  const appId = "236345589373542"

  // lead sync
  // const appId = "740702317858564"
  // const appId = "452477963882496"

  // audiencelab sync 
  const appId = "722707759519969"
  // const appId = "293406740194045"


  const accountId = authContext.user.account_id;


  const redirectUri = () => {
    const path = window.location.pathname;
    let page = path.split("/").pop();
    // if (page === "integrations") {
    //   page = "integrations/facebook/adaccounts";
    // }
    // const uri = `https://staging.audiencelab.io/${page}`;
    const uri = `https://app.audiencelab.io/${page}`;
    // const uri = `http://localhost:3000/${page}`;
    return uri;
  };


  const encodedRedirectUri = encodeURIComponent(redirectUri());
  const facebookLink = `https://www.facebook.com/v19.0/dialog/oauth?client_id=${appId}&scope=public_profile%2Cread_insights%2Cpages_show_list%2Cads_management%2Cads_read%2Cbusiness_management%2Cpages_read_engagement&redirect_uri=${encodedRedirectUri}&state=${accountId}`;

  const getAccess = async () => {
    const accessResponse = await axios.get(`${baseUrl}/api/access`);
    setFacebookIntegrationAccess(accessResponse.data.data.integrations_facebook.permit);
  };


  const getAdAccounts = async () => {

    const hasLoggedRequest = await axios.get(`${baseUrl}/api/facebook/hasToken`);
    const hasLogged = hasLoggedRequest.data;
    if (hasLogged) {
      sethasLoggedCondition(true);
      axios.get(`${baseUrl}/api/facebook/adAccounts`).then((res) => {
        const formattedAdAccounts = res.data.map((AdAccount) => {
          return {
            value: AdAccount.id,
            label: `${AdAccount.name} (${(AdAccount.id).replace("act_", "")})`,
          };
        });

        setAdAccounts(formattedAdAccounts);
      });
    }
  };


  const getLogged = async () => {
    const hasLoggedRequest = await axios.get(`${baseUrl}/api/facebook/hasToken`);
    const hasLogged = hasLoggedRequest.data;

    if (hasLogged) {

      sethasLoggedCondition(true);
    }

  };



  useEffect(() => {

    const redirectPath = window.location.pathname;
    let redirectPage = redirectPath.split("/").pop();

    const getQueryParam = (param) => {
      const urlParams = new URLSearchParams(window.location.search);

      return urlParams.get(param);
    };

    const redirectLink = redirectUri()

    const facebookResponse = getQueryParam('code');
    const state = getQueryParam('state');

    if (facebookResponse && state) {
      const endpoint = `${baseUrl}/api/facebook/oauth/${state}`;


      axios.post(endpoint, { facebookResponse, redirectLink })
        .then(response => {

          sethasLoggedCondition(true)

          context.newModal.show({
            title: "Meta Authentication",
            children: <span>{response.data.status}</span>,
          });

          getAdAccounts();

          navigate("/" + redirectPage)

        })
        .catch(error => {
          console.error(error);
          context.newModal.show({
            title: "Authentication failed",
            children: <span>{error.response.data.message}</span>,
          });
        });
    }

  }, []);


  useEffect(() => {
    getLogged()
    redirectUri()
    getAccess();
    getAdAccounts();
  }, []);



  const FacebookStyle = {
    subtitle: "font-bold mt-4",
    link: "text-blue-500 font-semibold mt-3 underline",
  };

  const FacebookLoginModalComponent = () => (
    <Card className="mb-40 flex flex-col items-start space-y-4">
      <img
        src="fav/meta-logo.png"
        alt="meta logo"
        className="py-1 w-2/5 lg:w-1/3"
      />

      <div>Proceed with Facebook Business Authentication through Meta's oAuth link</div>
      <div className="w-full flex justify-center">
        <button
          className={FacebookStyle.link}
          onClick={() => {
            window.location.href = facebookLink;
          }}>
          <div>Connect</div>
        </button>

      </div>
    </Card>
  );

  const FacebookSuccessModalComponent = () => (
    <div className="modal modal-appear-done modal-enter-done" onClick={() => closeIsSyncSuccess()}>
      <div className="modal-content text-center" onClick={(event) => event.stopPropagation()}>
        <Card title="Success on syncing audience" className="h-[350px]">
          <div className="text-lg my-5">We are sending your audience to Meta. Meta says this can take up to 6 hours, depending on the audience size. You should see the audience building in your account.</div>
        </Card>
      </div>
    </div>
  );

  const closeIsSyncSuccess = () => {
    setIsSyncSuccess(false);
  }

  const SyncAudienceModalComponent = () => {

    const handleAudienceNameChange = (event) => {
      audienceNameRef.current = event.target.value;
    };

    const handleAudienceNameBlur = () => {
      setAudienceName(audienceNameRef.current);
    };

    const handleAdAccountChange = (selectedOption) => {
      setSelectedAdAccount(selectedOption ? { value: selectedOption.value, label: selectedOption.label } : {});
    };


    const handleSubmit = async (event) => {

      event.preventDefault();
      setShowSyncModal(false);

      const AdAccountId = selectedAdAccount.value


      const form = {
        facebook_name: audienceName,
        audience_header: facebookAudienceData,
        ad_account: AdAccountId
      }


      try {

        setAudienceName('');
        setSelectedAdAccount(null);
        audienceNameRef.current = '';
        setShowSyncModal(false);
        await axios.post(`${baseUrl}/api/facebook/createCustomAudience/`, form)
        await axios.patch(`${baseUrl}/api/access/usage/integrations_facebook`)
        setIsSyncSuccess(true);

      } catch (error) {
        console.error(error.response.data.message);
        context.newModal.show({
          title: "Error on Meta's request",
          children: <span>{error.response.data.message}</span>,
        });
      }

    }

    const closeFacebookSyncModal = () => {
      setShowSyncModal(false);
      setAudienceName('');
      setSelectedAdAccount(null);
      audienceNameRef.current = '';
    };

    return (

      <div className="modal modal-appear-done modal-enter-done">
        <div className="modal-content" style={{ height: '550px' }}>
          <section className="relative p-6 x-1 mb-6 bg-white rounded">
            <header className="relative pb-5 mb-5 border-dotted border-slate-100 border-b block text-sm uppercase">
              <h1 className="float:left m-0 text-lg font-semibold">
                {`Sync your data`}
              </h1>
            </header>
            <form className="" method="POST"
              onSubmit={handleSubmit}>
              <div className="relative mb-1 last:mb-0">
                <label className="relative block text-sm mb-2 uppercase">
                  Audience Name
                </label>
                <input
                  required
                  type="text"
                  name="facebook_name"
                  defaultValue={audienceName}
                  placeholder='Type..'
                  onChange={handleAudienceNameChange}
                  onBlur={handleAudienceNameBlur}
                  className="relative mb-5 pb-5 block w-full p-3 rounded bg-white border border-solid border-slate-100 focus:bg-slate-50 appearance-none disabled:opacity-50"
                />
              </div>
              <fieldset className="relative clear-both border-0 p-0 last:mb-0">
                <legend className="relative block text-sm mb-2 uppercase">
                  Select Ad Account
                </legend>
                <SearchSelectFacebook
                  options={[...AdAccounts]}
                  onChange={handleAdAccountChange}
                  value={selectedAdAccount}
                  isMulti={false}
                  isSearchable
                  lazyLoad={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 8,
                    colors: {
                      ...theme.colors,
                      primary: 'none',
                      neutral10: '#ced4da',
                      neutral80: '#18181B',
                      neutral15: '#18181B',
                      dangerLight: '#ced4da',
                      danger: '#52525B',
                    },
                  })}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: '55px',
                      zIndex: 100,
                    }),
                  }}
                  closeMenuOnSelect={true}
                />
              </fieldset>

              <div className="relative mb-1 last:mb-0">
                <label className="relative block text-sm mb-2 uppercase">
                </label>
                <div className="relative mb-5 pb-5 block w-full p-3 rounded bg-white focus:bg-slate-50 appearance-none disabled:opacity-50">
                </div>
              </div>

              <input type="hidden" id="audience_header" name="audience_header" value={facebookAudienceData} />
              <button type={"submit"} className="cursor-pointer mt-5 font-sans w-[49%] mr-[2%] last:mr-0 bg-blue-500 text-white hover:bg-blue-600 hover:border-blue-600 relative block text-center font-semibold rounded px-12 py-3 ease-in-out duration-500">Send</button>
              <button type={"button"} onClick={() => { closeFacebookSyncModal() }} className="cursor-pointer mt-5 font-sans text-slate-500 border-solid border border-slate-200 hover:text-white hover:border-slate-500 bg-transparent w-[49%] mr-[2%] last:mr-0 bg-zinc-900 hover:bg-black relative block text-center font-semibold rounded px-12 py-3 ease-in-out duration-500">Cancel</button>
            </form>
          </section>
        </div>
      </div>
    )
  }

  return {
    facebookAudienceData,
    setFacebookAudienceData,
    showSyncModal,
    setShowSyncModal,
    isSyncSuccess,
    hasLoggedCondition,
    redirectUri,
    facebookLink,
    facebookIntegrationAccess,
    FacebookLoginModal: FacebookLoginModalComponent,
    SyncAudienceModal: SyncAudienceModalComponent,
    FacebookSuccessModal: FacebookSuccessModalComponent,
  };
}
