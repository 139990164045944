/* eslint-disable */
/** *
*
*   AUTHENTICATION
*   Auth provider to manage auth functions throughout
*   the application. <PrivateRoute> component to
*   protect internal application routes from unauthenticated
*   access.
*
********* */

import { useState, useEffect, createContext } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import Settings from 'settings.json';
import { checkLocation } from 'helpers/checklocation';
import { fromHex } from 'helpers/decode';

// auth context
export const AuthContext = createContext();
const { Event } = require('components/lib');
const permissions = require('./permissions');

export function AuthProvider(props) {
  const cache = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(cache);
  const baseUrl = checkLocation();
  const auth = cache ? axios.get(`${baseUrl}/api/auth`) : null;

  const readAuth = async () => {
    const res = await auth;
    localStorage.setItem('type', res?.data?.data?.type);
  };

  readAuth();
  useEffect(() => {
    // update the auth status
    if (!auth?.loading && auth?.data) {
      auth.data.authenticated ?
        update(auth.data) : signout();
    }
  }, [auth]);

  function signin(res) {

    if (res.data) {

      localStorage.setItem('user', JSON.stringify(res.data));
      axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
      Event('signin');

      // if (!res.data.plan) return window.location = '/signup/plan';

      if (res.data.access) {
        const accessData = fromHex(res.data.access)
        if (accessData.audiences) {
          return window.location = res.data.onboarded ? '/audience' : '/audience';
        }
        else {
          return window.location = res.data.onboarded ? '/integrations' : '/integrations'
        }
      } else {

        if (res.data.account_access) {
          const accountAccess = fromHex(res.data.account_access)
          if (accountAccess.audiences.allow) {
            return window.location = res.data.onboarded ? '/audience' : '/audience';
          }
          else {
            return window.location = res.data.onboarded ? '/integrations' : '/integrations'
          }
        }
      }

    }
  }

  function first_signin(res) {
    if (res.data) {
      localStorage.setItem('user', JSON.stringify(res.data));
      axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
      Event('signin');

      // if (!res.data.plan) return window.location = '/signup/plan';
      return window.location = res.data.onboarded ? '/signup/password' : '/signup/password';
    }
  }

  async function signout() {
    axios({ method: 'delete', url: '/api/auth' });
    localStorage.clear();
    return window.location = '/signin';
  }

  async function switchAccount(id) {
    const res = await axios({

      method: 'post',
      url: '/api/auth/switch',
      data: { account: id },

    });

    if (res.data) signin(res);
  }

  function update(data) {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));
      for (const key in data) {
        if (Array.isArray(data[key])) {
          user[key] = data[key];
        } else if (typeof data[key] === 'object') {
          for (const innerKey in data[key]) {
            user[key][innerKey] = data[key][innerKey];
          }
        } else {
          user[key] = data[key];
        }
      }

      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);
    }
  }

  return (
    <AuthContext.Provider
      value={{

        user,
        signin,
        first_signin,
        signout,
        update,
        switchAccount,
        permission: permissions[user?.permission],
        remote_server: Settings[process.env.NODE_ENV].remote_server,
        remote_client: Settings[process.env.NODE_ENV].remote_client,

      }}

      {...props}
    />
  );
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
  // check user exists
  const user = JSON.parse(localStorage.getItem('user'));
  // const path = window.location.pathname;
  // const permittedRoutes =
  //   [
  //     '/account/billing',
  //     '/signup/plan',
  //     '/account/upgrade',
  //     '/account',
  //     '/account/profile',
  //   ];

  if (user?.token) {
    if (permissions[user.permission][props.permission]) {
      // user has no plan
      // if (!user.plan && path !== '/account/profile' && path !== '/signup/plan') return <Navigate to="/signup/plan" />;

      // // user has no subscription
      // if ((user.subscription !== 'active' && user.subscription !== 'trialing') && user.permission !== 'master' && !permittedRoutes.includes(path)) return <Navigate to="/account/billing" />;

      // user is good
      return props.children;
    }
  }

  // user is not authenticated
  return <Navigate to="/signin" />;
}
/* eslint-enable */
