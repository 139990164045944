/** *
*
*   FOOTER (homepage)
*   Static homepage footer
*
**********/

import {Animate, Content} from 'components/lib';
import Style from './footer.tailwind.js';
export function Footer(props) {
  return (
    <Animate type='slideup'>
      <footer className={Style.footer}>
        <Content className={Style.content}>
          <div className={ Style.copyright }>{ `Copyright © AudienceLab ${new Date().getFullYear()}`}</div>
        </Content>
      </footer>
    </Animate>
  );
}
