import {createSlice} from '@reduxjs/toolkit';
import uuid4 from 'uuid4';

export const keywordPlannerSlice = createSlice({
  name: 'keywordPlanner',
  initialState: {
    keywordList: {},
    keywordInput: {},
    dateRange: {},
  },
  reducers: {
    addInput: (state, action) => {
      if (action.payload.fieldName && action.payload.value) {
        state.keywordInput[`${action.payload.fieldName}`] = action.payload.value;
      }
    },
    deleteInput: (state, action) => {
      state.keywordInput.keyword = [...state.keywordInput.keyword.filter((item) => item !== action.payload.value)];
    },
    setKeywordList: (state, action) => {
      state.keywordList = action.payload;
    },
    addKeyword: (state, action) => {
      state.keywordList.data = [...action.payload];
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload;
    },
    updateKeywordList: (state, action) => {
      /**
       * @param {object} action.payload = {
       *  type: string,
       *  id: string,
       * fileName: string,
       *  progress: {
       *   status: string,
       *   currentStep: number,
       *   totalSteps: number,
       *   stepName: string,
       *  stepDescription: string,
       *   stepProgress: number,
       *   totalProgress: number,
       * }
       * }
       */
      const data = action.payload;
      const keywordProgress = state.keywordList.data?.find((keyword) => keyword.keyword === data.id);
      if (keywordProgress) {
        keywordProgress.progress = data.progress;
        if (data.progress?.totalProgress && data.progress?.totalProgress === 100) {
          keywordProgress.status = 'COMPLETE';
          if (data.fileName) {
            keywordProgress.fileList.push({
              name: data.fileName,
              _id: uuid4(),
              date: new Date(Date.now()).toISOString(),
            });
          }
          if (data.data) {
            keywordProgress.keywords_matches = data.data.totalMatchedKeywords || 0;
            keywordProgress.search_volume = data.data.totalVolume || 0;
          }
        } else if (data.progress?.status === 'PROCESSING') {
          keywordProgress.status = `PROCESSING ${data.progress.totalProgress}%`;
        } else {
          keywordProgress.status = data.progress?.status;
        }
      }
    },
    deleteKeyword: (state, action) => {
      state.keywordList.data = [...state.keywordList.data.filter((item) => item.id !== action.payload)];
    },
  },
});

export const {deleteInput, addInput, setKeywordList, addKeyword, deleteKeyword, updateKeywordList, setDateRange} = keywordPlannerSlice.actions;

export default keywordPlannerSlice.reducer;
