/** *
*
*   SIGN UP STEP 1
*   Signup form for account owners
*   Step 1: create account
*   Step 2: select plan and make payment
*
**********/

import React, {useContext, useRef, useState} from 'react';
import {AuthContext, Animate, Row, Card, Form, Link} from 'components/lib';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

export function Signup(props) {
  const context = useContext(AuthContext);
  const captchaRef = useRef(null);
  const [valid, setValid] = useState(false);

  // Recaptcha

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    if (valid === false) {
      context.notification('Please verify you are not a robot', 'error', true);
    }

    const res = await axios.post(
        `https://www.google.com/recaptcha/api/siteverify?secret=${process.env.REACT_APP_RECAPTCHA_SECRET_KEY}&response=${token}`, {
          headers: {
            'Content-Security-Policy': '*', 'Content-type': 'application/json; charset=UTF-8',
          },
        },
    );
    if (res.status(200)) {
      res.send('Human 👨 👩');
      setValid(true);
    } else {
      res.send('Robot 🤖');
      context.notification('Please verify you are not a robot', 'error', true);
      setValid(false);
    }
  };

  const handleOnChange = () => {
    const token = captchaRef.current.getValue();
    token ? setValid(true) : setValid(false);
  };

  return (
    <Animate type='pop'>
      <Row title='Create Your Account'>
        <Card loading={ false } restrictWidth center>
          <Form
            data={{
              name: {
                label: 'First Name',
                type: 'text',
                required: true,
                errorMessage: 'Please enter your first name',
              },
              email: {
                label: 'Email',
                type: 'email',
                required: true,
              },
              password: {
                label: 'Password',
                type: 'password',
                required: true,
                complexPassword: true,
              },
              confirm_password: {
                type: 'hidden',
                value: null,
              },
            }}
            url={valid && '/api/account'}
            method='POST'
            buttonText={valid ? 'Create Account' : 'Verify you are not a robot'}
            onSubmit={handleOnSubmit}
            callback={ context.signin }
          />
          <br />
          <div className="flex mx-auto">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              ref={captchaRef}
              onChange={handleOnChange}
            />
          </div>
          <div className='mt-4'>
              Already registered? <Link url='/signin' text='Sign In' />
          </div>
        </Card>
      </Row>
    </Animate>
  );
}
