import React, { useEffect, useState } from 'react';
import { CheckIcon } from '@heroicons/react/solid';
import { useSelector, useDispatch } from 'react-redux';
import { nextStep, setStep, initialStep } from 'redux/features/audienceWizard/audienceWizardSlice';
import { AudienceGenerator } from './audience_generator';
import { defaultViews, newViews } from './wizard_views';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function AudienceWizard() {
  const targets = useSelector((state) => state.audienceBuilder.targets);
  const dispatch = useDispatch();
  const buildType = localStorage.getItem('audienceType') || "premade";
  const [views, setViews] = useState(defaultViews(buildType));

  useEffect(() => {
    if (targets === "new") {
      setViews(newViews(buildType));
    } else {
      setViews(defaultViews(buildType));
    }
  }, [targets, buildType]);

  let steps = useSelector((state) => state.audienceWizard.steps);

  const handleSteps = (name) => {
    dispatch(setStep({ name }));
  };

  const handleNext = () => {
    dispatch(nextStep());
  };

  const renderCurrentStep = () => {
    const currentStep = steps.find((step) => step.status === 'current');
    if (currentStep) {
      const componentName = currentStep.component;
      const Component = React.lazy(() => import(`./${componentName}`));

      return (
        <React.Suspense fallback={<div>Loading...</div>}>
          <Component key={currentStep.name} />
        </React.Suspense>
      );
    }

    return null;
  };

  useEffect(() => {
    dispatch(initialStep(views));
  }, [dispatch, views]);

  const totalSteps = steps.length;
  const currentStepIndex = steps.findIndex(step => step.status === 'current');
  const percentage = ((currentStepIndex + 1) / totalSteps) * 100;

  return (
    <div className='flex h-screen overflow-hidden'>

      {/* Navbar */}
      <nav aria-label="Progress" className='h-full w-1/5 flex flex-col justify-between items-center border-r p-2 overflow-auto'>
        <ol role="list" className="bg-white w-full">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pb-2' : '', 'relative')}>
              {step.status === 'complete' ? (
                <div onClick={() => handleSteps(step.name)} className="group relative flex items-start cursor-pointer p-2 hover:bg-gray-100 hover:rounded-md">
                  <span className="flex h-9 items-center">
                    <span className="relative z-10 flex h-6 w-6 items-center justify-center rounded-full bg-green-600">
                      <CheckIcon className="h-3 w-3 text-white" aria-hidden="true" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium">{step.name}</span>
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>
                </div>
              ) : step.status === 'current' ? (
                <div onClick={() => handleSteps(step.name)} className="group relative flex items-start cursor-pointer bg-gray-200 rounded-md p-2" aria-current="step">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-6 w-6 items-center justify-center rounded-full border-2 border-blue-600 bg-blue-600">
                      <span className="text-xs text-white">
                        {stepIdx + 1}
                      </span>
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium text-blue-600">{step.name}</span>
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>
                </div>
              ) : (
                <div onClick={() => handleSteps(step.name)} className="group relative flex items-start cursor-pointer p-2 hover:bg-gray-100 hover:rounded-md">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-6 w-6 items-center justify-center rounded-full border-2 border-gray-300 bg-gray-300">
                      <span className="text-xs">
                        {stepIdx + 1}
                      </span>
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium text-gray-500">{step.name}</span>
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>
                </div>
              )}
            </li>
          ))}
        </ol>
      </nav>

      {/* Current View */}
      <div className='flex-1 flex flex-col justify-between h-full overflow-auto p-2'>
        {renderCurrentStep()}
        {/* Buttons */}
        <div className='flex justify-between items-end p-5'>
          <AudienceGenerator />
          <button
            type="button"
            onClick={() => handleNext()}
            className="rounded-md bg-blue-600 px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
