/*
* EVENT
* log a new client event in the database
*/
import { checkLocation } from 'helpers/checklocation';

const Axios = require('axios');

export function Event(name, metadata) {
  const baseUrl = checkLocation();
  Axios.post(`${baseUrl}/api/event`, {

    name: name,
    metadata: metadata,

  });
}
