import axios from "axios";
import {checkLocation} from 'helpers/checklocation';
import {SignupLink} from 'views/auth/signup/link';

const baseUrl = checkLocation();

export const planRoutes = async () => {
const reqData = await axios.get(`${baseUrl}/api/plans`);
const data = reqData.data.data

const routes = data.map((element)=>{
    let newObject = {
        path: `/signup/${element.url}`,
        layout: 'auth',
        title: `Sign up with ${element.label}`,
      }
      return newObject
})



return routes
}