import {useState, useEffect} from 'react';
import {Button, ClassHelper} from 'components/lib';
import Style from './paginate.tailwind.js';

export function NewPaginateB2B({
  total,
  limit,
  offset,
  currentPage,
  next,
  prev,
  loading,
  className,
}) {
  const totalPages = Math.ceil(total / limit);

  function movePrev() {
    if (currentPage > 0) {
      prev();
    }
  }

  function moveNext() {
    if (currentPage < (totalPages)) {
      next();
    }
  }

  const paginateStyle = ClassHelper(Style, {

    paginate: true,
    className: className,

  });

  return (
    <section className={ paginateStyle }>

      <Button
        icon='chevron-left'
        size={ 20 }
        color='transparent'
        className={ Style.button }
        iconColor={ (currentPage > 1) && !(loading === true) ? 'purple' : 'grey' }
        action={ movePrev }
      />

      <span className={ Style.counter }>

        { (offset || limit) ?
          `showing page ${currentPage} of ${totalPages} ` : 'No Results' }

      </span>

      <Button
        icon='chevron-right'
        size={ 20 }
        color='transparent'
        className={ Style.button }
        iconColor={ currentPage < (totalPages) && !(loading === true) ? 'purple' : 'grey' }
        action={ moveNext }
      />

    </section>
  );
}
