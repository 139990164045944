/** *
*
*   LOGO
*   Replace the image in /images with your own logo
*
**********/

import {Link, ClassHelper} from 'components/lib';
import LogoWhite from './images/White-Color.png';
import LogoMarkWhite from './images/logo-mark-white.svg';
import LogoColor from './images/Black-Color.png';
import LogoMarkColor from './images/logo-mark-color.svg';
import Style from './logo.tailwind.js';

export function Logo(props) {
  const Logo = {
    color: {
      logo: LogoColor,
      mark: LogoMarkColor,
    },
    white: {
      logo: LogoWhite,
      mark: LogoMarkWhite,
    },
  };

  return (
    <Link url="/" className={`${props.big ? 'block w-[18em] h-[2em] mx-auto mb-4 outline-none' : 'block w-[6em] h-[2em] mx-auto mb-4 outline-none md:w-[8em]'}`}>
      <img src={Logo[props.color ? 'color' : 'white'][props.mark ? 'mark' : 'logo']} alt="Logo" />
    </Link>
  );
}
