import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {SearchSelect} from 'components/lib';
import axios from 'axios';

const ModalGoHighLevel = ({platform, baseUrl, pixelTag, clearModal, edit, isOpen, goHighLevelFormState, pixels, availableGoHighLevelPixels, successNotification, errorNotification, apiName, getIntegrations, pixelsList}) => {
  const [tagInput, setTagInput] = useState(goHighLevelFormState?.pixelTag || '');
  const [selectedPixels, setSelectedPixels] = useState([]);
  const navigate = useNavigate();

  const handleTagInput = (e) => {
      setTagInput(e.target.value);
  }


  useEffect(() => {
      setTagInput(pixelTag);
  }, [goHighLevelFormState]);

  const handlePixelChange = (value) => {
    if (value.length === 0 ) {
      setSelectedPixels(value?.map((item) => ({id: item.value, name: item.label})));
    } else if (value.find((item) => item.value === 'none')) {
      navigate('/pixel/welcome');
    } else {
      setSelectedPixels(value?.map((item) => ({id: item.value, name: item.label})));
    }
  };

  const goHighLevelFormStatePixels = goHighLevelFormState?.pixels;
  const isAllPixels = goHighLevelFormStatePixels && goHighLevelFormStatePixels.length === pixels?.length && goHighLevelFormStatePixels.every((id, index) => id === pixels[index]?.value);
  const mappedPixels = 
  availableGoHighLevelPixels.length === 0 && pixels.length === 1 ? [{value: pixels[0].value, label: pixels[0].label}] : goHighLevelFormStatePixels?.map((id) => {
    const correspondingPixel = pixels?.find((pixel) => pixel.value === id);
    if (correspondingPixel) {
      return {
        value: correspondingPixel.value,
        label: correspondingPixel.label,
      };
    }
    return null
  });

  
  const generateOptions = () => {
    if (availableGoHighLevelPixels.length === 0) {
      return [{ label: "No Pixels available. Create a new Pixel.", value: 'none' }];
    } else if (!edit && availableGoHighLevelPixels.length === 1) {
      return [
        { label: availableGoHighLevelPixels[0].label, value: availableGoHighLevelPixels[0].value }];
    } else if (!edit && availableGoHighLevelPixels.length === pixels.length) {
      return [{ label: 'All Pixels', value: availableGoHighLevelPixels },
                ...availableGoHighLevelPixels];

    } else {
      const usedPixels = pixels
      const unusedPixels = availableGoHighLevelPixels.filter(pixel => !usedPixels.includes(pixel.value));
      const allPixelsOption = unusedPixels.length > 0 
      ? [{ label: 'All Available Pixels', value: availableGoHighLevelPixels.map((pixel) => pixel.value)}] 
      : [{ label: 'All Pixels', value: pixels }];
      return [
        allPixelsOption[0],
        ...unusedPixels
      ];
    }
  };

  
  if (platform.type === 'Connect') return null;
  return (
    isOpen &&
    platform.platform === 'goHighLevel' && (
      <div className="modal modal-appear-done modal-enter-done">
        <div className="modal-content">
          <section className="relative p-6 x-1 mb-6 bg-white rounded">
            <header className="relative pb-5 mb-5 border-dotted border-slate-100 border-b">
              <h1 className="float:left m-0 text-lg font-semibold">
                { !edit ? `Connect with ${platform.platform}` : `Update Your ${platform.platform} Integration` }
              </h1>
            </header>
            <form
              className=""
              method="POST"
              onSubmit={(event) => {
                event.preventDefault();
                  
                let otherOptionsPixelIdArray = [];

                const form = {
                  name: apiName.name,
                  apiName: apiName.name,
                  pixels: selectedPixels.find((pixel) => pixel.name === 'All Available Pixels')
                  ? [...selectedPixels?.map((pixel) => {
                    if (typeof pixel.id === 'object') {
                      otherOptionsPixelIdArray = pixel.id.map((id) => id);
                    } else {
                      return pixel.id
                    }
                  }), ...otherOptionsPixelIdArray].filter((pixel) => pixel != null)
                  : selectedPixels[0]?.name === 'All Pixels'
                  ? availableGoHighLevelPixels?.map((pixel) => pixel.value)
                  : selectedPixels?.length === 0
                  ? mappedPixels?.map((pixel) => pixel.value)
                  : selectedPixels?.map((pixel) => pixel.id),
                  pixelTag: tagInput,
                };

                if (JSON.stringify(form.pixels[0]) == JSON.stringify(pixels)) {
                    form.pixels = pixels?.map((pixel) => pixel.value);
                }

                if (event.target.pixelTag.value !== '') {
                  form.pixelTag = event.target.pixelTag.value;
                }

                if (!edit) {
                  axios
                  .post(baseUrl + '/api/integration/goHighLevel', form)
                  .then((response) => {
                    successNotification('Connect');
                    clearModal();
                    getIntegrations();
                  })
                  .catch((error) => {
                    errorNotification('Connect');
                    console.error('Error submitting form:', error);
                  });
                } else {
                  axios
                  .put(baseUrl + '/api/integration/goHighLevel/' + goHighLevelFormState.id , form)
                  .then((response) => {
                    successNotification('Edit');
                    clearModal();
                    getIntegrations();
                  })
                  .catch((error) => {
                    errorNotification('Edit');
                    console.error('Error submitting form:', error);
                  });
                }
              }}
            >
              <fieldset className="relative clear-both border-0 p-0 last:mb-0">
                <legend className="relative block text-sm mb-2 uppercase after:text-red-500 after:ml-1 after:font-semibold after:content-['*']">
                  Is This Integration for All Pixels or a Particular Pixel?
                </legend>
                  <SearchSelect 
                    options={generateOptions()} 
                    onChange={(name, e) => handlePixelChange(e)}
                    isMulti 
                    isSearchable
                    lazyLoad={false}
                    defaultValue={edit? mappedPixels : ''}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 8,
                      colors: {
                        ...theme.colors,
                        primary: 'none',
                        neutral10: '#ced4da',
                        neutral80: '#18181B',
                        neutral15: '#18181B',
                        dangerLight: '#ced4da',
                        danger: '#52525B',
                      },
                    })}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        minHeight: '55px',
                      }),
                    }}
                    closeMenuOnSelect={false}
                  />
              </fieldset>
              <div className="relative mb-5 last:mb-0">
                <label className="relative block text-sm mb-2 uppercase after:text-red-500 after:ml-1 after:font-semibold after:content-['*']">
                  Tag Your Contacts
                </label>
                <input
                  placeholder="Your Tag"
                  type="text"
                  name="pixelTag"
                  value={tagInput}
                  onChange={(e) => handleTagInput(e)}
                  className="relative block w-full p-3 rounded bg-white border border-solid border-slate-100 focus:bg-slate-50 appearance-none disabled:opacity-50"
                />
              </div>
              <button
                type="submit"
                className="cursor-pointer font-sans w-[49%] mr-[2%] mt-2 last:mr-0 bg-blue-500 text-white hover:bg-blue-600 hover:border-blue-600 relative block text-center font-semibold rounded px-12 py-3 ease-in-out duration-500"
              >
               {edit ? 'Update' : 'Connect'}
              </button>
              <button
                onClick={clearModal}
                className="cursor-pointer font-sans text-slate-500 border-solid mt-2 border 
                border-slate-200 hover:text-white hover:border-slate-500  bg-transparent w-[49%] mr-[2%] last:mr-0 bg-zinc-900 hover:bg-black relative block text-center font-semibold rounded px-12 py-3 ease-in-out duration-500"
              >
                Cancel
              </button>
            </form>
          </section>
        </div>
      </div>
    )
  );
};

export default ModalGoHighLevel;