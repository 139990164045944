import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchSelect } from 'components/lib';
import axios from 'axios';

const ModalKlaviyo = ({ platform, baseUrl, closeModal, clearModal, edit, isOpen, pixels, availableKlaviyoPixels, klaviyoFormState, successNotification, errorNotification, pixelsList, apiName, getIntegrations }) => {
  const [selectedPixels, setSelectedPixels] = useState([]);
  const [klaviyoInput, setKlaviyoInput] = useState(klaviyoFormState?.data?.lists ? klaviyoFormState?.data?.lists?.map((list) => ({ id: list.id, name: list.name })) : []);
  const [klaviyoList, setKlaviyoList] = useState([]);
  const navigate = useNavigate();

  const handlePixelInput = (e) => {
    if (e.length === 0) {
      setSelectedPixels(e?.map((item) => ({ id: item.value, name: item.label })));
    } else if (e.find((item) => item.value === 'none')) {
      navigate('/pixel/welcome');
    } else {
      setSelectedPixels(e?.map((item) => ({ id: item.value, name: item.label })));
    }
  };

  const handleKlaviyoInput = (e) => {
    setKlaviyoInput(e.map((item) => ({ id: item.value, name: item.label })));
  };

  const klaviyoFormStatePixels = klaviyoFormState?.data?.pixels;
  const isAllPixels =
    klaviyoFormStatePixels &&
    klaviyoFormStatePixels.length === pixels?.length &&
    klaviyoFormStatePixels.every((id, index) => id === pixels[index]?.value);
  const mappedPixels = availableKlaviyoPixels.length === 0 && pixels.length === 1
    ? [{ value: pixels[0].value, label: pixels[0].label }]
    : klaviyoFormStatePixels?.map((id) => {
      const correspondingPixel = pixels?.find((pixel) => pixel.value === id);
      if (correspondingPixel) {
        return {
          value: correspondingPixel.value,
          label: correspondingPixel.label,
        };
      }
      return null;
    });

  const klaviyoFormStateLists = klaviyoFormState?.data?.lists;
  const mappedKlaviyoLists = klaviyoFormStateLists?.map((correspondingList) => {
    return {
      value: correspondingList.id,
      label: correspondingList.name,
    };
  });

  const klaviyoFormStateInputs = klaviyoFormState?.klaviyoLists;
  const mappedKlaviyoInputs = klaviyoFormStateInputs?.map((correspondingList) => {
    return {
      value: correspondingList.id,
      label: correspondingList.name,
    };
  });

  const sendForm = async (e) => {
    e.preventDefault();
    let otherOptionsPixelIdArray = [];

    const formOptions = {
      name: apiName.name,
      apiName: apiName.name,
      pixels: selectedPixels.find((pixel) => pixel.name === 'All Available Pixels')
        ? [...selectedPixels?.map((pixel) => {
          if (typeof pixel.id === 'object') {
            otherOptionsPixelIdArray = pixel.id.map((id) => id);
          } else {
            return pixel.id;
          }
        }), ...otherOptionsPixelIdArray].filter((pixel) => pixel != null)
        : selectedPixels[0]?.name === 'All Pixels'
          ? availableKlaviyoPixels?.map((pixel) => pixel.value)
          : selectedPixels?.length === 0
            ? mappedPixels?.map((pixel) => pixel.value)
            : selectedPixels?.map((pixel) => pixel.id),
      lists: klaviyoInput,
    };

    if (JSON.stringify(formOptions.pixels[0]) === JSON.stringify(pixels)) {
      formOptions.pixels = pixels?.map((pixel) => pixel.value);
    }

    try {
      const id = klaviyoFormState?.data?.id;
      if (edit) {
        await axios.put(`${baseUrl}/api/integration/klaviyo/${id}`, formOptions);
      } else {
        await axios.post(`${baseUrl}/api/integration/klaviyo`, formOptions);
      }
      edit ? successNotification('Edit') : successNotification('Connect');
      clearModal();
      getIntegrations();
    } catch (error) {
      edit ? errorNotification('Edit') : errorNotification('Connect');
      console.error('Error submitting form:', error);
    }
  };

  useEffect(() => {
    function findObjectByValue(array, value) {
      return array.find((obj) => obj['name'] === value);
    }

    const fetchKlaviyoList = async () => {
      try {
        if (!apiName.name || apiName.platform !== 'Klaviyo') {
          return;
        }

        const klaviyoIntegrations = await axios.get(`${baseUrl}/api/integrationAccount`);
        const matchingName = findObjectByValue(klaviyoIntegrations.data['klaviyo'], apiName.name);
        const klaviyoName = matchingName ? matchingName.name : null;
        const response = await axios.post(`${baseUrl}/proxy-klaviyo-api`, {
          apiName: klaviyoName
        });

        setKlaviyoList(response?.data?.data ? response.data.data : []);
      } catch (error) {
        console.error('error', error);
      }
    };

    fetchKlaviyoList();
  }, [apiName]);

  const generateOptions = () => {
    if (availableKlaviyoPixels.length === 0) {
      return [{ label: "No Pixels available. Create a new Pixel.", value: 'none' }];
    } else if (!edit && availableKlaviyoPixels.length === 1) {
      return [{ label: availableKlaviyoPixels[0].label, value: availableKlaviyoPixels[0].value }];
    } else if (!edit && availableKlaviyoPixels.length === pixels.length) {
      return [
        { label: 'All Pixels', value: availableKlaviyoPixels },
        ...availableKlaviyoPixels
      ];
    } else {
      const usedPixels = pixels;
      const unusedPixels = availableKlaviyoPixels.filter(pixel => !usedPixels.includes(pixel.value));
      const allPixelsOption = unusedPixels.length > 0
        ? [{ label: 'All Available Pixels', value: availableKlaviyoPixels.map((pixel) => pixel.value) }]
        : [{ label: 'All Pixels', value: pixels }];

      return [
        allPixelsOption[0],
        ...unusedPixels
      ];
    }
  };

  if (platform.platform !== 'Klaviyo') return null;
  return (
    isOpen &&
    platform.platform === 'Klaviyo' && (
      <div className="modal modal-appear-done modal-enter-done">
        <div className="modal-content max-h-[80vh] overflow-y-auto">
          <section className="relative p-6 x-1 mb-6 bg-white rounded">
            <header className="relative pb-5 mb-5 border-dotted border-slate-100 border-b block text-sm uppercase">
              <h1 className="float:left m-0 text-lg font-semibold">
                {!edit ? `Connect with ${platform.platform}` : `Update Your ${platform.platform} Integration`}
              </h1>
            </header>
            <form className="">
              <input type="hidden" id="name" name="name" value="4d024eab-7d7c-45e5-8f8c-9d444c4b42ab" />
              <div className="relative clear-both border-0 p-0 mb-0">
                <legend className="relative block text-sm mb-2 uppercase after:text-red-500 after:ml-1 after:font-semibold after:content-['*']">
                  Is this integration for all pixels or a particular pixel?
                </legend>
                <SearchSelect
                  options={generateOptions()}
                  onChange={(name, e) => handlePixelInput(e)}
                  name="state"
                  isMulti
                  isSearchable
                  lazyLoad={false}
                  defaultValue={!edit ? '' : mappedPixels}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 8,
                    colors: {
                      ...theme.colors,
                      primary: 'none',
                      neutral10: '#ced4da',
                      neutral80: '#18181B',
                      neutral15: '#18181B',
                      dangerLight: '#ced4da',
                      danger: '#52525B',
                    },
                  })}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: '55px',
                    }),
                  }}
                  closeMenuOnSelect={false}
                />
              </div>
              <div>
                <legend className="relative block text-sm mb-2 mt-6 uppercase after:text-red-500 after:ml-1 after:font-semibold after:content-['*']">
                  Which Klaviyo list should the data be pushed to?
                </legend>
                <SearchSelect
                  options={mappedKlaviyoInputs || klaviyoList?.map((item) => ({ value: item.id, label: item.attributes?.name }))}
                  onChange={(name, e) => handleKlaviyoInput(e)}
                  name="state"
                  isMulti
                  isSearchable
                  lazyLoad={false}
                  defaultValue={!edit ? '' : mappedKlaviyoLists}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 8,
                    colors: {
                      ...theme.colors,
                      primary: 'none',
                      neutral10: '#ced4da',
                      neutral80: '#18181B',
                      neutral15: '#18181B',
                      dangerLight: '#ced4da',
                      danger: '#52525B',
                    },
                  })}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: '55px',
                    }),
                  }}
                  closeMenuOnSelect={false}
                />
              </div>
              <div className="flex justify-between mt-5">
                <button
                  onClick={sendForm}
                  className="cursor-pointer font-sans w-[49%] mr-[2%] last:mr-0 bg-blue-500 text-white hover:bg-blue-600 hover:border-blue-600 relative block text-center font-semibold rounded px-12 py-3 ease-in-out duration-500"
                >
                  {edit ? 'Update' : 'Connect'}
                </button>
                <button
                  onClick={clearModal}
                  className="cursor-pointer font-sans text-slate-500 border-solid border border-slate-200 hover:text-white hover:border-slate-500 bg-transparent w-[49%] mr-[2%] last:mr-0 bg-zinc-900 hover:bg-black relative block text-center font-semibold rounded px-12 py-3 ease-in-out duration-500"
                >
                  Cancel
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    )
  );
};

export default ModalKlaviyo;
