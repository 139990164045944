const Style = {
  footer: 'bg-white justify-center',
  content: 'w-full max-w-screen-lg flex flex-col items-center',
  copyright: 'block opacity-70 text-center text-sm',
  address: 'block opacity-70 text-center text-sm not-italic',
  nav: 'mb-5 flex justify-center',
  link: 'mr-4 text-sm no-underline hover:underline hover:text-blue-500',
}

export default Style;
