import React, {useState, useEffect, useContext} from 'react';
import {Card, NewPaginate, Icon, Stat, Animate, Feedback, Button, ViewContext, Message, Loader} from 'components/lib';
import {setTable, setActive, setVisitors, addPixel} from 'redux/features/pixelProcessor/pixelProcessorSlice';
import Datepicker from 'react-tailwindcss-datepicker';
import {Table} from '../../gsd-components/table/table';
import {useSelector, useDispatch} from 'react-redux';
import {checkLocation} from 'helpers/checklocation';
import {useNavigate, useParams} from 'react-router-dom';
import Formatation from 'helpers/format';
import axios from 'axios';

export function PixelDashboard() {
  const [pixelName, setPixelName] = useState('');
  const table = useSelector((state) => state.pixelProcessor.table);
  const context = useContext(ViewContext);
  const baseUrlMain = checkLocation();
  const baseUrlPixel = checkLocation(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const format = new Formatation();

  // Pagination:
  const [currentPage, setCurrentPage] = useState(1);
  const [pagValues, setPagValues] = useState({
    limit: 10,
    total: 0,
  });

  // Format Table Data
  const formatData = (data) => {
    const table = [];
    data?.map((item) => {
      const row = {
        visit_date: item.updatedAt ? item.updatedAt : 'N/A',
        first_name: item.first_name ? item.first_name : 'N/A',
        last_name: item.last_name ? item.last_name : 'N/A',
        email: item.email_address_1 ? item.email_address_1 : 'N/A',
        phone: item.cell_phone_1 ? item.cell_phone_1 : 'N/A',
        referrer_Url: item.referrer ? item.referrer : 'N/A',
      };
      if (row.first_name != 'N/A' || row.last_name != 'N/A') table.push(row);
    });
    dispatch(setTable(table));
    return table;
  };

  // Get Table Data:
  const getPixel = async (dateRange) => {
    const startDate = dateRange.startDate === undefined ? '' : dateRange.startDate === null ? '' : dateRange.startDate;
    const endDate = dateRange.endDate === undefined ? '' : dateRange.endDate === null ? '' : dateRange.endDate;
    const res = await axios.get(`${baseUrlPixel}/api/pixel/${params.id}/dashboard?limit=${pagValues.limit}&page=${currentPage}&startDate=${startDate}&endDate=${endDate}`);
    const data = await res?.data;

    if (data.trovos.length === 0) {
      setPagValues({
        limit: 10,
        total: 0,
      });
    } else {
      setPagValues({
        limit: data.limit,
        total: data.totalDocs,
      });
    }
  
    const pixelData = formatData(data?.trovos);
    if (pixelData && pixelData.length > 0) {
      dispatch(addPixel(pixelData[0]));
    }

    const pixel = await axios.get(`${baseUrlPixel}/api/pixel/${params.id}`);
    setPixelName(pixel?.data?.data?.docs[0]?.website_name);
  };

  // Visitors:
  const visitors = useSelector((state) => state.pixelProcessor.visitors);
  const getVisitors = async (dateRange) => {
    try {
      const startDate = dateRange.startDate === null ? '' : dateRange.startDate;
      const endDate = dateRange.endDate === null ? '' : dateRange.endDate;
      const res = await axios.get(`${baseUrlPixel}/api/pixel/${params.id}/statistics?startDate=${startDate}&endDate=${endDate}`);
      dispatch(setVisitors(res.data));
    } catch (error) {
      console.error(error);
    }
  };

  const identifiedVisitors = visitors?.identified;
  const uniqueVisitors = visitors?.unique;
  const matchRate = uniqueVisitors != 0 ? ((identifiedVisitors/uniqueVisitors).toFixed(2) * 100) >= 100 ? 100 : ((identifiedVisitors/uniqueVisitors).toFixed(2) * 100) : 0;

  // Download CSV:
  const active = useSelector((state) => state.pixelProcessor.active);

  const downloadBtn = () => {
    if (active === true) {
      dispatch(setActive(!active));
      context.notification.show('Download started. You will receive it into your mail', 'success', true);
      axios.get(`${baseUrlPixel}/api/pixel/${params.id}/data`);
    }
  };

  const downloadStatus = () => {
    navigate('status');
    dispatch(setActive(true));
  };

  // Datepicker:
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const handleDateChange = (value) => {
    setDateRange(value);
  };

  // Search Results:
  const [searchResult, setSearchResult] = useState([]);
  const searchPixelDashboard = async () => {
    const data = await axios.get(`${baseUrlPixel}/api/pixel/${params.id}/dashboard?limit=1000`);
    setSearchResult(formatData(data.data.trovos));
  };

  // Timezone:
  const [timezone, setTimezone] = useState('CST');

  useEffect(() => {
    setCurrentPage(1);
    format.timezones(setTimezone);
  }, []);
  
  useEffect(() => {
    getVisitors(dateRange);
    getPixel(dateRange);
  }, [currentPage, dateRange]);

  return (
    <Animate type='pop'>
      {/* Title */}
      <div className='flex justify-between items-center'>
        <h2 className="m-6 text-3xl font-semibold">Manage Pixels</h2>
        <div className="float-left sm:float-right">
          {/* <div className=" m-6 flex space-x-4">
            <button
              type="button"
              onClick={active ? () => downloadBtn() : () => context.notification.show(`You can see them in the 'Files' button`, 'success', true)}
              className="inline-flex items-center rounded-md bg-blue-600 px-5 py-2.5 text-sm font-semibold text-white shadow-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            >
            <Icon image={'refresh-cw'} size={18} />
              {active ? 'Generate Pixel Data' : 'Generating...'}
            </button>
            <button
              type="button"
              onClick={() => downloadStatus()}
              className="inline-flex items-center rounded-md bg-green-600 px-5 py-2.5 text-sm font-semibold text-white shadow-md hover:bg-green-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500"
            >
            <Icon image="file-plus" size={18} />
              {'Files'}
            </button>
          </div> */}
        </div>
      </div>
      <Message
        type='info'
        title='Important:'
        text='If you see resolutions but an empty table, that means we have matched on a SHA256 level and have not matched that to Consumer Data. Please download the data.'
        closable
      />
      {/* Stats */}
      <div className='grid grid-cols-4 gap-6 m-6'>
        {
        isNaN(matchRate?.toFixed(2)) ?
          <div className='border rounded-xl p-1 shadow-md'>
            <Loader />
          </div> :
          <div className='border rounded-xl p-1 shadow-md'>
            <Stat
              value={ uniqueVisitors?.toLocaleString('en-US') || 0 }
              label='Unique Visitors'
              icon='users'
            />
          </div>
        }
        {
        isNaN(matchRate?.toFixed(2)) ?
          <div className='border rounded-xl p-1 shadow-md'>
            <Loader />
          </div> :
          <div className='border rounded-xl p-1 shadow-md'>
            <Stat
              value={ identifiedVisitors?.toLocaleString('en-US') || 0}
              label='Identified Visitors'
              icon='check'
            />
          </div>
        }

        {
        isNaN(matchRate?.toFixed(2)) ?
          <div className='border rounded-xl p-1 shadow-md'>
            <Loader />
          </div> :
          <div className='border rounded-xl p-1 shadow-md'>
            <Stat
              value={ `${matchRate?.toFixed(2)}%` || 'Loading...'}
              label='Match Rate'
              icon='refresh-cw'
            />
          </div>
        }
        <div className='border rounded-xl p-6 shadow-md'>
          <Datepicker
            value={dateRange}
            startFrom={dateRange.endDate ? new Date(dateRange.endDate) : null}
            onChange={(e) => handleDateChange(e)}
            primaryColor={'blue'}
            displayFormat={"MM/DD/YYYY"}
          />
        </div>
      </div>

      <Card>
        <NewPaginate
          offset={ currentPage -1 }
          limit={ pagValues.limit }
          total={ pagValues.total }
          next={() => setCurrentPage(currentPage + 1) }
          prev={ () => setCurrentPage(currentPage - 1) }
          onChange={ () => setCurrentPage() }
        />
        <Table
          search={searchResult}
          data={ table?.map((item) => ({...item, visit_date: `${format.timestampTable(new Date(item.visit_date))} ${timezone.replace(/_/g, ' ')}`})) }
          className='restrict-width'
          show={['visit_date', 'first_name', 'last_name', 'email', 'phone', 'referrer_Url'] }
        >
        </Table>
      </Card>
      <Feedback/>
    </Animate>
  );
}
