const checkLocation = (serverNumber) => {
  if (/staging/.test(window.location.host)) {
    if (serverNumber === 1) return "https://pixel.audiencelab.io:5511";
    else return "https://staging.audiencelab.io";
  } else if (/localhost/.test(window.location.host)) {
    if (serverNumber === 1) return "http://localhost:5500";
    else return "http://localhost:8080";
  } else {
    if (serverNumber === 1) return "https://pixel.audiencelab.io:5510";
    else return "https://app.audiencelab.io:";
  }
};


const checkStaging = () => {
  if (/staging/.test(window.location.href)) {
    return "https://staging.audiencelab.io/api/pixel";
  } else if (/localhost/.test(window.location.href)) {
    return "http://localhost:8080/api/pixel";
  } else {
    return "/api/pixel";
  }
};

export { checkLocation, checkStaging };
