import React, {Fragment, useState, useContext} from 'react';
import {Card, ViewContext, useNavigate, Importer} from 'components/lib';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {checkLocation} from 'helpers/checklocation';

export const UploaderPage = () => {
  const context = useContext(ViewContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const name = useSelector((state) => state.enrichment.name);
  const baseUrl = checkLocation();

  const columnsPairs = (headerMappings) => {
    const newPairs = [];
    if (!headerMappings) return [];
    const keys = Object.keys(headerMappings);
    keys.forEach((key) => {
      const item = headerMappings[key];
      if (item.selectedField && item.confirmed) {
        newPairs.push([item?.selectedField?.value, item?.name]);
      }
    });
    return newPairs;
  };

  const addFile = (pairs, importedFile) => {
    const formData = new FormData();
    if (importedFile) {
      formData.append('file', importedFile);
      formData.append('enrichmentName', name);
      formData.append('enrichmentColumns', JSON.stringify(pairs));
    } else {
      console.log('>> no file >>');
    }
    return formData;
  };

  const cleaner = () => {
    localStorage.setItem('name', name);
  };

  const submit = async (data) => {
    try {
      await axios.patch(`${baseUrl}/api/access/usage/enrichments`);

      const res = await axios.post(`${baseUrl}/api/enrichment`, data);
      if (res?.status === 200) context.notification.show(`${res.data.message} successfully`, 'success', true);
    } catch (err) {
      if (err?.response?.status === 413) context.notification.show('File size is too big', 'error', true);
      else context.notification.show(`${err?.response?.data?.message}`, 'error', true);
    }
    cleaner();
    navigate('/enrichment');
  };

  return (
    <Fragment>
      <div className='flex justify-between items-center'>
          <h2 className="m-6 text-3xl font-semibold">Enrichment</h2>
      </div>
      <Importer
        fields={[
          {
            key: 'LINKEDIN_URL',
            label: 'LinkedIn',
          },
          {
            key: 'FIRST_NAME',
            label: 'First Name',
          },
          {
            key: 'LAST_NAME',
            label: 'Last Name',
          },
          {
            key: 'EMAIL',
            label: 'Email',
          },
          {
            key: 'PHONE',
            label: 'Phone',
          },
          {
            key: 'PERSONAL_CITY',
            label: 'City',
          },
          {
            key: 'COMPANY_NAME',
            label: 'Company',
          },
          {
            key: 'COMPANY_DOMAIN',
            label: 'Company Domain',
          },
        ]}
        onComplete={async (data, headerMappings, file) => {
          setLoading(true);
          if (data.length) {
            // convert data that is array of objects to string and create a csv file
            try {
              const pairs = columnsPairs(headerMappings);
              const formData = addFile(pairs, file);
              await submit(formData);
            } catch (error) {
              console.log(error);
            }
          }
          setLoading(false);
        }}
      />
    </Fragment>
  );
};
