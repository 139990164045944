/** *
 *
 *   USERS
 *   Enables an admin to manage the users in their application
 *
 **********/
import React, { Fragment, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import {
  ViewContext,
  Card,
  Animate,
  usePermissions,
  Event,
  Icon
} from 'components/lib';
import { checkLocation } from 'helpers/checklocation';
import { Table } from 'gsd-components/table/table';

  const Users = () => {
  const context = useContext(ViewContext);
  const permissions = usePermissions();
  const baseUrl = checkLocation();
  const [users, setUsers] = useState([]);
  let list = [];

  const reicidentEmails = (email) => {
    const repeatingPattern = /\+[0-9]+/;
    if (repeatingPattern.test(email)) {
      const modifiedEmail = email.replace(/^(.+(?=\+))\+\d+(@.*)$/, '$1$2');
      return modifiedEmail;
    } else {
      return email;
    }
  };

  const getUsers = async () => {
    const res = await axios.get(`${baseUrl}/api/account/users`);
    const data = res.data;
    if (data?.data?.users?.length) {
      list = data.data.users.map((x) => {
        return {
          id: x.id,
          name: x.name,
          email: reicidentEmails(x.email),
          date_created: x.date_created,
          permission: x.permission,
          status: 'Registered',
        };
      });
    }

    if (data?.data?.invites?.length) {
      data.data.invites.forEach((x) => {
        list.push({
          id: x.id,
          name: '',
          email: x.email,
          date_created: x.date_sent,
          permission: x.permission || 'user',
          status: 'Invited',
        });
      });
    }

    setUsers(list);
  };

  const invite = async () => {
    try {
      const inviteRes = await axios.get(`${baseUrl}/api/account/users`);
      const inviteData = inviteRes.data;
      const inviteNumber = inviteData?.data?.invites?.length;

      const userString = localStorage.getItem('user');
      const user = userString ? JSON.parse(userString) : null;
      const accountId = user ? user.account_id : null;
      const quota = await axios.get(`${baseUrl}/api/access/quota/users`,);

      const quotaNumber = quota.data.quota;
      const invitePermit = inviteNumber < quotaNumber || inviteNumber == undefined;

      if (quota.data.permit) {
        if (invitePermit) {
          context.modal.show(
              {
                title: 'Add User',
                form: {
                  email: {
                    label: 'Email',
                    type: 'text',
                    required: true,
                  },
                  permission: {
                    label: 'Permission',
                    type: 'select',
                    default: 'user',
                    options: permissions?.data?.list?.filter(
                        (x) => x.value !== 'owner',
                    ),
                  },
                },
                buttonText: 'Send Invite',
                text: 'To invite more than one user, seperate the emails with a comma',
                url: '/api/invite',
                method: 'POST',
              },
              (form, res) => {
              // add the invited user to the
                if (res.length) {
                  const state = [...users];

                  res.forEach((invite) => {
                    if (!state.find((x) => x.id === invite.id)) {
                      state.push({
                        id: invite.id,
                        name: '',
                        email: invite.email,
                        date_created: invite.date_sent,
                        permission: invite.permission || 'user',
                        status: 'Invited',
                        actions: {
                          invite: resendInvite,
                          delete: deleteInvite,
                        },
                      });
                    }
                  });

                  Event('invited_user');
                  setUsers(state);
                }
              },
          );
        } else {
          context.notification.show(
              'Please delete pending invites or contact support for more users',
              'error',
              true,
          );
        }
      } else {
        context.notification.show(
            'Please contact support for more users',
            'error',
            true,
        );
      }
    } catch (err) {
      context.handleError(err);
    }
  };

  const editUser = (data, callback) => {
    context.modal.show(
        {
          title: 'Update User',
          form: {
            id: {
              type: 'hidden',
              value: data.id,
            },
            name: {
              label: 'Name',
              type: 'text',
              required: true,
              value: data.name,
              errorMessage: 'Please enter a name',
            },
            email: {
              label: 'Email',
              type: 'email',
              value: data.email,
              required: true,
            },
            permission: {
              label: 'Permission',
              type: data.permission === 'owner' ? null : 'select',
              options: permissions?.data?.list?.filter(
                  (x) => x.value !== 'owner',
              ),
              default: data.permission,
            },
          },
          buttonText: 'Save',
          url: '/api/user',
          method: 'PATCH',
        },
        (res) => {
          context.notification.show(data.name + ' was updated', 'success', true);
          callback(res);
        },
    );
  };

  const deleteUser = (data, callback) => {
    console.log(data);
    context.modal.show(
        {
          title: 'Delete User',
          form: {},
          buttonText: 'Delete User',
          text: `Are you sure you want to delete ${data.name}?`,
          url: `/api/user/${data.id}`,
          method: 'DELETE',
          destructive: true,
        },
        () => {
          const state = [...users];
          const filtered = state.filter((x) => x.id !== data.id);
          setUsers(filtered);
          context.notification.show(data.name + ' was deleted', 'success', true);
          callback();
        },
    );
  };

  const deleteInvite = (data, callback) => {
    context.modal.show(
        {
          title: 'Delete Invite',
          form: {},
          buttonText: 'Delete Invite',
          text: `Are you sure you want to delete the invite for ${data.email}?`,
          url: `/api/invite/${data.id}`,
          method: 'DELETE',
          destructive: true,
        },
        () => {
          context.notification.show(
              `${data.email}'s invite was deleted`,
              'success',
              true,
          );
          const state = [...users];
          const filtered = state.filter((x) => x.id !== data.id);
          setUsers(filtered);
          callback();
        },
    );
  };

  async function resendInvite(data) {
    try {
      await axios({
        url: '/api/invite',
        method: 'post',
        data: {email: data.email},
      });

      context.notification.show(
          `Invite re-sent to ${data.email}`,
          'success',
          true,
      );
    } catch (err) {
      context.handleError(err);
    }
  }

  useEffect(() => {
    getUsers();
  }, []);

  // attach the per row actions for invites
  if (users.length) {
    users.forEach((u) => {
      if (u.status === 'Invited') {
        u.actions = {
          invite: resendInvite,
          delete: deleteInvite,
        };
      }
    });
  }

  return (
    <Fragment>
      <Animate>
        <div className='m-6 flex justify-between items-center'>
          <h2 className="text-3xl font-semibold">Users</h2>
          <button
              type="button"
              onClick={invite}
              className="inline-flex items-center rounded-md bg-blue-600 px-5 py-2.5 text-sm font-semibold text-white shadow-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          >
          <Icon image="plus-square" size={18} />
              Add User
          </button>
        </div>
        <Card>
          <Table
            search
            className="restrict-width"
            data={users || []}
            show={[
              'email',
              'name',
              'date_created',
              'status',
            ]}
            badge={
              {
                col: 'status',
                color: 'blue',
                condition: [
                  {
                    value: 'Registered',
                    label: 'REGISTERED',
                    color: 'green',
                    tooltip: 'User is registered'
                  },
                  {
                    value: 'Invited',
                    label: 'INVITED',
                    color: 'blue',
                    tooltip: 'User has been invited' 
                  },
                ],
              }            
            }
            actions={
              [
                {
                  icon: 'edit',
                  onClick: [editUser],
                  tooltip: ['Edit User'],
                },
                {
                  icon: 'trash-2',
                  onClick: [deleteUser],
                  tooltip: ['Delete User'],
                },
                {
                  icon: 'mail',
                  onClick: [resendInvite],
                  tooltip: ['Email User'],
                },
              ]
            }
          />
        </Card>
      </Animate>
    </Fragment>
  );
}

export default Users;
