const Style = {

  input: `float-left relative w-6 h-6 cursor-pointer mr-2
  border border-solid border-slate-200 bg-center appearance-none
  rounded-full checked:after:absolute checked:after:top-1/2 
  checked:after:left-1/2 checked:after:w-3 checked:after:h-3
  checked:after:rounded-full checked:after:bg-[#2563eb] 
  checked:after:-translate-y-1/2 checked:after:-translate-x-1/2 
  checked:bg-[#2563eb] focus:ring-offset focus:ring-5 focus:ring-offset-transparent-75`,
  
  label: 'cursor-pointer mt-8 normal-case md:relative md:bottom-8 md:left-9',  radio: 'pt-2 pl-2'
}

export default Style;