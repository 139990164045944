import React, { useState, useEffect } from 'react';
import { SearchSelect } from 'components/lib';
import axios from 'axios';

const ModalIntegrate = ({ platform, baseUrl, closeModal, clearModal, setShowNoIntegrationWarning, showNoIntegrationWarning, integrateInput, setIntegrateInput, isNext, openModal, errorNotification }) => {
  const [options, setOptions] = useState([]);

  const handleIntegrateInput = (e) => {
    setShowNoIntegrationWarning(false);
    setIntegrateInput(e);
  }

  const handleNextModal = (e) => {
    e.preventDefault();
    if (!integrateInput) {
      setShowNoIntegrationWarning(true);
    } else {
      clearModal();
      setShowNoIntegrationWarning(false);
      openModal(platform.type, integrateInput.platform, integrateInput.name);
    }
  }

  const getList = async () => {
    try {
      const res = await axios.get(`${baseUrl}/api/integrationAccount`);
      const klaviyoOptions = res.data.klaviyo.map(({ name, _id }) => ({
        label: `Klaviyo - ${name}`,
        value: _id,
        name,
        platform: 'Klaviyo'
      }));

      const goHighLevelOptions = res.data.goHighLevel.map(({ name, _id }) => ({
        label: `GoHighLevel - ${name}`,
        value: _id,
        name,
        platform: 'goHighLevel'
      }));

      const allOptions = [...klaviyoOptions, ...goHighLevelOptions];
      setOptions(allOptions);

    } catch (error) {
      errorNotification('Integrate', error.response.data.message);
    }
  }

  useEffect(() => {
    if (platform.type === 'Integrate') {
      getList();
    }
  }, [platform]);

  return (
    (platform.type === 'Integrate' && isNext === false && platform.platform === undefined) && (
      <div className="modal modal-appear-done modal-enter-done">
        <div className="modal-content max-h-[80vh] overflow-y-auto">
          <section className="relative p-6 x-1 mb-6 bg-white rounded">
            <header className="relative pb-5 mb-5 border-dotted border-slate-100 border-b block text-sm uppercase">
              <h1 className="float:left m-0 text-lg font-semibold">
                {`Select Account`}
              </h1>
            </header>
            <form className="">
              <input type="hidden" id="name" name="name" value="4d024eab-7d7c-45e5-8f8c-9d444c4b42ab" />
              <div className="relative clear-both border-0 p-0 mb-0">
                <legend className="relative block text-sm mb-2 uppercase after:text-red-500 after:ml-1 after:font-semibold after:content-['*']">
                  Which account do you want to use?
                </legend>
                <legend className="relative block text-xs mb-2 after:ml-1 after:font-semibold ">
                  Select only one account at a time or connect to one if you don't have any
                </legend>
                <SearchSelect
                  options={options}
                  onChange={(name, e) => handleIntegrateInput(e)}
                  name='state'
                  isSearchable
                  lazyLoad={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 8,
                    colors: {
                      ...theme.colors,
                      primary: 'none',
                      neutral10: '#ced4da',
                      neutral80: '#18181B',
                      neutral15: '#18181B',
                      dangerLight: '#ced4da',
                      danger: '#52525B',
                    },
                  })}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: '55px',
                    }),
                  }}
                  closeMenuOnSelect={true}
                />
                {showNoIntegrationWarning && <div className="text-red-500">Please select an Integration Account</div>}
              </div>
              <div className="flex justify-between mt-5">
                <button onClick={handleNextModal} className="cursor-pointer font-sans w-[49%] mr-[2%] last:mr-0 bg-blue-500 text-white hover:bg-blue-600 hover:border-blue-600 relative block text-center font-semibold rounded px-12 py-3 ease-in-out duration-500">{'Next'}</button>
                <button onClick={clearModal} className="cursor-pointer font-sans text-slate-500 border-solid border border-slate-200 hover:text-white hover:border-slate-500 bg-transparent w-[49%] mr-[2%] last:mr-0 bg-zinc-900 hover:bg-black relative block text-center font-semibold rounded px-12 py-3 ease-in-out duration-500">Cancel</button>
              </div>
            </form>
          </section>
        </div>
      </div>
    )
  )
};

export default ModalIntegrate;
