import React, { useState, Fragment, useEffect, useContext } from 'react';
import { Card, Icon, ViewContext, NewPaginate, Table } from 'components/lib';
import {
  updateForm,
  clearForm,
  setList,
  setForm,
  setCurrentBuilderPage,
  setPagValues,
} from 'redux/features/businessBuilder/businessBuilderSlice';
import Style from './b2b.tailwind';
import department from './options/department';
import employeeCount from './options/employee_count';
import industry from './options/industry';
import revenue from './options/revenue';
import seniority from './options/seniority';
import personalState from './options/personal_state';
import personalCity from './options/personal_city';
import sic from './options/sic';
import naics from './options/naics';
import { BusinessText } from './inputs/b2b_text_input';
import { BusinessSelect } from './inputs/b2b_select_input';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { checkLocation } from 'helpers/checklocation';
import axios from 'axios';
import uuid4 from 'uuid4';

export const B2bBuilder = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const baseUrl = checkLocation();
  const context = useContext(ViewContext);
  const [currentTab, setCurrentTab] = useState('Contacts');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [b2bItem, setb2bItem] = useState({});
  const [formId, setFormId] = useState('');
  const currentBuilderPage = useSelector((state) => state.businessBuilder.currentBuilderPage);
  const pagValues = useSelector((state) => state.businessBuilder.pagValues);
  const formState = useSelector((state) => state.businessBuilder.businessBuilderForm);
  const b2bList = useSelector((state) => state.businessBuilder.businessList);

  // HANDLERS
  const handleTabSwitch = (tab) => {
    setCurrentTab(tab);
  };

  const handleClearForm = async (e) => {
    dispatch(clearForm());
  };

  const handleBusinessSelectChange = (label, value) => {
    dispatch(
      updateForm({
        label: label?.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s/g, '_'),
        value,
        form: currentTab.toLowerCase(),
      })
    );
  };

  const inputSelect = (label, json) => {
    const inputs = formState[currentTab.toLowerCase()][label.toLowerCase().replace(/\s/g, '_')];
    return (
      <span>
        <label className="relative block text-sm mb-2 uppercase">{label}</label>
        <BusinessSelect
          options={json.map((field) => ({ value: field.label, label: field.label }))}
          onChange={handleBusinessSelectChange}
          isMulti
          isSearchable
          lazyLoad={false}
          name={label}
          defaultValue={inputs?.map((item) => ({ value: item, label: item }))}
          theme={(theme) => ({
            ...theme,
            borderRadius: 8,
            colors: {
              ...theme.colors,
              primary: 'none',
              neutral10: '#ced4da',
              neutral80: '#18181B',
              neutral15: '#18181B',
              dangerLight: '#ced4da',
              danger: '#52525B',
            },
          })}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              minHeight: '42px',
            }),
          }}
          closeMenuOnSelect={false}
        />
      </span>
    );
  };

  const inputText = (label) => {
    const inputs = formState[currentTab.toLowerCase()][label.toLowerCase().replace(' ', '_')];
    return (
      <span>
        <label className="relative block text-sm mb-2 uppercase">{label}</label>
        <BusinessText label={label} form={currentTab} inputs={inputs} />
      </span>
    );
  };

  // BUTTONS
  const searchButton = () => {
    const notAllowed = ['COMPLETE'];
    return (
      <button
        disabled={loading} // Disable when loading
        className={`${!notAllowed?.includes(b2bItem?.status) && !loading ? 'cursor-pointer bg-blue-600 hover:bg-blue-700 hover:border-blue-700' : 'bg-gray-500 pointer-events-none'} font-sans text-sm px-7 py-2.5 float-right mr-[2%] last:mr-0 text-white  relative block text-center font-semibold rounded-md ease-in-out duration-500`}
        onClick={sendQuery}
      >
        {loading ? 'Searching...' : 'Search'}
      </button>
    );
  };

  const clearButton = () => {
    return (
      <button
        className="cursor-pointer font-sans hover:text-red text-sm float-right mr-[2%] last:mr-0 hover:text-white border text-zinc-500 hover:border-white hover:bg-red-400 bg-transparent relative block text-center font-semibold rounded-md px-7 py-2.5 ease-in-out duration-500"
        onClick={handleClearForm}
      >
        Clear All
      </button>
    );
  };

  const downloadButton = () => {
    return (
      <button
        onClick={downloadB2bSearch}
        className={`${b2bItem?.fileDetailsList?.length > 0 ? 'bg-green-300' : 'bg-white pointer-events-none'} ${Style.default} text-slate-500 border rounded-md flex space-x-1 items-center `}
      >
        <Icon image="download" color="dark" size={16} />
        <span>Download</span>
      </button>
    );
  };

  const generateB2bSearchDataButton = () => {
    return (
      <button
        onClick={generateB2bSearchData}
        className={`${
          b2bItem?.fileDetailsList?.length === 0 && !generating && b2bItem?.status !== 'CREATED' && b2bItem?.status !== 'IN QUEUE'
            ? 'bg-blue-600'
            : generating
            ? 'bg-red-400 pointer-events-none'
            : 'bg-blue-600 pointer-events-none'
        } ${Style.default} text-white flex space-x-1 items-center `}
      >
        <Icon image="refresh-ccw" color="white" size={16} />
        <span>{generating ? 'Processing...' : 'Generate Data'}</span>
      </button>
    );
  };

  // PAGINATION & RESULTS
  const paginationFrontend = (limit, currentBuilderPage, item) => {
    if (item) {
      const data = item?.visualData || item?.formStateList[item?.formStateList?.length - 1]?.visualData;
      const startIndex = (currentBuilderPage - 1) * limit;
      const endIndex = startIndex + limit;

      const paginatedData = data?.slice(startIndex, endIndex);
      setResults(paginatedData);
    } else {
      dispatch(setCurrentBuilderPage(1));
    }
  };

  const showResults = (loading, results, b2bItem) => {
    if (loading) {
      return (
        <div className="h-screen w-screen z-50 flex justify-center items-center">
          <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        </div>
      );
    }

    switch (b2bItem?.status) {
      case 'COMPLETE':
        return results?.length ? (
          <Fragment>
            <span className="relative block text-sm mb-2 uppercase">Count {results.length}</span>
            <Table data={results} show={['FIRST_NAME', 'LAST_NAME', 'COMPANY_NAME', 'LINKEDIN_URL']} />
          </Fragment>
        ) : (
          <div>No results available</div>
        );
      case 'NO_DATA':
        return <div>No data found. Please modify your search.</div>;
      case 'PROCESSING':
      case 'IN QUEUE':
        return (
          <div className="h-screen w-screen z-50 flex justify-center items-center">
            <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
            </div>
          </div>
        );
      default:
        return <div>Perform a search to see results.</div>;
    }
  };

  // API CALLS
  const updateInputs = async (e, id) => {
    e.preventDefault();
    await axios.patch(`${baseUrl}/api/b2bSearch/${params.id}/updateFormState/${id}`, { contacts: formState.contacts, companies: formState.companies });
  };

  const sendQuery = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state
    try {
      await updateInputs(e, formId);
      await axios.get(`${baseUrl}/api/b2bSearch/${params.id}/generateSearch/${formId}?download=false`);
      context.notification.show('search started processing', 'success', true);

      let retries = 0;
      const interval = setInterval(async () => {
        retries += 1;
        const result = await getResults();
        if (result && result.status !== 'CREATED' && result.status !== 'IN QUEUE' && result.status !== 'PROCESSING') {
          setb2bItem(result);
          clearInterval(interval);
          setLoading(false); // Remove loading state
        }
        // if (retries > 10) {
        //   clearInterval(interval);
        //   setLoading(false);
        // }
      }, 3000);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const downloadB2bSearch = async () => {
    try {
      const res = await axios.get(`${baseUrl}/api/b2bSearch/${params.id}/`);
      const item = res?.data?.data?.docs[0];
      const status = item.status;
      const file = item.fileDetailsList[item.fileDetailsList?.length - 1]?.name;
      if (file && status !== 'NO_DATA') {
        const CSV = 'https://audiencelab-builder.s3.amazonaws.com/' + file;
        window.open(CSV, '_blank').focus();
      } else {
        context.notification.show('No data found', 'error', true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const generateB2bSearchData = async (e) => {
    try {
      e.preventDefault();
      await axios.get(`${baseUrl}/api/b2bSearch/${params.id}/generateSearch/${formId}?download=true`);
      context.notification.show('started generating file', 'success', true);
      setGenerating(true);
      const interval = setInterval(async () => {
        setLoading(false);
        try {
          const result = await getResults();
          if (result && result?.status !== 'CREATED' && result?.status !== 'IN QUEUE' && result?.status !== 'PROCESSING') {
            setb2bItem(result);
            clearInterval(interval);
            setGenerating(false);
          }
        } catch (resultError) {
          console.error('An error occurred while getting results:', resultError);
          clearInterval(interval);
        }
      }, 3000);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const getResults = async () => {
    try {
      const res = await axios.get(`${baseUrl}/api/b2bSearch/${params.id}/`);
      const item = res?.data?.data?.docs[0];
      const formStateData = item?.formStateList[item?.formStateList?.length - 1]?.visualData?.length;
      const visualData = item?.visualData?.length;
      paginationFrontend(25, currentBuilderPage, item);

      if (formStateData > 0 || visualData > 0 && !pagValues.total) {
        dispatch(setPagValues({ total: formStateData || visualData || 1, limit: 25 }));
      }
      setb2bItem(item);
      return item;
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  };

  const getB2bList = async () => {
    try {
      const res = await axios.get(`${baseUrl}/api/b2bSearch?limit=100`);
      const docs = res.data.data.docs;
      dispatch(setList(docs));
      const item = docs?.find((item) => item.id === params.id);
      if (item) {
        setb2bItem(item);
        if (item?.formStateList && item.formStateList?.length) {
          setFormId(item?.formStateList[item?.formStateList?.length - 1]?.id);
          dispatch(
            setForm({
              id: item?.formStateList[item?.formStateList?.length - 1]?.id,
              contacts: item?.formStateList[item?.formStateList?.length - 1]?.formState?.contacts,
              companies: item?.formStateList[item?.formStateList?.length - 1]?.formState?.companies,
            })
          );
        }
      }
      if (item?.status === 'COMPLETE') {
        dispatch(setPagValues({ total: item?.formStateList[item?.formStateList?.length - 1]?.visualData?.length || item?.visualData?.length || 1, limit: 25 }));
      } else {
        dispatch(setPagValues({}));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(setCurrentBuilderPage(1));
  }, []);

  useEffect(() => {
    let interval;

    const fetchData = async () => {
      if (!b2bList.length) {
        await getB2bList();
      }
      if (b2bList.length) {
        const item = b2bList?.find((item) => item.id === params.id);
        const id = item?.formStateList[item?.formStateList?.length - 1]?.id;
        setb2bItem(item);
        paginationFrontend(25, currentBuilderPage, item);
        if (id) {
          setFormId(id);
        } else {
          setFormId(uuid4());
        }

        if (item?.status === 'PROCESSING' || item?.status === 'IN QUEUE') {
          interval = setInterval(async () => {
            const result = await getResults();
            if (
              result &&
              result?.status !== 'CREATED' &&
              result?.status !== 'PROCESSING' &&
              result?.status !== 'IN QUEUE'
            ) {
              setb2bItem(result);
              clearInterval(interval);
              setLoading(false);
            }
          }, 3000);
        } else {
          setLoading(false);
          getResults();
        }
      }
    };

    fetchData();

    return () => {
      clearInterval(interval);
    };
  }, [b2bList, currentBuilderPage, params.id]);

  useEffect(() => {
    if (formId && updateForm && formState) {
      const mockEvent = { preventDefault: () => {} };
      updateInputs(mockEvent, formId);
    }
  }, [updateForm, formState]);

  return (
    <Fragment>
      {/* Header */}
      <section className="overflow-auto">
        {/* Buttons - Generate Data and Download */}
        <div className="m-6 flex justify-between items-center">
          <h2 className="text-3xl font-semibold">Business</h2>
          <div className="float-right flex space-x-5">
            {generateB2bSearchDataButton()}
            {downloadButton()}
          </div>
        </div>
        {/* Tabs */}
        <div className="m-6 float-left text-lg w-1/3">
          <div className="flex space-x-2">
            <button
              onClick={() => handleTabSwitch('Contacts')}
              className={`${Style.default} ${currentTab === 'Contacts' ? Style.selected : Style.not_selected} w-full border rounded-md`}
            >
              Contacts
            </button>
            <button
              onClick={() => handleTabSwitch('Companies')}
              className={`${Style.default} ${currentTab === 'Companies' ? Style.selected : Style.not_selected} w-full border rounded-md`}
            >
              Companies
            </button>
          </div>
        </div>
      </section>

      {/* Forms */}
      <section className="h-full">
        <div className="flex flex-col lg:flex-row lg:w-full lg:space-x-4">
          {/* Contacts */}
          {currentTab === 'Contacts' && (
            <Card className="flex-auto w-full lg:w-1/3 h-full">
              {b2bList.length > 0 && (
                <form>
                  {inputText('First Name')}
                  {inputText('Last Name')}
                  {inputText('Job Title')}
                  {inputSelect('Seniority', seniority)}
                  {inputSelect('Department', department)}
                  {inputSelect('Personal State', personalState)}
                  {inputSelect('Personal City', personalCity)}
                  {inputText('Company Name')}
                  {inputText('Company Domain')}
                  {inputSelect('Industry', industry)}
                  {inputSelect('SIC', sic)}
                  {inputSelect('NAICS', naics)}
                  {inputSelect('Employee Count', employeeCount)}
                  {inputSelect('Est Company Revenue', revenue)}
                  <div className="flex justify-between items-center">
                    {clearButton()}
                    {searchButton()}
                  </div>
                </form>
              )}
            </Card>
          )}
          {/* Companies */}
          {currentTab === 'Companies' && (
            <Card className="flex-auto w-full lg:w-1/3 h-auto">
              {b2bList.length > 0 && (
                <form>
                  {inputSelect('Industry', industry)}
                  {inputSelect('SIC', sic)}
                  {inputSelect('NAICS', naics)}
                  {inputSelect('Est. Company Revenue', revenue)}
                  {inputSelect('Employee Count', employeeCount)}
                  <div className="flex justify-between items-center">
                    {clearButton()}
                    {searchButton()}
                  </div>
                </form>
              )}
            </Card>
          )}
          {/* Search Results */}
          <Card className="flex-auto w-full lg:w-3/5">
            <NewPaginate
              offset={currentBuilderPage - 1}
              limit={pagValues.limit}
              total={pagValues.total}
              currentPage={currentBuilderPage}
              next={() => dispatch(setCurrentBuilderPage(currentBuilderPage + 1))}
              prev={() => dispatch(setCurrentBuilderPage(currentBuilderPage - 1))}
              onChange={() => dispatch(setCurrentBuilderPage())}
            />
            {showResults(loading, results, b2bItem)}
          </Card>
        </div>
      </section>
    </Fragment>
  );
};