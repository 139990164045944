/* eslint-disable no-unused-expressions */
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { setAudience, addAudience as addAudienceRedux, deleteAudience as deleteAudienceRedux, setForm, removeForm, updateList} from 'redux/features/audienceBuilder/audienceBuilderSlice';
import { resetCurrentStep } from 'redux/features/audienceWizard/audienceWizardSlice';
import { Card, ViewContext, useNavigate, Message, NewPaginate, Animate, Icon } from 'components/lib';
import { Table} from '../../gsd-components/table/table';
import { addTypeCheck } from 'helpers/checkPermissionType';
import { checkLocation } from 'helpers/checklocation';
import { useLocation } from 'react-router-dom';
import { setTargets, updateForm } from 'redux/features/audienceBuilder/audienceBuilderSlice';
import { checkDate } from 'helpers/checkDate';
import Formatation from 'helpers/format';
import uuid4 from 'uuid4';
import axios from 'axios';
import AudienceQuota from 'helpers/quotaAudience';
import { useFacebook } from 'gsd-components/facebook/hook';

export const Audience = () => {
  const format = new Formatation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baseUrl = checkLocation();
  const baseUrlPixel = checkLocation(1);
  const context = useContext(ViewContext);
  const [loading, setLoading] = useState(true);
  let audienceList = useSelector((state) => state.audienceBuilder.audienceList);
  const [searchResult, setSearchResult] = useState([]);
  const [optionAccess, setOptionAccess] = useState([]);
  const [isNotOld, setIsNotOld] = useState({});
  const [filteredAudienceList, setFilteredAudienceList] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [pagValues, setPagValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [accessResponse, setAccessResponse] = useState({});
  const location = useLocation();

  const getAudienceTypes = () => {
    const baseTypes = [];
  
    if (accessResponse.data.audiences.permit) {
      baseTypes.push({ label: 'Premade', value: 'premade' });
      baseTypes.push({ label: 'Keyword', value: 'keyword' });
    }
  
    if (accessResponse.data.custom_audiences.permit) {
      baseTypes.push({ label: 'Custom', value: 'custom' });
    }
  
    return baseTypes;
  };

  const { 
    showSyncModal, 
    setShowSyncModal,
    isSyncSuccess, 
    SyncAudienceModal,
    FacebookSuccessModal,
    facebookIntegrationAccess,
    setFacebookAudienceData,
    hasLoggedCondition,
    FacebookLoginModal
  } = useFacebook();

  // Redux
  const reduxAdd = (data) => {
    dispatch(addAudienceRedux({
      name: data.name.value,
      created_on: Date.now(),
      status: 'CREATED',
    }));
  };

  const reduxSet = (data) => {
    dispatch(setAudience(addTypeCheck(data)));
  };

  const reduxUpdate = async (data) => {
    const parentForm = await axios.get(`${baseUrl}/api/audience/index/account`);
    const filtered = parentForm.data.data.docs?.filter((f) => f.id === data.id);
    const formState = filtered[0]?.formState;
    localStorage.setItem('audienceType', data?.buildType);
    dispatch(resetCurrentStep());
    if (formState) {
      dispatch(setForm(formState));
    }
    navigate(`/audience/wizard/${data.newId || data.id}`);
  };

  const getAccess = async () => {
    try {
      const res = await axios.get(`${baseUrl}/api/updatedAudienceUsage/`);
      setAccessResponse(res.data)
      return accessResponse
    } catch (error) {
      console.error('Failed to fetch quota:', error);
    }
  }

  const getPremadeQuota = async () => {
    try {
      const quotaResponse = await axios.get(`${baseUrl}/api/access/quota/audiences`);
      
      return quotaResponse.data;
    } catch (error) {
      console.error('Failed to fetch quota:', error);
    }
  }

  const getCustomQuota = async () => {
    try {
      const quotaResponse = await axios.get(`${baseUrl}/api/access/quota/custom_audiences`);
      
      return quotaResponse.data;
    } catch (error) {
      console.error('Failed to fetch quota:', error);
    }
  }

  // Audience Functions
  const getAudience = async () => {
    try {
      const list = await axios.get(`${baseUrl}/api/audience/${currentPage}`);
      const data = await list.data.data;
      reduxSet(data.docs);
      setPagValues({total: data.totalDocs, limit: data.limit});
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 403) {
      context.notification.show('Please contact support for Audience and Keywords access', 'error', true)
      }
    }
  };

  const handleDateCheck = (date) => {
    const result = checkDate(date);
    dispatch(setTargets(result));
    
  };

  const addAudience = async () => {
    try {
      handleDateCheck();
      const quota = await getPremadeQuota();
      const customQuota = await getCustomQuota();
  
      const permit = quota.permit;
      const customPermit = customQuota.permit;
  
      const id = uuid4();
      context.modal.show(
        {
          title: 'Add Audience',
          form: {
            id: { label: 'ID', type: 'hidden', value: id },
            name: { label: 'Name', type: 'text', required: true },
            buildType: {
              label: 'Audience Type',
              type: 'radio',
              options: getAudienceTypes(),
              required: true,
            },
          },
          buttonText: 'Save',
          url: `/api/audience/`,
          method: 'POST',
        },
        async (data) => {

          
          if (/[^a-z ]/gi.test(data.name.value)) {
            context.notification.show(
              'Audience names cannot contain special signs or numbers',
              'error',
              true
            );
            return;
          }
  
          const { value } = data.buildType;
  
          const hasPermit = (type) => {
            if (type === 'custom' && customPermit) {
              axios.patch(`${baseUrl}/api/access/usage/custom_audiences`);
              return true;

            } else if ((type === 'premade' || type === 'keyword') && permit) {
              axios.patch(`${baseUrl}/api/access/usage/audiences`);

              return true;
            } else {
              return false;
            }
          };
          
  
          if (hasPermit(value)) {
            dispatch(removeForm());
            reduxAdd(data);
            localStorage.setItem('audienceType', value);
            navigate(`/audience/wizard/${id}`);
          } else {
            context.notification.show(
              'Please contact support for more access',
              'error',
              true
            );
            return;
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAudience = (data) => {
    context.modal.show({
      title: 'Delete Audience',
      text: `Are you sure you want to delete ${data.name}?`,
      form: {},
      buttonText: 'Delete',
      url: `/api/audience/${data.id}`,
      method: 'DELETE',
      destructive: true,
    }, () => {
      context.notification.show('deleted successfully', 'success', true);
      dispatch(deleteAudienceRedux(data.id));
    });
  };

  const deleteSelected = () => {
    context.modal.show({
      title: 'Delete Audience(s)',
      text: `Are you sure you want to delete ${selectedItem.length} audience(s)?`,
      form: {},
      buttonText: 'Delete',
      method: 'POST',
      url: '/api/audience/callback',
      destructive: true,
    },
    () => {
      selectedItem.forEach((data) => {
        axios.delete(`/api/audience/${data.id}`).then(() => {
          dispatch(deleteAudienceRedux(data.id));
        });
      });
      setSelectedItem([]);
      context.notification.show(' deleted successfully', 'success', true);
    });
  };

  const duplicateAudience = async (data) => {
    try {
      const quota = await axios.get(`${baseUrl}/api/access/quota/audiences`);
      const permit = quota.data.permit;

      if (permit) {
        const id = uuid4();
        context.modal.show({
          title: 'Duplicate Audience',
          form: {
            id: { label: 'ID', type: 'hidden', value: id },
            name: { label: 'Name', type: 'text', value: `Copy of ${data.name}` },
            buildType: { label: 'Audience Type', type: 'hidden', value: data.buildType },
          },
          buttonText: 'Save',
          url: `/api/audience/`,
          method: 'POST',
        },
        async (res) => {
          context.notification.show('added successfully', 'success', true);
          reduxUpdate({ id: data.id, newId: res.id.value, buildType: res.buildType.value });
          reduxAdd(data);


        });
      } else {
        context.notification.show(`Please contact support for Audience refresh credits`, 'error', true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const editAudience = async (data) => {
    let type = data.buildType;
    if (type === 'custom') {
      type = 'premade';
    }
    try {
      const response = await axios.get(`${baseUrlPixel}/api/dataAvailability/${type}Audience`);
      const { earliestDate, latestDate } = response.data;
      const startDate = new Date(earliestDate).toISOString().split('T')[0];
      const endDate = new Date(latestDate).toISOString().split('T')[0];
      console.log(startDate, endDate);
      dispatch(updateForm({
        fieldName: 'dateRangeLimit',
        value: {minDate: startDate, maxDate: endDate},
      }));
    } catch (error) {
      console.error(error);
      context.notification.show('Please select a date prior to a maximum of 7 days from today', 'warning', true);
    }
    handleDateCheck(data.createdAt);
    reduxUpdate(data);
  };

  const getMostRecentDate = async (audienceType) => {
    if (audienceType === 'custom') {
      audienceType = 'premade'
    }
    const res = await axios.get(`${baseUrlPixel}/api/dataAvailability/${audienceType}Audience`);    
    let startDate = new Date(res.data.earliestDate);
    // serialize start date
    startDate = startDate.toISOString().split('T')[0];

    let endDate = new Date(res.data.latestDate);
    // serialize end date
    endDate = endDate.toISOString().split('T')[0];
    return { startDate, endDate };
  };

  const getCustom = async () => {
    const res = await axios.get(`${baseUrl}/api/custom-audience/topics`);
    dispatch(updateList(
      res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.topic_id,
          desc: item.topic_desc
        };
      }),
    ));
  };

  const refreshAudience = async (data) => {
    try {
      const userString = localStorage.getItem('user');
      const user = userString ? JSON.parse(userString) : null;
      const accountId = user ? user.account_id : null;
      const quota = await axios.get(`${baseUrl}/api/access/quota/audiences?mode=refresh`);
      const permit = quota.data.permit;

      if (permit) {
        // Update badge
        const changedStatusArray = audienceList?.filter((f) => f.id === data.id).map((m) => ({ ...m, status: 'IN QUEUE' }));
        const filteredArray = audienceList?.filter((f) => f.id !== data.id);
        reduxSet([...changedStatusArray, ...filteredArray]);
        // Get form state
        const parentForm = await axios.get(`${baseUrl}/api/audience/index/account`);
        const filtered = parentForm.data.data.docs?.filter((f) => f.id === data.id);
        // Get most recent date
        const buildType = filtered[0].buildType
        const {startDate, endDate} = await getMostRecentDate(buildType);
        filtered[0].formState.dateRange = {startDate: startDate, endDate: endDate}
        console.log(filtered[0].formState)
        // Refresh request
        if (filtered[0].formState) {
          await axios.post(
            `${baseUrl}/api/audience/${data.id}/generate`,
            filtered[0].formState,
            {
              headers: {
                'content-type': 'application/json',
                'Accept': 'application/json,text/*;q=0.99',
              },
            }
          );
        }

        await axios.patch(`${baseUrl}/api/access/usage/audiences?mode=refresh`);
      } else {
        context.notification.show(`Audience Refresh is renewed first day of every month`, 'error', true);
      }
    } catch (error) {
      console.error(error);
      // Handle errors or notify the user
      context.notification.show('An error occurred during audience refresh', 'error', true);
    }
  };

  const downloadCSV = (audience, file) => {
    if (audience.fileDetailsList && audience.status === 'COMPLETE') {
      const csv = file.name ? `https://audiencelab-builder.s3.amazonaws.com/${file.name}` : file.url;
      const newWindow = window.open(csv, '_blank');
      if (newWindow) {
        newWindow.focus();
      }
    }
    return null;
  };

  const csvShowModal = (audience) => {
    const lastFive = audience.fileDetailsList?.slice(Math.max(audience.fileDetailsList.length - 5, 0));
    const files = lastFive ? lastFive : audience.fileDetailsList;

    if (!audience.fileDetailsList) {
      context.notification.show('No CSV files to download', 'error', true);
      return null;
    }

    if (files.length > 0) {

    context.modal.show({
      title: 'Download CSV',
      text: 'Please select a version of the audience to download.',
      form: {
        csv: {
          label: 'CSV',
          type: 'checkbox',
          options: files.map((file) => (format.timestampModal(file.date))),
        },
      },
      buttonText: 'Download',
      url: `/api/audience/${audience.id}`,
      method: 'GET',
    }, (data) => {
      const filesToDownload = files.map((file) => data.csv.value.includes(format.timestampModal(file.date)) ? file : null).filter((f) => f !== null);
      context.notification.show('downloaded successfully', 'success', true);
      filesToDownload.forEach((file) => downloadCSV(audience, file));
    });
  } else {
    context.modal.show({
      title: 'Download CSV',
      text: 'No CSV files to download',
      form: {},
      buttonText: 'Download'
    });
  }
}

  const progressAudience = (data) => {
    const id = uuid4();
    const progress = data.progress;
    context.modal.show({
      title: `${progress.stepName}`,
      form: {
        id: {label: 'ID', type: 'hidden', value: id},
        name: {label: 'Steps', type: 'text', value: `${progress.currentStep} out ${progress.totalSteps}`},
        description: {label: 'Description', type: 'text', value: `${progress.stepDescription}`},
      },
    });
  };

  useEffect(() => {
    setCurrentPage(1);
    searchAllAudiences();
    getCustom();
    localStorage.removeItem('topic');
    localStorage.removeItem('description');
  }, []);

  useEffect(() => {
    getAudience();
  }, [currentPage, dispatch, context]);

  useEffect(() => {
    getAccess();
  }, [location]);


// FACEBOOK INTEGRATION

const FacebookAudience = async (data) => {
  let audienceHeader = {...data}; 
  audienceHeader["featuretype"] = "AudiencelabAudience";
  setFacebookAudienceData(audienceHeader);

  const quota = await axios.get(`${baseUrl}/api/access/quota/integrations_facebook`);
  const permit = quota.data.permit;

  if (permit) {
  const id = audienceHeader.id;

  const hasAudienceRequest = await axios.get(`${baseUrl}/api/facebook/hasSynced/${id}`);
  const hasAudience = hasAudienceRequest.data
  if (hasLoggedCondition) {
    if (hasAudience) {

      try {
        context.notification.show('Your Audience is already synced', 'success', true);

    } catch (error) {
        console.error(error);
    }
    } 
    
    else {
        setShowSyncModal(true);
    }

  } else {
    context.newModal.show({
      title: "",
      children: <FacebookLoginModal/>,
  });

  }

} else {
  context.notification.show(`Please contact support for Facebook Integration credits`, 'error', true);
}


};

// END OF FACEBOOK INTEGRATION

const getPremadeConditional = (audience) => {
  if (optionAccess.length > 1 || audience.buildType === 'keyword') {
    return 'yes';
  } else {
    const type = audience.formState?.optionType;
  
    switch (optionAccess[0]) {
      case 'B2C':
        return type === 'B2C' ? 'yes' : 'no';
      case 'B2B':
        return type === 'B2B' ? 'yes' : 'no';
      default:
        return 'yes';
    }    
  }
}

// Checks if audience date. If audience date is older than 26 january, returns false, if not, returns true.
const getIsNotOldConditional = (audience) => {
  const date = new Date(audience.createdAt);
  const january26 = new Date('2024-01-26');
  return date > january26 ? 'yes' : 'no';
}

useEffect(() => {
  setFilteredAudienceList(audienceList.map(audience => ({ ...audience, optionAccess: getPremadeConditional(audience), isNotOld: getIsNotOldConditional(audience) })));
}, [audienceList, optionAccess, isNotOld]);


  const actionList = [
    {
      icon: 'refresh-cw', 
      tooltip: ['Refresh audience to receive data updates', `This audience can't be refreshed`], 
      onClick: [refreshAudience],
      condition: [
        {col: "isNotOld", values: ["yes"]},
        {col: "optionAccess", values:  ["yes"]},
        {col: 'status', values: ['COMPLETE', 'PROCESSING', 'Kindly Try Again', 'IN QUEUE', 'FAILED', 'CONTACT_SUPPORT']}
      ],
    },
    {
      icon: "facebook",
      color: "blue",
      tooltip: ["Sync Audience with Facebook","Build audience first"],
      onClick: [FacebookAudience],
      condition: [
        {col: "optionAccess", values:  ["yes"]},
        {col: "status", values: ["COMPLETE"] }
      ],
    },
    {
      icon: 'edit', 
      tooltip: ['Edit Audience', 'Premade type not avaible'], 
      onClick: [editAudience], 
      condition: [{col: "optionAccess", values:  ["yes"]}]
    },

    {
      icon: 'copy', 
      tooltip: ['Duplicate Audience','Premade type not avaible'], 
      onClick: [duplicateAudience],
      condition: [{col: "optionAccess", values:  ["yes"]}]
    },
    {
      icon: 'download', 
      tooltip: ['Allow popups on your browser to download Audiences', `This audience can't be downloaded`], 
      onClick: [csvShowModal],
      condition: [
        {col: "optionAccess", values:  ["yes"]},
      ],
    },
    {
      icon: 'trash-2', 
      tooltip: ['Delete Audience'], 
      onClick: [deleteAudience], 
      color: 'red',
      condition: [{ col: "optionAccess", values:  ["yes"]}]
    },
    {
      icon: 'more-vertical', 
      tooltip: ['See more progress status', 'No progress status yet'], 
      onClick: [progressAudience],
      condition: [
        {col: "optionAccess", values:  ["yes"]},
        {col: 'status', values: ['PROCESSING']}
      ],
    },
  ]
  
  function filterActions(actions) {
    let filtered;
  
    if (facebookIntegrationAccess) {
      filtered = actions;
    } else {
      filtered = actions.filter(action => action.icon !== "facebook");
    }
  
    return filtered;
  }
  
  const filteredActions = filterActions(actionList)
  const badges = {
    col: 'status', color: 'blue', condition: [
      {value: 'Empty query and no keywords provided', label: 'FAILED', color: 'red', tooltip: 'Please add keywords to your audience'},
      {value: 'Kindly Try Again', label: 'FAILED', color: 'red', tooltip: 'Please try again'},
      {value: 'FAILED', label: 'FAILED', color: 'red'},
      {value: 'COMPLETE', color: 'green'},
      {value: 'NO_DATA', label: 'NO DATA'},
    ],
  }

  const searchAllAudiences = async () => {
    const data = await axios.get(`${baseUrl}/api/audience/index/account`);
    setSearchResult(data.data.data.docs.map(audience => ({ ...audience, optionAccess: getPremadeConditional(audience), isNotOld: getIsNotOldConditional(audience) })));
  };

  return (
    <Fragment>
      <Animate>
        {/* Title */}
        <div className='flex justify-between items-center'>
          <h2 className="m-6 text-3xl font-semibold">Manage Audiences</h2>
          <div className="float-left sm:float-right">
            <div className=" m-6 flex space-x-4">
              {selectedItem?.length > 0 && (
                <button
                type="button"
                onClick={deleteSelected}
                className="inline-flex items-center rounded-md bg-red-600 px-5 py-2.5 text-sm font-semibold text-white shadow-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
              >
              <Icon image="trash" size={18} />
                  Delete Selected
              </button>
              )}
              <button
                type="button"
                onClick={addAudience}
                className="inline-flex items-center rounded-md bg-blue-600 px-5 py-2.5 text-sm font-semibold text-white shadow-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              >
              <Icon image="plus-square" size={18} />
                  Add Audience
              </button>
            </div>
          </div>
        </div>

        {showSyncModal && <SyncAudienceModal />}
        {isSyncSuccess && <FacebookSuccessModal />}

        <Message
          closable
          title='Welcome to the Audience Generator!'
          text='Your data will be processed via e-mail shortly after you create an audience'
          type='success'
        />

        {/* Quota */}
        {accessResponse && <AudienceQuota quotaResponse={accessResponse} />}

        {/* Table */}
        <Card loading={loading}>
          <NewPaginate
            offset={ currentPage - 1}
            limit={ pagValues?.limit}
            total={ pagValues?.total}
            next={ () => setCurrentPage(currentPage + 1) }
            prev={ () => setCurrentPage(currentPage - 1) }
            onChange={ () => setCurrentPage() }
          />
          <Table
            checkbox={{getItem: selectedItem, setItem: setSelectedItem}}
            search={searchResult}
            data={filteredAudienceList}
            show={['name', 'createdAt', 'status']}
            actions={filteredActions}
            badge={badges}
          />
        </Card>
      </Animate>
    </Fragment>
  );
};
