import React, {Fragment, useContext, useEffect, useState} from 'react';
import {ViewContext, Button, TitleRow, useNavigate, Animate, Icon } from 'components/lib';
import {useDispatch} from 'react-redux';
import {setName} from 'redux/features/enrichment/enrichmentSlice';
import {EnrichmentTable} from './enrichmentTable';
import axios from 'axios';
import {checkLocation} from 'helpers/checklocation';
import Quota from 'helpers/quota';
import EnrichmentsQuota from 'helpers/quotaEnrichments';


export function Enrichment() {
  const context = useContext(ViewContext);
  const baseUrl = checkLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [quota, setQuota] = useState(null);

  useEffect(
    () => {
      const quotaData = getQuota();
      setQuota(quotaData);
    }, 
    []
  )

  const getQuota = async () => {
    try {
      const quotaResponse = await axios.get(`${baseUrl}/api/access/quota/audiences`);
      return quotaResponse.data;
    } catch (error) {
      console.error('Failed to fetch quota:', error);
    }
  }

  const nameModal = async () => {
    const baseUrl = checkLocation();
    const userString = localStorage.getItem('user');
    const user = userString ? JSON.parse(userString) : null;
    const accountId = user ? user.account_id : null;
    const quota = await axios.get(`${baseUrl}/api/access/quota/enrichments`);
    const permit = quota.data.permit;
    const interval = quota.data.resetInterval;

    if (permit) {
      context.modal.show({
        title: 'Name your enrichment',
        form: {
          name: {
            label: 'Name',
            type: 'text',
            placeholder: 'Enrichment name',
            required: true,
          },
        },
        buttonText: 'Done',
        method: 'GET',
        url: '/api/enrichment/callback',
      }, async (e) => {
        dispatch(setName(e.name.value));
        context.notification.show(`${e.name.value} created successfully`, 'success', true);
        navigate('/enrichment/upload');
      });
    } else {
      context.notification.show(`Enrichments limit will be renewed on ${interval}`, 'error', true);
    }
  };

  return (
    <Fragment>
      <Animate>
        <div className='flex justify-between items-center'>
          <h2 className="text-3xl font-semibold m-6">Enrichment</h2>
          <button
              type="button"
              onClick={nameModal}
              className="m-6 inline-flex items-center rounded-md bg-blue-600 px-5 py-2.5 text-sm font-semibold text-white shadow-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          >
          <Icon image="plus-square" size={18} />
              Add File
          </button>
        </div>

        <EnrichmentsQuota/>

        <EnrichmentTable />
      </Animate>
    </Fragment>
  );
}

