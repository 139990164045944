/** *
*
*   HOME NAV
*   Navigation used on the main external website. Renders a dashboard link
*   if the user is signed in, or a sign up link if they are not
*
**********/

import {Fragment, useContext} from 'react';
import {AuthContext, Logo, /* Link,*/ Button, Content, ClassHelper} from 'components/lib';
import Style from './home.tailwind.js';

export function HomeNav(props) {
  // context
  const context = useContext(AuthContext);

  const css = ClassHelper(Style, {

    wrapper: true,
    color: !props.transparent,
    transparent: props.transparent,

  });

  return (
    <section className={ css }>
      <Content>

        {/* <Logo className={ Style.logo }/> */}

      </Content>
    </section>
  );
}
